import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FormSelectField from '../../../common/components/formFields/FormSelectField';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import CommonButton from '../../../common/components/CommonButton';
import {
  getVehicleMakeList,
  getVehicleModelList,
} from '../../../addvehicle/AddVehicleActions';
import { dataToSelect } from '../../../common/helpers/dataToSelect';
import { mapRangeToSelect } from '../utils/yearsRangeOptions';
import { getSearchAlertOptions } from '../SearchAlertActions';
import RouterPaths from '../../../constants/RouterPaths';
import { AccountLayoutContentWrapper } from '../../../common/components/AccountLayoutWrapper';
import SettingsHeader from '../../SettingsHeader';

const addAllEmptyOption = (values, emptyLabel = 'ALL') => {
  const all = {
    value: -1,
    label: emptyLabel,
  };

  return [all, ...values];
};

const valuesToSelect = values => {
  if (!values) return [];
  const array = [];
  values.forEach(item => {
    array.push({
      value: item.toString(),
      label: `${item.toString()} miles`,
    });
  });
  return array;
};

const SearchAlertForm = ({ isCreateForm, form, values, history }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const distanceInMiles = useSelector(state => state.searchAlerts.get('distanceInMiles'));
  const valueInMiles = useSelector(state => state.searchAlerts.get('valueInMiles'));
  const yearsAgo = useSelector(state => state.searchAlerts.get('yearsAgo'));
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [modelSelectDisabled, setModelSelectDisabled] = useState(true);

  useEffect(() => {
    dispatch(
      getVehicleMakeList(makeList => {
        setMakeOptions(addAllEmptyOption(dataToSelect([...makeList?.data], ['id', 'name'])));
      }),
    );
    dispatch(getSearchAlertOptions());
  }, [dispatch]);

  useEffect(() => {
    if (!values?.yearMaxValue) {
      form.setFieldValue('yearMaxValue', { value: -1, label: 'Any' });
    }

    if (!values?.yearMinValue) {
      form.setFieldValue('yearMinValue', { value: -1, label: 'Any' });
    }

    if (values?.make?.value || values?.make?.value === -1) {
      setModelSelectDisabled(false);
    }
  }, [form, values]);

  const handleMakeSelectChange = e => {
    form.setFieldValue('model', { value: -1, label: 'All' });
    if (!e.value || e.value === -1) {
      setModelOptions([]);
      setModelSelectDisabled(true);
      return;
    }
    setModelSelectDisabled(false);
    dispatch(
      getVehicleModelList(e.value, modelList => {
        setModelOptions(dataToSelect(modelList?.data, ['id', 'name']));
      }),
    );
  };

  return (
    <>
      <SettingsHeader>
        <Row>
          <Col className="d-flex justify-content-end align-items-center actions-wrapper">
            <CommonButton
              className="text-sm"
              label="Cancel"
              disabled={isLoading}
              variant={CommonButtonVariants.OUTLINED}
              handleClick={() => history.push(RouterPaths.SEARCH_ALERTS)}
            />
            <CommonButton
              className="text-sm"
              label={`${isCreateForm ? 'SAVE' : 'UPDATE'}`}
              disabled={isLoading}
              variant={CommonButtonVariants.PRIMARY}
              handleClick={() => form.handleSubmit()}
            />
          </Col>
        </Row>
      </SettingsHeader>
      <AccountLayoutContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">
              {isCreateForm ? 'Add new search alert' : 'Edit search alert'}
            </h5>
            <p className="text-sm mt-2">
              Please specify the parameters of the alert so that we can inform you of new
              vehicles promptly.
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12} className="justify-content-center mr-auto ml-auto">
            <Form autoComplete="off" className="text-left">
              <Row>
                <Col md="4" sm="12">
                  <FormSelectField
                    name="make"
                    className="basic-data__input"
                    label="Make"
                    options={makeOptions}
                    onChangeHandler={handleMakeSelectChange}
                  />
                </Col>
                <Col md="4" sm="12">
                  <FormSelectField
                    name="model"
                    className="basic-data__input"
                    label="Model"
                    disabled={modelSelectDisabled}
                    options={modelOptions}
                  />
                </Col>
                <Col md={2}>
                  <FormSelectField
                    name="yearMaxValue"
                    className="basic-data__input"
                    label="From"
                    options={addAllEmptyOption(mapRangeToSelect(yearsAgo), 'Any')}
                  />
                </Col>
                <Col md={2}>
                  <FormSelectField
                    name="yearMinValue"
                    className="basic-data__input"
                    label="To"
                    options={addAllEmptyOption(mapRangeToSelect(yearsAgo), 'Any')}
                  />
                </Col>
              </Row>
              <Row className="d-flex align-items-center  mt-3">
                <Col md={4}>
                  <FormSelectField
                    name="mileageMaxValue"
                    className="basic-data__input"
                    label="Maximum mileage"
                    options={addAllEmptyOption(valuesToSelect(valueInMiles))}
                  />
                </Col>
                <Col md={4}>
                  <FormSelectField
                    name="distanceMaxValueInMiles"
                    className="basic-data__input"
                    label="Maximum distance from you"
                    options={addAllEmptyOption(valuesToSelect(distanceInMiles), 'National')}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </AccountLayoutContentWrapper>
    </>
  );
};

SearchAlertForm.defaultProps = {
  isCreateForm: false,
};

SearchAlertForm.propTypes = {
  isCreateForm: PropTypes.bool,
};

export default withRouter(SearchAlertForm);
