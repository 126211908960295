import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CommonButton from '../common/components/CommonButton';
import { CommonButtonVariants } from '../constants/CommonButtonVariants';

const AddVehicleHeader = ({
  backButtonDisabled,
  nextButtonDisabled,
  backButtonHandler,
  nextButtonHandler,
  nextButtonText,
  saveDraftButtonHandler,
  saveDraftButtonDisabled,
  hideSaveDraftButton,
  hideBackButton,
  customButtons,
}) => {
  return (
    <Row className="add-vehicle-header account-layout__header">
      <Col sm={12} md={3} className="p-0">
        {' '}
        <h3 className="m-0 add-vehicle-header__title mr-auto font-weight-bold d-flex align-items-center">
          Add Vehicle
        </h3>
      </Col>
      <Col sm={12} md={9} className="p-0">
        <div className="ml-auto d-flex flex-row add-vehicle-header__buttons-container">
          {customButtons}
          {!hideSaveDraftButton && saveDraftButtonHandler ? (
            <CommonButton
              label="SAVE DRAFT"
              disabled={!saveDraftButtonHandler || saveDraftButtonDisabled}
              variant={CommonButtonVariants.OUTLINED}
              handleClick={saveDraftButtonHandler}
              className="add-vehicle-header__save-draft-button"
            />
          ) : (
            ''
          )}
          {!hideBackButton ? (
            <CommonButton
              label="Back"
              disabled={backButtonDisabled || !backButtonHandler}
              variant={CommonButtonVariants.OUTLINED}
              handleClick={backButtonHandler}
            />
          ) : (
            ''
          )}
          <CommonButton
            label={`${nextButtonText || 'Next'}`}
            disabled={nextButtonDisabled || !nextButtonHandler}
            variant={CommonButtonVariants.PRIMARY}
            handleClick={nextButtonHandler}
          />
        </div>
      </Col>
    </Row>
  );
};

AddVehicleHeader.defaultProps = {
  hideSaveDraftButton: false,
  saveDraftButtonDisabled: false,
  hideBackButton: false,
  backButtonDisabled: false,
  nextButtonDisabled: false,
  backButtonHandler: () => {},
  nextButtonHandler: () => {},
  customButtons: '',
  nextButtonText: 'Next',
};

AddVehicleHeader.propTypes = {
  hideSaveDraftButton: PropTypes.bool,
  saveDraftButtonDisabled: PropTypes.bool,
  hideBackButton: PropTypes.bool,
  backButtonDisabled: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
  backButtonHandler: PropTypes.func,
  nextButtonHandler: PropTypes.func,
  customButtons: PropTypes.string,
  nextButtonText: PropTypes.string,
};

export default AddVehicleHeader;
