import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import trustedDealerIcon from 'assets/img/trusted-dealer-icon.png';
import phoneCoinIcon from 'assets/img/phone-coin-icon.png';
import computerClockIcon from 'assets/img/computer-clock-icon.png';
import clockIcon from 'assets/img/clock-icon.png';

const SellCard = ({ icon, title, description, className }) => {
  return (
    <div className={`sell-card-wrapper h-100 ${className}`}>
      <div className="sell-card">
        <div className="justify-content-between d-flex">
          <div className="w-auto sell-card__image-wrapper">
            <div className="sell-card__image">
              <img src={icon} alt={title} width="72px" className="m-0" />
            </div>
          </div>
          <div className="w-100 ml-4">
            <Row>
              <Col className="sell-card__title text-xl fw-700">{title}</Col>
            </Row>
            <Row>
              <Col className="sell-card__description text-base">{description}</Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

const SellSection = () => {
  return (
    <section className="sell section" id="sell-section">
      <Container fluid>
        <Row>
          <Col className="sell-content" md={12}>
            <Row className="sell-content__title-container m-auto mb-5">
              <Col sm={12} md={12} lg={12} className="pl-0">
                <h5 className="color-light-primary text-lg font-weight-bolder">SELL</h5>
                <p className="font-weight-bold section-title color-primary">
                  Faster vehicle uploads and shorter timed auctions, delivering a more
                  efficient and profitable solution.
                </p>
              </Col>
            </Row>
            <Row className="sell-content__card-list-container justify-content-between ml-auto mr-auto">
              <Col sm={12} md={6} lg={6} className="p-0">
                <SellCard
                  title="Trusted Dealers"
                  description="Sell your Trade cars and part exchanges direct to the industry's most trusted and professional motor dealers."
                  icon={trustedDealerIcon}
                  bordered={true}
                  className="bordered mr-4"
                />
              </Col>
              <Col sm={12} md={6} lg={6} className="p-0">
                <SellCard
                  title="Sell Faster"
                  description="Why wait longer to sell when you can sell faster. Achieve the highest bids for your vehicles with auctions running from as little as 1 hour."
                  icon={computerClockIcon}
                  className="bordered ml-4"
                />
              </Col>
              <Col sm={12} md={6} lg={6} className="p-0">
                <SellCard
                  title="Faster Mobile Uploads"
                  description="It's simple and faster using our innovative mobile app to launch vehicles for sale direct from your mobile."
                  icon={phoneCoinIcon}
                  bordered={true}
                  className="bordered mr-4"
                />
              </Col>
              <Col sm={12} md={6} lg={6} className="p-0">
                <SellCard
                  title="Buyers Alert"
                  description="Push notifications instantly alert buyers when you launch a vehicle for sale. Your vehicle gets maximum exposure to the best trade buyers every time."
                  icon={clockIcon}
                  bordered={true}
                  className="bordered ml-4"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SellSection;
