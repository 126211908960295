import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';

const ConfirmLaunchFormHeader = ({
  handleLaunchForm,
  handleCancelForm,
  isLaunchFormDisabled,
}) => {
  return (
    <Row className="confirm-launch-form__header">
      <Col md={4} sm={12}>
        <h3 className="font-weight-bold">Launch Vehicle</h3>
      </Col>
      <Col md={8} sm={12}>
        <Row>
          <Col className="d-flex justify-content-end align-items-center actions-wrapper">
            <CommonButton
              className="text-sm"
              label="CANCEL"
              variant={CommonButtonVariants.OUTLINED}
              handleClick={handleCancelForm}
            />
            <CommonButton
              className="text-sm"
              label="LAUNCH"
              disabled={isLaunchFormDisabled}
              variant={CommonButtonVariants.PRIMARY}
              handleClick={handleLaunchForm}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ConfirmLaunchFormHeader.defaultProps = {
  isLaunchFormDisabled: false,
};

ConfirmLaunchFormHeader.propTypes = {
  isLaunchFormDisabled: PropTypes.bool,
  handleLaunchForm: PropTypes.func.isRequired,
  handleCancelForm: PropTypes.func.isRequired,
};

export default ConfirmLaunchFormHeader;
