import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { checkIsFieldEmpty } from 'common/helpers/checkIsFieldEmpty';
import { EmployeeStatusTypes } from 'constants/EmployeeStatusTypes';
import { useHistory } from 'react-router-dom';
import FormInputField from '../../../common/components/formFields/FormInputField';
import FormSelectField from '../../../common/components/formFields/FormSelectField';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import CommonButton from '../../../common/components/CommonButton';
import { fetchOrganizationLocations } from '../../SettingsActions';
import { constantToSelect } from '../../../common/helpers/constantToSelect';
import { EmployeeUserRoleName, UserRole } from '../../../constants/UserRole';
import { DealerPositionName } from '../../../constants/DealerPosition';
import { AccountLayoutContentWrapper } from '../../../common/components/AccountLayoutWrapper';
import SettingsHeader from '../../SettingsHeader';
import RouterPaths from '../../../constants/RouterPaths';
import FormGroupToggleField from '../../../common/components/formFields/FormGroupToggleField';

const dealerRole = constantToSelect(DealerPositionName);

const EmployeeForm = ({ isCreateForm, values, form }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const organizationIsBuyer = useSelector(state => state.auth.get('isBuyer'));
  const organizationIsSeller = useSelector(state => state.auth.get('isSeller'));
  const organizationLocations = useSelector(state =>
    state.myOrganization.get('organizationLocations'),
  );
  const employeeStatus = useSelector(
    state => state.myOrganization.get('employeeDetails')?.employee?.status,
  );
  const [location, setLocation] = useState([]);
  const [emptyFields, setEmptyFields] = useState(true);

  const employeeUserRoles = constantToSelect(EmployeeUserRoleName).map(role => {
    const disabled =
      (role.value === UserRole.BUYER && !organizationIsBuyer) ||
      (role.value === UserRole.SELLER && !organizationIsSeller) ||
      (role.value === UserRole.TRANSACTION_MANAGEMENT &&
        (!organizationIsSeller || !values.role.includes(UserRole.SELLER)));
    return { ...role, disabled };
  });

  const checkCheckboxDependencies = value => {
    if (!value.includes(UserRole.SELLER) && value.includes(UserRole.TRANSACTION_MANAGEMENT)) {
      const idx = value.indexOf(UserRole.TRANSACTION_MANAGEMENT);
      if (idx !== -1) {
        value.splice(idx, 1);
      }
    }
  };

  useEffect(() => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      position,
      location,
      password,
      passwordRepeat,
      role,
    } = values;
    const valuesArray = [
      firstName,
      lastName,
      phoneNumber,
      email,
      isEmpty(position) ? '' : position,
      isEmpty(location) ? '' : location,
      isEmpty(role) ? '' : role,
    ];
    if (isCreateForm) {
      valuesArray.push(password, passwordRepeat);
    }
    checkIsFieldEmpty(valuesArray, setEmptyFields);
  }, [values, isCreateForm]);

  useEffect(() => {
    dispatch(fetchOrganizationLocations());

    if (organizationLocations.length > 0) {
      const preparedOrganizationLocations = organizationLocations.map(item => {
        return {
          label: item?.postalCode?.postcode,
          value: item.id,
        };
      });
      setLocation(preparedOrganizationLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, organizationLocations.length]);
  return (
    <>
      <SettingsHeader>
        <Row>
          <Col className="d-flex justify-content-end align-items-center actions-wrapper">
            <CommonButton
              className="text-sm"
              label="CANCEL"
              variant={CommonButtonVariants.OUTLINED}
              handleClick={() => history.push(RouterPaths.EMPLOYEES)}
            />
            <CommonButton
              className="text-sm"
              label="SAVE"
              disabled={isLoading || emptyFields}
              variant={CommonButtonVariants.PRIMARY}
              handleClick={() => form.handleSubmit()}
            />
          </Col>
        </Row>
      </SettingsHeader>
      <AccountLayoutContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">
              {isCreateForm ? 'Add new employee' : 'Edit employee'}
            </h5>
            <p className="text-sm mt-2">
              Please add the personal & contact details of the employee.
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12} className="justify-content-center mr-auto ml-auto">
            <Form autoComplete="off" className="text-left">
              <Row className="justify-content-between">
                <Col md="4" sm={12}>
                  <FormInputField
                    name="firstName"
                    label="First name"
                    disabled={!isCreateForm && employeeStatus !== EmployeeStatusTypes.REJECTED}
                  />
                </Col>
                <Col md="4" sm={12}>
                  <FormInputField
                    name="lastName"
                    label="Last name"
                    disabled={!isCreateForm && employeeStatus !== EmployeeStatusTypes.REJECTED}
                  />
                </Col>
                <Col md="4" sm={12}>
                  <FormSelectField
                    name="position"
                    label="Choose a position"
                    options={dealerRole}
                    disabled={!isCreateForm && employeeStatus !== EmployeeStatusTypes.REJECTED}
                  />
                </Col>
              </Row>
              <Row className="justify-content-between mt-4">
                <Col md="4" sm={12}>
                  <FormSelectField
                    name="location"
                    label="Choose a location"
                    options={location}
                  />
                  <FormSelectField rowClassNames="form-select-field-hidden" />
                </Col>
                <Col md="4" sm={12}>
                  <FormInputField
                    name="phoneNumber"
                    label="Phone number"
                    disabled={!isCreateForm && employeeStatus !== EmployeeStatusTypes.REJECTED}
                  />
                </Col>
                <Col md="4" sm={12}>
                  <FormInputField
                    name="email"
                    label="E-mail address"
                    disabled={!isCreateForm && employeeStatus !== EmployeeStatusTypes.REJECTED}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={12} className="form-group-checkbox-inline-wrapper">
                  <FormGroupToggleField
                    inline={true}
                    name="role"
                    label="Role"
                    checkboxes={employeeUserRoles}
                    checkDependencies={checkCheckboxDependencies}
                    isRoleGroupCheckbox={true}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="4" sm={12}>
                  <FormInputField type="password" name="password" label="Password" />
                </Col>
                <Col md="4" sm={12}>
                  <FormInputField
                    type="password"
                    name="passwordRepeat"
                    label="Repeat password"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </AccountLayoutContentWrapper>
    </>
  );
};

EmployeeForm.defaultProps = {
  isCreateForm: false,
  values: {},
  form: {},
};

EmployeeForm.propTypes = {
  isCreateForm: PropTypes.bool,
  values: PropTypes.object,
  form: PropTypes.object,
};

export default EmployeeForm;
