import React, { useRef } from 'react';
import { Formik } from 'formik';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AddDescriptionForm from './AddDescriptionForm';
import validationSchema from './AddDescriptionForm.schema';
import { getDraft, saveDescriptionAndSettingsDraft } from '../AddVehicleActions';

const AddDescriptionPage = ({ prevForm, generalValues, nextForm }) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const id = useSelector(state => state.addVehicle.get('id'));
  const descriptionAndSettings = useSelector(state =>
    state.addVehicle.get('descriptionAndSettings'),
  );
  const initialValues = {
    description: descriptionAndSettings ? descriptionAndSettings?.description : '',
  };

  const handleSubmit = async () => {
    if (formRef?.current) {
      const { description } = formRef?.current?.values;

      const data = {
        ...descriptionAndSettings,
        description,
      };

      dispatch(
        saveDescriptionAndSettingsDraft(data, () => {
          dispatch(getDraft(id));
          nextForm();
        }),
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      innerRef={f => {
        formRef.current = f;
      }}
      enableReinitialize
    >
      {form => (
        <AddDescriptionForm form={form} prevForm={prevForm} generalValues={generalValues} />
      )}
    </Formik>
  );
};

AddDescriptionPage.propTypes = {
  prevForm: PropTypes.func.isRequired,
  generalValues: PropTypes.object.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default AddDescriptionPage;
