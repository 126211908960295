export default {
  LIVE: 'live',
  INFO: 'info',
  DANGER: 'danger',
  SUCCESS: 'success',
  PRIMARY: 'primary',
  WARNING: 'warning',
  SECONDARY: 'secondary',
  NEW_VEHICLE_AVAILABLE: 'new-vehicle-available',
};
