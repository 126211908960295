import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import {
  AccountLayoutNavItem,
  AccountLayoutTitle,
  AccountLayoutWrapper,
} from '../common/components/AccountLayoutWrapper';
import RouterPaths from '../constants/RouterPaths';
import AnalyticsPieChart from './AnalyticsPieChart';
import {
  AuctionStatus,
  AuctionStatusAnalyticsColors,
  AuctionStatusName,
} from '../constants/AuctionStatus';
import {
  BidStatusTypeName,
  BidStatusType,
  BidStatusTypeAnalyticsColors,
} from '../constants/BidStatusType';
import {
  fetchOrganizationDetails,
  fetchOrganizationStatistics,
} from '../settings/SettingsActions';
import { setVehicleFilter } from '../myvehicles/MyVehiclesActions';
import { setBidsFilter } from '../mybids/MyBidsActions';

const launchedAuctionsData = [
  {
    type: AuctionStatus.PUBLISHED,
    name: AuctionStatusName.PUBLISHED,
    color: AuctionStatusAnalyticsColors.PUBLISHED,
    value: 0,
  },
  {
    type: AuctionStatus.DRAFT,
    name: AuctionStatusName.DRAFT,
    color: AuctionStatusAnalyticsColors.DRAFT,
    value: 0,
  },
  {
    type: AuctionStatus.TO_ACCEPT,
    name: AuctionStatusName.TO_ACCEPT,
    color: AuctionStatusAnalyticsColors.TO_ACCEPT,
    value: 0,
  },
  {
    type: AuctionStatus.WAITING,
    name: AuctionStatusName.WAITING,
    color: AuctionStatusAnalyticsColors.WAITING,
    value: 0,
  },
  {
    type: AuctionStatus.REJECTED,
    name: AuctionStatusName.REJECTED,
    color: AuctionStatusAnalyticsColors.REJECTED,
    value: 0,
  },
  {
    type: AuctionStatus.ENDED,
    name: AuctionStatusName.ENDED,
    color: AuctionStatusAnalyticsColors.ENDED,
    value: 0,
  },
  {
    type: AuctionStatus.CANCELLED,
    name: AuctionStatusName.CANCELLED,
    color: AuctionStatusAnalyticsColors.CANCELLED,
    value: 0,
  },
];

const placedBidsData = [
  {
    type: BidStatusType.PLACED_BIDS,
    name: BidStatusTypeName.PLACED_BIDS,
    color: BidStatusTypeAnalyticsColors.PLACED_BIDS,
    value: 0,
  },
  {
    type: BidStatusType.TO_ACCEPT,
    name: BidStatusTypeName.TO_ACCEPT,
    color: BidStatusTypeAnalyticsColors.TO_ACCEPT,
    value: 0,
  },
  {
    type: BidStatusType.WAITING,
    name: BidStatusTypeName.WAITING,
    color: BidStatusTypeAnalyticsColors.WAITING,
    value: 0,
  },
  {
    type: BidStatusType.LOST,
    name: BidStatusTypeName.LOST,
    color: BidStatusTypeAnalyticsColors.LOST,
    value: 0,
  },
  {
    type: BidStatusType.REJECTED,
    name: BidStatusTypeName.REJECTED,
    color: BidStatusTypeAnalyticsColors.REJECTED,
    value: 0,
  },
  {
    type: BidStatusType.CANCELLED,
    name: BidStatusTypeName.CANCELLED,
    color: BidStatusTypeAnalyticsColors.CANCELLED,
    value: 0,
  },
];

const AnalyticsPage = ({ history }) => {
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));

  const [auctionsDataTotal, setAuctionsDataTotal] = useState(0);
  const [bidsDataTotal, setBidsDataTotal] = useState(0);

  const [auctionsData, setAuctionsData] = useState([]);
  const [bidData, setBidData] = useState([]);

  const isBuyer = useSelector(state => state.auth.get('isBuyer'));
  const isSeller = useSelector(state => state.auth.get('isSeller'));

  const organizationStatistics = useSelector(state =>
    state.myOrganization.get('organizationStatistics'),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchOrganizationDetails());
      dispatch(fetchOrganizationStatistics());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    setAuctionsDataTotal(organizationStatistics?.auctionCount || 0);
    setAuctionsData(
      launchedAuctionsData.map(item => {
        const value =
          organizationStatistics?.auctionCount &&
          organizationStatistics?.auctions &&
          organizationStatistics?.auctions[item?.type]
            ? organizationStatistics?.auctions[item?.type]
            : 0;

        return {
          ...item,
          value,
        };
      }),
    );

    setBidsDataTotal(organizationStatistics?.auctionBidCount || 0);
    setBidData(
      placedBidsData.map(item => {
        const value =
          organizationStatistics?.auctionBidCount &&
          organizationStatistics?.auctionBids &&
          organizationStatistics?.auctionBids[item?.type]
            ? organizationStatistics?.auctionBids[item?.type]
            : 0;

        return {
          ...item,
          value,
        };
      }),
    );
  }, [organizationStatistics]);

  const getSold = () => {
    return organizationStatistics?.auctions?.[AuctionStatus.SOLD] || 0;
  };

  const getWon = () => {
    return organizationStatistics?.auctionBids?.[BidStatusType.WON] || 0;
  };

  const getTotalWithoutSold = () => {
    const result = auctionsDataTotal - getSold();
    return result < 0 ? 0 : result;
  };

  const getTotalBidsWithoutWon = () => {
    const result = bidsDataTotal - getWon();
    return result < 0 ? 0 : result;
  };

  const navItems = useCallback(() => {
    return [
      <AccountLayoutNavItem link={RouterPaths.MY_VEHICLES} hide={!isSeller}>
        MY VEHICLES
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem link={RouterPaths.MY_BIDS} hide={!isBuyer}>
        MY BIDS
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem link={RouterPaths.ANALYTICS}>ANALYTICS</AccountLayoutNavItem>,
    ];
  }, [isBuyer, isSeller]);

  const handleLaunchedBidsPieClick = value => {
    if (value?.type && AuctionStatus?.[value.type]) {
      dispatch(setVehicleFilter(value?.type));
      history.push(`${RouterPaths.MY_VEHICLES}#vehicleFilter`);
    } else {
      history.push(`${RouterPaths.MY_VEHICLES}`);
    }
  };

  const handlePlacedBidsPieClick = value => {
    if (value?.type && BidStatusType?.[value.type]) {
      dispatch(setBidsFilter(value?.type));
      history.push(`${RouterPaths.MY_BIDS}#bidsFilter`);
    } else {
      history.push(`${RouterPaths.MY_BIDS}`);
    }
  };

  return (
    <>
      <AccountLayoutWrapper
        navTitle={<AccountLayoutTitle>{getSold()} VEHICLES SOLD</AccountLayoutTitle>}
        navOptions={navItems()}
      >
        <Row className="mb-3">
          <Col sm={12} md={12} className="d-flex align-items-center">
            <h4 className="m-0 font-weight-bold page-title">Analytics</h4>
          </Col>
        </Row>
        <Row className="analytics-content m-0">
          <Col sm={12} md={6} className="p-0">
            <div className="analytics-content__segment">
              <Row className="mb-2">
                <Col className="d-flex flex-column">
                  <p className="text-base">Launched Auctions</p>
                  <p>
                    <span className="font-weight-bold text-3xl">{getTotalWithoutSold()}</span>
                    <span className="text-sm"> / {getSold()} Sold</span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AnalyticsPieChart
                    data={auctionsData}
                    total={getTotalWithoutSold()}
                    label="SELLING"
                    onClick={handleLaunchedBidsPieClick}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={12} md={6} className="p-0">
            <div className="analytics-content__segment">
              <Row className="mb-2">
                <Col className="d-flex flex-column">
                  <p className="text-base">Placed Bids</p>
                  <p>
                    <span className="font-weight-bold text-3xl">
                      {getTotalBidsWithoutWon()}
                    </span>
                    <span className="text-sm"> / {getWon()} Won</span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AnalyticsPieChart
                    data={bidData}
                    total={getTotalBidsWithoutWon()}
                    label="BUYING"
                    onClick={handlePlacedBidsPieClick}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </AccountLayoutWrapper>
    </>
  );
};

AnalyticsPage.propTypes = {};

export default AnalyticsPage;
