import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import WalletDetailsForm from './forms/WalletDetailsForm';
import CommonMyOrganizationContainer from '../common/components/CommonMyOrganizationContainer';
import RouterPaths from '../constants/RouterPaths';
import { clearStripeValidationMessage } from '../register/RegisterActions';
import { getPaymentMethod } from './MyWalletActions';

const MyWalletPage = ({ history }) => {
  const dispatch = useDispatch();
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const last4 = useSelector(state => state.myWallet.get('last4'));
  const [hasAccess, setHasAccess] = useState(false);

  const initialValues = {
    cardNumber: last4 ? `**** **** **** ${last4}` : '',
    cardholder: '',
  };

  useEffect(() => {
    if (!isOwner) {
      setHasAccess(false);
      return history.push(RouterPaths.DASHBOARD);
    }
    return setHasAccess(true);
  }, [isOwner, history]);

  useEffect(() => {
    dispatch(getPaymentMethod());
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(clearStripeValidationMessage());
  }, [dispatch]);

  return (
    <>
      {hasAccess && (
        <CommonMyOrganizationContainer>
          <Formik initialValues={initialValues} onSubmit={() => null} enableReinitialize>
            {({ values }) => <WalletDetailsForm values={values} />}
          </Formik>
        </CommonMyOrganizationContainer>
      )}
    </>
  );
};

export default withRouter(MyWalletPage);
