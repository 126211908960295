import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { dateToString } from '../../../common/utils/date/Date.utils';
import { preparePriceFormat } from '../../../common/helpers/preparePriceFormat';

const TransactionTableRow = ({ item }) => {
  return (
    <>
      <td>{dateToString(item?.createdOn)}</td>
      <td>{item.title}</td>
      <td>
        <span className="font-weight-bold">
          {preparePriceFormat(item.amountInPence, true)}
        </span>
      </td>
    </>
  );
};

TransactionTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default withRouter(TransactionTableRow);
