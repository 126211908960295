import React, { useState } from 'react';
import { Alert, Col, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Form } from 'formik';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { UserStatus } from '../../constants/UserStatus';
import FormInputField from '../../common/components/formFields/FormInputField';
import {
  getStripeAccountClientSecret,
  getStripeDirectDebitCheckoutSession,
  updateCreditCard,
} from '../MyWalletActions';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { StripeValidationErrorType } from '../../constants/StripeValidationError';
import { validateFormField } from '../../common/helpers/validateFormField';
import { FormFieldTypes } from '../../constants/FormFieldTypes';
import { PaymentMethod } from '../../constants/PaymentMethod';
import { AccountLayoutContentWrapper } from '../../common/components/AccountLayoutWrapper';
import SettingsHeader from '../../settings/SettingsHeader';
import plusIcon from '../../assets/img/icons/plus.svg';
import { getCardElementOptions } from '../../constants/CardElementOptions';
import creditCardIcon from '../../assets/img/icons/credit-card.svg';
import calendarIcon from '../../assets/img/icons/calendar.svg';
import keyIcon from '../../assets/img/icons/key.svg';

const { CREDIT_CARD, DIRECT_DEBIT, BASED_ON_THE_INVOICE } = PaymentMethod;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const WalletDetailsFormInner = ({ values }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const last4 = useSelector(state => state.myWallet.get('last4'));
  const paymentMethod = useSelector(state => state.myWallet.get('paymentMethod'));
  const userAuthStatus = useSelector(state => state.auth.get('status'));
  const stripeValidationMessage = useSelector(state =>
    state.register.get('stripeValidationMessage'),
  );

  const [isChangeAccepted, setIsChangeAccepted] = useState(false);
  const [errors, setErrors] = useState({
    cardholderError: '',
    cvcCodeError: '',
    cardNumberError: '',
    expiryError: '',
  });

  const handleInputChange = (name, error) => {
    setErrors({ ...errors, [`${name}Error`]: error ? error.message : '' });
  };

  const hasAnyError = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in errors) {
      if (errors[key]) {
        return true;
      }
    }

    return false;
  };

  const isSubmittingDisabled = isLoading || !values?.cardholder || hasAnyError();

  const renderBlockedDueToPaymentMessage = isDirectDebitMethodPayment => {
    if (last4) {
      return (
        <Alert color="success">
          <p className="m-0">
            {`Thank you for adding the ${
              isDirectDebitMethodPayment ? 'direct debit account' : 'card'
            }. After successful
          verification your account will be activated in a few minutes. If not, please contact
          us.`}
          </p>
        </Alert>
      );
    }
    if (last4 === null) {
      return (
        <Alert color="error">
          <p className="m-0">
            We could not charge the payment from your account. Please enter a valid credit card
            number.
          </p>
        </Alert>
      );
    }
    return null;
  };

  const renderPaymentMethodForm = () => {
    switch (paymentMethod) {
      case CREDIT_CARD:
      case BASED_ON_THE_INVOICE:
        return (
          <>
            {userAuthStatus === UserStatus.BLOCKED_DUE_TO_PAYMENT ? (
              <Row>
                <Col>{renderBlockedDueToPaymentMessage()}</Col>
              </Row>
            ) : (
              <>
                {last4 === null && !isChangeAccepted && (
                  <Alert color="info">
                    <p className="m-0">
                      No card added to the account. Click{' '}
                      <span className="text-uppercase font-weight-bold">ADD CARD</span> to add
                      a new card.
                    </p>
                  </Alert>
                )}
              </>
            )}
            {!isChangeAccepted && last4 && (
              <Row className="justify-content-between">
                <Col md="12" className="d-flex flex-column">
                  <FormInputField
                    name="cardNumber"
                    placeholder="Card Number"
                    icon={creditCardIcon}
                    iconClassName="input-right-icon"
                    disabled={true}
                    label="Credit Card Number"
                  />
                </Col>
              </Row>
            )}
            {isChangeAccepted && (
              <>
                <Row>
                  <Col md="6">
                    <FormInputField
                      name="cardholder"
                      placeholder="Cardholder's name"
                      disabled={isLoading}
                      label="Card Holder"
                    />
                    {errors.cardholderError && (
                      <CommonErrorLabel value={errors.cardholderError} />
                    )}
                  </Col>
                  <Col md="6">
                    <Label
                      className={`form-input-field-label ${
                        isLoading ? 'form-input-field-label__disabled' : ''
                      }`}
                    >
                      Credit Card Number
                    </Label>
                    <div
                      className={`form-control card-element-wrapper mb-2 position-relative ${
                        isLoading ? 'form-control--disabled' : ''
                      }`}
                    >
                      <CardNumberElement
                        elements={elements}
                        options={getCardElementOptions(isLoading)}
                        onChange={({ error }) => handleInputChange('cardNumber', error)}
                        id="credit-card-container"
                      />
                      <img
                        src={creditCardIcon}
                        className="input-icon input-right-icon"
                        alt="card number"
                      />
                    </div>
                    {stripeValidationMessage ? (
                      <CommonErrorLabel value={stripeValidationMessage} />
                    ) : (
                      ''
                    )}
                    <CommonErrorLabel value={errors?.cardNumberError || ''} />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <Label
                      className={`form-input-field-label ${
                        isLoading ? 'form-input-field-label__disabled' : ''
                      }`}
                    >
                      Expiration Month and Year
                    </Label>
                    <div
                      className={`form-control card-element-wrapper mb-2 position-relative ${
                        isLoading ? 'form-control--disabled' : ''
                      }`}
                    >
                      <CardExpiryElement
                        elements={elements}
                        options={getCardElementOptions(isLoading)}
                        onChange={({ error }) => handleInputChange('expiry', error)}
                        id="credit-card-container"
                      />
                      <img
                        src={calendarIcon}
                        className="input-icon input-right-icon"
                        alt="card exp date"
                      />
                    </div>
                    <CommonErrorLabel value={errors?.expiryError || ''} />
                  </Col>
                  <Col md="6">
                    <Label
                      className={`form-input-field-label ${
                        isLoading ? 'form-input-field-label__disabled' : ''
                      }`}
                    >
                      CVC Code
                    </Label>
                    <div
                      className={`form-control card-element-wrapper mb-2 position-relative ${
                        isLoading ? 'form-control--disabled' : ''
                      }`}
                    >
                      <CardCvcElement
                        elements={elements}
                        options={getCardElementOptions(isLoading)}
                        onChange={({ error }) => handleInputChange('cvcCode', error)}
                        id="credit-card-container"
                      />
                      <img
                        src={keyIcon}
                        className="input-icon input-right-icon"
                        alt="card cvc number"
                      />
                    </div>
                    <CommonErrorLabel value={errors?.cvcCodeError || ''} />
                  </Col>
                </Row>
              </>
            )}
          </>
        );
      case DIRECT_DEBIT:
        return (
          <div className="d-flex flex-column align-items-center">
            {userAuthStatus === UserStatus.BLOCKED_DUE_TO_PAYMENT && (
              <Row>
                <Col>{renderBlockedDueToPaymentMessage(true)}</Col>
              </Row>
            )}
            {!last4 && (
              <Alert color="info">
                <p className="text-center mb-5 payment wallet-details__no-card">
                  Your payment method is{' '}
                  <span className="text-uppercase font-weight-bold">Direct Debit</span>. Click{' '}
                  <span className="text-uppercase font-weight-bold">Add</span> to start
                  process.
                </p>
              </Alert>
            )}
            {last4 && (
              <Col md="12" className="d-flex flex-column">
                <FormInputField
                  name="cardNumber"
                  placeholder="Card Number"
                  icon={creditCardIcon}
                  iconClassName="input-right-icon"
                  disabled={!isChangeAccepted}
                />
              </Col>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const validateForm = () =>
    new Promise((resolve, reject) => {
      const { cardholder } = values;
      const updatedErrors = {
        ...errors,
        cardholderError: validateFormField(cardholder, FormFieldTypes.STRING),
      };
      setErrors(updatedErrors);

      const { cardholderError, cvcCodeError, cardNumberError, expiryError } = updatedErrors;
      if (cardholderError || cvcCodeError || cardNumberError || expiryError) {
        reject();
      }
      resolve();
    });

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    try {
      await validateForm();
      dispatch(
        getStripeAccountClientSecret(values, stripe, elements, stripePaymentMethodId =>
          dispatch(updateCreditCard(stripePaymentMethodId, () => setIsChangeAccepted(false))),
        ),
      );
    } catch (e) {
      console.error(StripeValidationErrorType.VALIDATION_ERROR);
    }
  };

  return (
    <>
      <SettingsHeader>
        <Row>
          <Col className="d-flex justify-content-end align-items-center actions-wrapper">
            {isChangeAccepted ? (
              <>
                {' '}
                <CommonButton
                  className="text-sm"
                  label="CANCEL"
                  variant={CommonButtonVariants.OUTLINED}
                  handleClick={() => setIsChangeAccepted(false)}
                />
                <CommonButton
                  className="text-sm"
                  label="SAVE"
                  disabled={isSubmittingDisabled}
                  variant={CommonButtonVariants.PRIMARY}
                  handleClick={handleSubmit}
                />
              </>
            ) : (
              <CommonButton
                className="text-sm"
                label={`${last4 ? 'CHANGE CARD' : 'ADD CARD'}`}
                variant={CommonButtonVariants.PRIMARY}
                icon={last4 ? null : plusIcon}
                iconAlt="Add New Location"
                iconLeft={true}
                handleClick={() => {
                  if (paymentMethod === DIRECT_DEBIT) {
                    dispatch(getStripeDirectDebitCheckoutSession(stripe));
                  } else {
                    setIsChangeAccepted(true);
                  }
                }}
              />
            )}
          </Col>
        </Row>
      </SettingsHeader>
      <AccountLayoutContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">Payment Card</h5>
            <p className="text-sm mt-2">Here you will find card information for payments.</p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12} className="justify-content-center mr-auto ml-auto">
            <Form>{renderPaymentMethodForm()}</Form>
          </Col>
        </Row>
      </AccountLayoutContentWrapper>
    </>
  );
};

const WalletDetailsForm = ({ values }) => {
  return (
    <Elements stripe={stripePromise}>
      <WalletDetailsFormInner values={values} />
    </Elements>
  );
};

export default WalletDetailsForm;
