import React, { useRef, useEffect } from 'react';
import { v4 } from 'uuid';
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '../../assets/css/componentStyles/Fancybox.css';

export const fancyBoxOptions = {
  Thumbs: {
    type: 'classic',
    showOnStart: false,
  },
  Carousel: {
    infinite: true,
  },
  Toolbar: {
    display: {
      right: ['zoomIn', 'zoomOut', 'fullscreen', 'slideshow', 'thumbs', 'close'],
    },
  },
};

export const FancyBox = props => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || '[data-fancybox]';
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
};

export const FancyBoxHiddenGallery = ({ media = [], group }) => {
  return (
    <div style={{ display: 'none' }}>
      {media?.length
        ? media.map(el => {
            return (
              <>
                {el?.type === 'video' ? (
                  <a
                    key={v4()}
                    data-fancybox={group}
                    href={el.fileUrl}
                    data-caption={el?.caption || ''}
                    data-type="vimeo"
                  >
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img src={el?.previewUrl || el?.fileUrl} alt="" />
                  </a>
                ) : (
                  <a
                    key={v4()}
                    data-fancybox={group}
                    href={el.fileUrl}
                    data-caption={el?.caption || ''}
                    data-damages={el?.damages?.length}
                    data-id={el?.id}
                  >
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img src={el.fileUrl} alt="" />
                  </a>
                )}
              </>
            );
          })
        : ''}
    </div>
  );
};
