import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import TransactionForm from './forms/TransactionForm';
import CommonMyOrganizationContainer from '../../common/components/CommonMyOrganizationContainer';
import TransactionTable from './table/TransactionTable';
import { setQueryString, useQueryString } from '../../common/helpers/queryString';
import { searchTransactions } from '../SettingsActions';
import RouterPaths from '../../constants/RouterPaths';
import SettingsHeader from '../SettingsHeader';
import { AccountLayoutContentWrapper } from '../../common/components/AccountLayoutWrapper';

const TransactionsPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const items = useSelector(state => state.myOrganization.get('transactions'));
  const totalItems = useSelector(state => state.myOrganization.get('transactionsTotalItems'));
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (!isOwner) {
      setHasAccess(false);
      return history.push(RouterPaths.DASHBOARD);
    }
    return setHasAccess(true);
  }, [isOwner, history]);

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(searchTransactions(searchParams));
  }, [dispatch, searchParams]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  const handleSearch = (values, { setSubmitting }) => {
    setSearchParams(prevState => ({ ...prevState, ...values, offset: 0 }));
    setSubmitting(false);
  };

  return (
    <>
      {hasAccess && (
        <CommonMyOrganizationContainer>
          <SettingsHeader />
          <AccountLayoutContentWrapper>
            <Row>
              <Col>
                <h5 className="font-weight-bold m-0">Transactions</h5>
                <p className="text-sm mt-2">
                  In the table you can see transactions made on the itrade30 platform.
                </p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between align-items-center mb-3 mt-4">
              <Col md={12}>
                <TransactionForm handleSearch={handleSearch} />
              </Col>
            </Row>
            <Row>
              <TransactionTable
                data={{ items, total: totalItems }}
                offset={searchParams.offset}
                changeOffset={changeOffset}
              />
            </Row>
          </AccountLayoutContentWrapper>
        </CommonMyOrganizationContainer>
      )}
    </>
  );
};

export default withRouter(TransactionsPage);
