import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

const AuctionSellingPersonInformation = ({ item }) => {
  const { firstName, lastName, email, phoneNumber } = item?.seller;

  return (
    <>
      <Row
        className="main-description-container__section-title mt-4"
        id="vehicle-selling-person-info"
      >
        <Col md={12} className="p-0 pb-2 text-lg">
          Selling Person Information
        </Col>
      </Row>
      <Row className="mt-3 summary__selling-person-list text-base">
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Contact Name</p>
          <p className="m-0 fw-600">
            {firstName} {lastName}
          </p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Email</p>
          <p className="m-0 fw-600">{email}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Mobile</p>
          <p className="m-0 fw-600">{phoneNumber}</p>
        </Col>
      </Row>
    </>
  );
};

AuctionSellingPersonInformation.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AuctionSellingPersonInformation;
