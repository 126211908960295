import React from 'react';
import ContactUsForm from '../../common/components/Forms/ContactUsForm';

const ContactUsSection = () => {
  return (
    <section className="section contact-us-section">
      <ContactUsForm />
    </section>
  );
};

export default ContactUsSection;
