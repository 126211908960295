import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Sector } from 'recharts';
import { Row, Col } from 'reactstrap';
import uuid from 'react-uuid';

const AnalyticsPieChart = ({ data, label, total, onClick }) => {
  const [activePie, setActivePie] = useState(null);
  const dataTotal = useMemo(() => {
    if (!data?.length) {
      return 0;
    }

    const result = data.reduce((acc, el) => (el?.value || 0) + acc, 0);
    return result <= 0 ? 0 : result;
  }, [data]);

  const renderActiveShape = useCallback(
    props => {
      const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
      return (
        <g>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius + 2}
            startAngle={startAngle + 2}
            endAngle={endAngle - 2}
            fill={fill}
            onMouseLeave={onPieLeave}
          />
        </g>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePie],
  );

  const onPieOver = useCallback((_, index) => {
    setActivePie({
      index,
      name: _?.name,
      value: _?.value,
      color: _?.fill,
    });
  }, []);

  const onPieEnter = (_, index) => {
    setActivePie({
      index,
      name: _?.name,
      value: _?.value,
      color: _?.fill,
    });
  };

  const onPieLeave = useCallback(() => {
    setActivePie(null);
  }, []);

  const isActive = name => {
    return activePie && activePie?.name?.toLowerCase() === name.toLowerCase();
  };

  return (
    <>
      {data?.length ? (
        <Row className="analytics-pie-chart">
          <Col
            sm={12}
            md={4}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Row className="d-flex flex-column analytics-pie-chart__legend w-100">
              {data.map(el => (
                <div className="analytics-pie-chart__legend-label flex-grow-1 w-100 d-flex align-items-center text-2xs">
                  <div
                    className="legend-label__color mr-2"
                    style={{ backgroundColor: el?.color || '#9CB6DF' }}
                  />{' '}
                  <p
                    className="m-0"
                    style={isActive(el?.name || '-') ? { fontWeight: 700 } : {}}
                  >
                    {el?.name || '-'} {el?.value || 0}
                  </p>
                </div>
              ))}
            </Row>
          </Col>
          <Col
            sm={12}
            md={8}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <PieChart width={280} height={280}>
              <Pie
                data={data.map(el => {
                  return {
                    name: el?.name || '',
                    value: el?.value || '#9CB6DF',
                    type: el?.type || undefined,
                  };
                })}
                innerRadius={60}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
                activeIndex={activePie?.index || null}
                activeShape={renderActiveShape}
                onMouseEnter={onPieEnter}
                onMouseOver={onPieOver}
                onMouseLeave={onPieLeave}
                onMouseUp={(_, index) => {
                  if (onClick) {
                    onClick(_, index);
                  }
                }}
              >
                {data.map(el => (
                  <Cell key={uuid()} fill={el?.color || '#9CB6DF'} />
                ))}
              </Pie>
            </PieChart>
            {activePie ? (
              <div className="analytics-pie-chart__centered-text">
                <div className="font-weight-bold text-3xl">{activePie?.value || ''}</div>
                <div className="text-sm text-uppercase">{activePie?.name || ''}</div>
              </div>
            ) : (
              <div className="analytics-pie-chart__centered-text">
                <div className="font-weight-bold text-3xl">{total}</div>
                <div className="text-sm">{label}</div>
              </div>
            )}
            {!dataTotal ? <div className="analytics-pie-chart__empty" /> : ''}
          </Col>
        </Row>
      ) : (
        ''
      )}
    </>
  );
};

AnalyticsPieChart.defaultProps = {
  label: '',
  total: 0,
  onClick: null,
};

AnalyticsPieChart.propTypes = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  total: PropTypes.number,
  onClick: PropTypes.func,
};

export default AnalyticsPieChart;
