import { pencesToPounds } from './pencesToPounds';
import {
  AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE,
  AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE
} from '../../constants/AuctionBid';

export const validateBidAmount = (value, auction, highestBidMyOrganization, stateCallback) => {
  const isCurrentBidderWinning = auction.bids.some(it => it.isHighest);
  let requiredBidAmount;

  if (isCurrentBidderWinning) {
    requiredBidAmount = pencesToPounds(
      auction.highestBidInPence + AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE,
    );
    if (value < requiredBidAmount) {
      stateCallback(requiredBidAmount);
      return false;
    }
  } else {
    if (auction.proxyBidInPence && auction.highestBidInPence) {
      requiredBidAmount = pencesToPounds(
        auction.proxyBidInPence + AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE,
      );
    } else if (auction.highestBidInPence) {
      requiredBidAmount = pencesToPounds(
        auction.highestBidInPence + AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE,
      );
    } else if (auction.minimumPriceInPence) {
      requiredBidAmount = pencesToPounds(auction.minimumPriceInPence);
    } else {
      requiredBidAmount = pencesToPounds(AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE);
    }

    if (value < requiredBidAmount) {
      stateCallback(requiredBidAmount);
      return false;
    }
  }
  return true;
};
