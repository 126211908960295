import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { CommonString } from 'constants/CommonString';
import { useSelector } from 'react-redux';
import noImageImg from '../assets/img/vehicles/default.png';
import { capitalizeString } from '../common/helpers/capitalizeString';
import { checkIsPropertySpecified } from '../common/helpers/checkIsPropertySpecified';
import { PhotoPerspective } from '../constants/PhotoPerspective';
import { pencesToPounds } from '../common/helpers/pencesToPounds';
import AuctionTypeLabel from './AuctionTypeLabel';
import { getPriceColor } from '../common/helpers/auctionColorHelper';
import { auctionListType } from '../constants/AuctionListType';
import CurrentBidLabel from './CurrentBidLabel';
import OrganizationRoleBox from './OrganizationRoleBox';

import carCalendarBlue from '../assets/img/vehicles/car-calendar-blue.svg';
import carRegistrationBlue from '../assets/img/vehicles/car-registration-blue.svg';
import carColorBlue from '../assets/img/vehicles/car-color-blue.svg';
import carEngineBlue from '../assets/img/vehicles/car-engine-blue.svg';
import carTransmissionBlue from '../assets/img/vehicles/car-transmission-blue.svg';
import carMileageBlue from '../assets/img/vehicles/car-mileage-blue.svg';
import carLocationBlue from '../assets/img/vehicles/car-location-blue.svg';
import carFuelBlue from '../assets/img/vehicles/car-fuel-blue.svg';

import { AuctionStatusTypeName } from '../constants/AuctionStatusType';
import { AuctionStatus, AuctionStatusColors } from '../constants/AuctionStatus';
import { AuctionItemMessage } from '../constants/AuctionItemMessage';
import AuctionMainDescriptionContainer, {
  SlotItems,
} from '../common/components/Auction/AuctionMainDescriptionContainer';
import Derivative from '../common/components/Auction/Derivative';
import { prepareVehicleTitle } from '../common/helpers/prepareVehicleTitle';
import { AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE } from '../constants/AuctionBid';

const MyVehiclesItem = ({ item, onClickItem, getRef }) => {
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  const [windowFocus, setWindowFocus] = useState(false);
  const windowOnFocus = () => {
    setWindowFocus(true);
  };

  const windowOnBlur = () => {
    setWindowFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', windowOnFocus);
    window.addEventListener('blur', windowOnBlur);
    return () => {
      window.removeEventListener('focus', windowOnFocus);
      window.removeEventListener('blur', windowOnBlur);
    };
  }, []);

  useEffect(() => {
    renderMyBidItemMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const renderMyBidItemMessage = () => {
    let message;
    let statusColor;

    switch (item?.status) {
      case AuctionStatus.DRAFT:
        message = AuctionItemMessage.DRAFT;
        statusColor = AuctionStatusColors.DRAFT;
        break;
      case AuctionStatus.PUBLISHED:
        message = AuctionItemMessage.PUBLISHED;
        statusColor = AuctionStatusColors.PUBLISHED;
        break;
      case AuctionStatus.CANCELLED:
        message = AuctionItemMessage.CANCELLED;
        statusColor = AuctionStatusColors.CANCELLED;
        break;
      case AuctionStatus.TO_ACCEPT:
        message = AuctionItemMessage.TO_ACCEPT_SELLER;
        statusColor = AuctionStatusColors.TO_ACCEPT_BUYER;
        break;
      case AuctionStatus.WAITING:
        message = AuctionItemMessage.WAITING;
        statusColor = AuctionStatusColors.TO_ACCEPT_SELLER;
        break;
      case AuctionStatus.REJECTED:
        message = AuctionItemMessage.REJECTED;
        statusColor = AuctionStatusColors.REJECTED;
        break;
      case AuctionStatus.SOLD:
        message = AuctionItemMessage.SOLD;
        statusColor = AuctionStatusColors.SOLD;
        break;
      case AuctionStatus.ENDED:
        message = AuctionItemMessage.ENDED;
        statusColor = AuctionStatusColors.ENDED;
        break;
      default:
        return null;
    }
    return { message, statusColor };
  };

  let image = noImageImg;
  if (item?.vehicle?.vehicleMedia?.photos?.length) {
    const masterPhoto = item?.vehicle?.vehicleMedia?.photos?.find(
      item => item?.perspective === PhotoPerspective.MASTER,
    );
    image = masterPhoto?.fileUrl || noImageImg;
  }

  const title = prepareVehicleTitle(item?.vehicle) || CommonString.VEHICLE_NOT_SPECIFIED;
  const organizationRole = item?.organizationRole || item?.seller?.organizationContact?.role;

  // eslint-disable-next-line no-unused-vars
  const renderAuctionType = (isDesktop = true) => {
    if (!item.type) {
      return null;
    }
    return (
      <>
        <p
          className={`auction-item__status-type ${
            isDesktop ? 'search-min-price--desktop' : 'search-min-price--mobile'
          }`}
        >
          <AuctionTypeLabel hasBid={!!item?.highestBidInPence} type={item.type} />
        </p>
        <p
          className={`auction-item__status-type ${
            isDesktop ? 'search-min-price--desktop' : 'search-min-price--mobile'
          }`}
        >
          <CurrentBidLabel hasBid={!!item?.highestBidInPence} type={item.type} />
          <span className="search-min-price" style={getPriceColor(item.shouldPriceBeGreen)}>
            <CurrencyFormat
              value={
                pencesToPounds(item?.highestBidInPence) ||
                pencesToPounds(item?.minimumPriceInPence) ||
                pencesToPounds(AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE)
              }
              thousandSeparator={true}
              allowNegative={false}
              prefix="£"
              displayType="text"
            />
          </span>
        </p>
      </>
    );
  };

  const isAuctionLabel = useMemo(() => {
    return !!(item?.type || AuctionStatusTypeName[item?.type]);
  }, [item.type]);

  const shortMeText = (text, length = 12) => {
    if (text?.length > length) {
      return `${text?.slice(0, length)}...`;
    }

    return text || '';
  };

  return (
    <Row
      className="search-box-of-car my-vehicles-box-of-car search-box-of-car--mobile"
      key={item.id}
      onClick={onClickItem}
    >
      <Col sm={12} md={3} className="search-box-of-img search-box-of-img--mobile flex-column">
        <div className="search-box-of-img-wrapper" ref={el => getRef(el)}>
          <img
            className="search-img-in-box"
            src={image}
            alt="car"
            onError={e => {
              e.target.src = noImageImg;
            }}
          />
        </div>
        <div className="search-title-wrapper--mobile">
          <p className="search-title--mobile">{title}</p>
        </div>
      </Col>
      <Col sm={12} md={9} className="h-100">
        <Row
          md={12}
          className="auction-basic-data-row h-100 d-flex flex-column position-relative"
        >
          <Col md={12} className="d-flex flex-column">
            <Row>
              <Col className="d-flex flex-row">
                <div
                  className={`auction-basic-data__type-label text-uppercase ${
                    isAuctionLabel ? '' : 'hide'
                  }`}
                >
                  <AuctionTypeLabel hasBid={!!item?.highestBidInPence} type={item.type} />
                </div>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <p className="search-title m-0 font-weight-bold">
                  {shortMeText(checkIsPropertySpecified(title, true), 56)}
                </p>
              </Col>
            </Row>
            <Row
              md={12}
              className="search-content-left auction-basic-data-wrapper flex-grow-1"
            >
              <Col className="flex-grow-1">
                <Row className="auction-basic-data__container">
                  <Col md={3} className="basic-data-col">
                    {' '}
                    <div
                      className={`d-flex flex-row align-items-center ${
                        !item?.vehicle?.year ? 'd-none' : ''
                      }`}
                    >
                      <div className="auction-item__icon">
                        <img src={carCalendarBlue} alt="Vehicle Calendar" />
                      </div>
                      <p className="m-0 ml-2">
                        {checkIsPropertySpecified(item?.vehicle?.year, false, '', 'N/S')}
                      </p>
                    </div>
                  </Col>
                  <Col md={3} className="basic-data-col">
                    <div
                      className={`d-flex flex-row align-items-center ${
                        !item?.vehicle?.mileage ? 'd-none' : ''
                      }`}
                    >
                      <div className="auction-item__icon">
                        <img src={carMileageBlue} alt="Cat Mileage Blue" />
                      </div>
                      <p className="m-0 ml-2">
                        {checkIsPropertySpecified(
                          item?.vehicle?.mileage,
                          false,
                          'miles',
                          'N/S',
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col md={3} className="basic-data-col">
                    <div
                      className={`d-flex flex-row align-items-center ${
                        !item?.vehicle?.derivative ? 'd-none' : ''
                      }`}
                    >
                      <div className="auction-item__icon">
                        <img src={carEngineBlue} alt="Vehicle Engine" />
                      </div>
                      <p className="m-0 ml-2">
                        <Derivative derivative={item?.vehicle?.derivative} limit={9} />
                      </p>
                    </div>
                  </Col>
                  <Col md={3} className="basic-data-col">
                    {' '}
                    <div
                      className={`d-flex flex-row align-items-center ${
                        !item?.vehicle?.colour ? 'd-none' : ''
                      }`}
                    >
                      <div className="auction-item__icon">
                        <img src={carColorBlue} alt="Vehicle Color" />
                      </div>
                      <p className="m-0 ml-2">
                        {checkIsPropertySpecified(item?.vehicle?.colour, false, '', 'N/S')}
                      </p>
                    </div>
                  </Col>
                  <Col md={3} className="basic-data-col">
                    {' '}
                    <div
                      className={`d-flex flex-row align-items-center ${
                        !item?.vehicle?.registrationPlate ? 'd-none' : ''
                      }`}
                    >
                      <div className="auction-item__icon">
                        <img src={carRegistrationBlue} alt="Vehicle Registration" />
                      </div>
                      <p className="m-0 ml-2">
                        {checkIsPropertySpecified(
                          item?.vehicle?.registrationPlate,
                          false,
                          '',
                          'N/S',
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col md={3} className="basic-data-col">
                    <div
                      className={`d-flex flex-row align-items-center ${
                        !item?.vehicle?.transmission ? 'd-none' : ''
                      }`}
                    >
                      <div className="auction-item__icon">
                        <img src={carTransmissionBlue} alt="Vehicle Transmission" />
                      </div>
                      <p className="m-0 ml-2">
                        {checkIsPropertySpecified(
                          capitalizeString(item?.vehicle?.transmission),
                          false,
                          '',
                          'N/S',
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col md={3} className="basic-data-col">
                    <div
                      className={`d-flex flex-row align-items-center ${
                        !item?.vehicle?.fuel ? 'd-none' : ''
                      }`}
                    >
                      <div className="auction-item__icon">
                        <img src={carFuelBlue} alt="Vehicle Fuel" />
                      </div>
                      <p className="m-0 ml-2">
                        {checkIsPropertySpecified(
                          capitalizeString(item?.vehicle?.fuel),
                          false,
                          '',
                          'N/S',
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col md={3} className="basic-data-col">
                    {' '}
                    <div
                      className={`d-flex flex-row align-items-center ${
                        !item?.vehicle?.city ? 'd-none' : ''
                      }`}
                    >
                      <div className="auction-item__icon">
                        <img src={carLocationBlue} alt="Vehicle Location" />
                      </div>
                      <p className="m-0 ml-2">
                        {checkIsPropertySpecified(item?.location?.city, false, '', 'N/S')}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <AuctionMainDescriptionContainer
              item={item}
              organizationId={organizationId}
              windowFocus={windowFocus}
              listType={auctionListType.MY_VEHICLES}
              patternSizes={[3, 3, 3, 3]}
              pattern={[
                SlotItems.AUCTION_END,
                SlotItems.TIME_LEFT,
                SlotItems.CURRENT_BID,
                SlotItems.AUCTION_STATUS_BOX,
              ]}
            />
          </Col>
        </Row>
      </Col>
      <OrganizationRoleBox role={organizationRole} />
    </Row>
  );
};
MyVehiclesItem.defaultProps = {
  onClickItem: () => {},
  getRef: () => {},
};
MyVehiclesItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClickItem: PropTypes.func,
  getRef: PropTypes.func,
};

export default withRouter(MyVehiclesItem);
