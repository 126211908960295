import React from 'react';
import { Row, Col } from 'reactstrap';

import { NavLink } from 'react-router-dom';
import RouterPaths from '../../constants/RouterPaths';

import liveOnlineImage from '../../assets/img/HomePage/live-online-mockup.png';

const LiveOnlineAuctionSection = () => {
  return (
    <section className="live-online-auction section">
      <div className="line-online-auction__background-right hide-on-mobile-md" />
      <Row className="w-100">
        <Col className="live-online-auction-content" md={12}>
          <Row className="m-auto">
            <Col xs={12} md={6} lg={6} className="pl-0 pr-4 live-online-auction-content-left">
              <p className="fw-700 text-3xl mb-3">Live Online Auctions</p>
              <p className="mb-4 text-xl">
                View all currently running auctions live online. Fully detailed vehicle
                descriptions including appraisals, location and the ability to place instant
                bids on live listings.
              </p>
              <NavLink
                to={RouterPaths.REGISTER}
                className="common-button primary-button"
                activeClassName="auth-button--active"
              >
                VIEW ALL VEHICLES
              </NavLink>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="pr-0 pl-4 text-right live-online-auction-content-right"
            >
              <img
                className="live-online-auction__image"
                src={liveOnlineImage}
                alt="live online auctions"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default LiveOnlineAuctionSection;
