import RouterPaths from './RouterPaths';

export const ACTIVE_ROUTES_MAPPING = {
  [RouterPaths.MY_BIDS]: RouterPaths.DASHBOARD,
  [RouterPaths.MY_VEHICLES]: RouterPaths.DASHBOARD,
  [RouterPaths.ANALYTICS]: RouterPaths.DASHBOARD,
  [RouterPaths.EMPLOYEES]: RouterPaths.SETTINGS,
  [RouterPaths.MY_WALLET]: RouterPaths.SETTINGS,
  [RouterPaths.TRANSACTIONS]: RouterPaths.SETTINGS,
  [RouterPaths.INVOICES]: RouterPaths.SETTINGS,
  [RouterPaths.MY_PROFILE]: RouterPaths.SETTINGS,
  [RouterPaths.SEARCH_ALERTS]: RouterPaths.SETTINGS,
};
