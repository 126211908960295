import React from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { handleCarDataAutofil } from '../AddVehicleActions';
import FormInputField from '../../common/components/formFields/FormInputField';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';

const ConfirmNumber = ({ nextForm, prevForm }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));

  const moveToNextStep = e => {
    if (e.target.classList.contains('primary-button')) {
      dispatch(handleCarDataAutofil(true));
    } else {
      dispatch(handleCarDataAutofil(false));
    }
    nextForm();
  };

  return (
    <>
      <AddVehicleHeader
        hideBackButton={true}
        nextButtonHandler={moveToNextStep}
        nextButtonDisabled={!!isLoading}
        hideSaveDraftButton={true}
      />
      <AddVehicleContentWrapper>
        <Row>
          <Col>
            <Row>
              <Col>
                <h5 className="font-weight-bold">Registration Number</h5>
                <p className="text-sm">
                  Please enter your vehicle registration to start a new listing.
                </p>
              </Col>
            </Row>
            <Row className="align-items-center text-left mt-3">
              <Col sm={12} md={12}>
                <FormInputField
                  inputClassName="add-number-form__input-field"
                  buttonClassNames="add-number-form__search-button"
                  wrapperClassName="add-number-form__input-wrapper"
                  name="basicData.registrationPlate"
                  type="text"
                  label="Vehicle registration plate"
                  upperCase
                  withButton={true}
                  buttonLabel="Search"
                  buttonDisabled={true}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Alert color="info">
                  We could not find the data for this vehicle. Do you want to change the
                  registration number?
                </Alert>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="actions-wrapper" md={6}>
                <CommonButton
                  label="no"
                  handleClick={moveToNextStep}
                  variant={CommonButtonVariants.OUTLINED}
                  className="flex-grow-1"
                />
                <CommonButton label="yes" handleClick={prevForm} className="flex-grow-1" />
              </Col>
            </Row>
          </Col>
        </Row>
      </AddVehicleContentWrapper>
    </>
  );
};

ConfirmNumber.propTypes = {
  nextForm: PropTypes.func.isRequired,
  prevForm: PropTypes.func.isRequired,
};

export default ConfirmNumber;
