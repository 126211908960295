export const AddVehicleSteps = {
  VEHICLE_REGISTRATION: 'VEHICLE_REGISTRATION',
  VEHICLE_DETAILS: 'VEHICLE_DETAILS',
  PHOTOS: 'PHOTOS',
  VIDEOS: 'VIDEOS',
  TYRES: 'TYRES',
  SERVICE_HISTORY: 'SERVICE_HISTORY',
  SPECIFICATION: 'SPECIFICATION',
  DESCRIPTION: 'DESCRIPTION',
  LAUNCH_VEHICLE: 'LAUNCH_VEHICLE',
};

export const AddVehicleStepsName = {
  VEHICLE_REGISTRATION: 'VEHICLE REGISTRATION',
  VEHICLE_DETAILS: 'VEHICLE DETAILS',
  PHOTOS: 'PHOTOS',
  VIDEOS: 'VIDEOS',
  TYRES: 'TYRES',
  SERVICE_HISTORY: 'SERVICE HISTORY',
  SPECIFICATION: 'SPECIFICATION',
  DESCRIPTION: 'DESCRIPTION',
  LAUNCH_VEHICLE: 'LAUNCH VEHICLE',
};

export const AddVehicleNavMapping = {
  [AddVehicleSteps.VEHICLE_REGISTRATION]: [1, 2],
  [AddVehicleSteps.VEHICLE_DETAILS]: [3],
  [AddVehicleSteps.PHOTOS]: [4, 5],
  [AddVehicleSteps.VIDEOS]: [6],
  [AddVehicleSteps.TYRES]: [7],
  [AddVehicleSteps.SERVICE_HISTORY]: [8],
  [AddVehicleSteps.SPECIFICATION]: [9],
  [AddVehicleSteps.DESCRIPTION]: [10],
  [AddVehicleSteps.LAUNCH_VEHICLE]: [11],
};

export const AddVehicleStepsMapping = {
  VEHICLE_REGISTRATION: 1,
  VEHICLE_DETAILS: 3,
  PHOTOS: 4,
  PHOTOS_SUMMARY: 5,
  VIDEOS: 6,
  TYRES: 7,
  SERVICE_HISTORY: 8,
  SPECIFICATION: 9,
  DESCRIPTION: 10,
  LAUNCH_VEHICLE: 11,
};
