import React, { useState } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import TablePagination from './TablePagination';

const CommonTable = ({
  offset,
  onOffsetChange,
  data,
  ordinal,
  headerOptions,
  rowComponent: RowComponent,
  rowComponentProps,
  rowStyle,
  rowDecoration,
  pagination,
  partial,
  partialCount,
  messageOnEmptyList,
  className,
  lastIsActionColumn,
}) => {
  const [showMore, setShowMore] = useState(false);

  const handleClick = () => {
    setShowMore(prev => !prev);
  };

  return (
    <>
      <div>
        <Table
          className={`common-table ${className} ${
            lastIsActionColumn ? 'common-table__action-column' : ''
          }`}
          responsive
        >
          {!isEmpty(headerOptions) && (
            <thead className="common-table__header">
              <tr>
                {headerOptions.map(option => (
                  <th className="common-table-header__cell" key={uuid()}>
                    {option}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody className="common-table__body">
            {!isEmpty(data.items) &&
              data.items.map((item, index) =>
                !partial || showMore || (partial && !showMore && index < partialCount) ? (
                  <React.Fragment key={item.id}>
                    {rowDecoration(item)}
                    <tr
                      key={item.id}
                      className={`${rowStyle(item)} ${
                        index % 2 === 0 ? 'common-table-row-even' : ''
                      }`}
                    >
                      {ordinal && <td className="text-center">{offset + index + 1}</td>}
                      <RowComponent item={item} {...rowComponentProps} />
                    </tr>
                  </React.Fragment>
                ) : null,
              )}
          </tbody>
        </Table>
        {isEmpty(data.items) && (
          <div className="common-table__empty">
            <span>{messageOnEmptyList}</span>
          </div>
        )}
        {pagination && (
          <div className="mt-2">
            {!isEmpty(data.items) && (data?.total || 0) > 10 && (
              <TablePagination
                total={data.total}
                offset={offset}
                pageSize={10}
                onOffsetChange={offset => onOffsetChange(offset)}
              />
            )}
          </div>
        )}
        {partial && (
          <Row>
            <Col md="12">
              <Button
                color="neutral"
                className="mt-4 pull-right"
                type="button"
                onClick={handleClick}
              >
                {!showMore ? 'more' : 'less'}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

CommonTable.defaultProps = {
  titleTranslated: false,
  offset: 0,
  onOffsetChange: () => {},
  ordinal: false,
  rowComponentProps: {},
  rowStyle: () => {},
  rowDecoration: () => {},
  pagination: true,
  partial: false,
  partialCount: 5,
  messageOnEmptyList: 'No data to be displayed',
  headerOptions: [],
  lastIsActionColumn: false,
};

CommonTable.propTypes = {
  offset: PropTypes.number,
  onOffsetChange: PropTypes.func,
  data: PropTypes.object.isRequired,
  rowComponent: PropTypes.any.isRequired,
  titleTranslated: PropTypes.bool,
  ordinal: PropTypes.bool,
  rowComponentProps: PropTypes.object,
  rowStyle: PropTypes.func,
  rowDecoration: PropTypes.func,
  pagination: PropTypes.bool,
  partial: PropTypes.bool,
  partialCount: PropTypes.number,
  messageOnEmptyList: PropTypes.string,
  headerOptions: PropTypes.array,
  lastIsActionColumn: PropTypes.bool,
};

export default CommonTable;
