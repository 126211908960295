import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { Col, Row } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import FormInputField from '../../common/components/formFields/FormInputField';
import { clearValidationErrors, validateOrganization } from '../RegisterActions';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { validateFormField } from '../../common/helpers/validateFormField';
import { FormFieldTypes } from '../../constants/FormFieldTypes';
import Footer from '../Footer';
import phoneIcon from '../../assets/img/icons/phone.svg';
import webIcon from '../../assets/img/icons/web.svg';
import { scrollTop } from '../../common/helpers/generalHelper';
import { validatePostalCode } from './postcode/PostcodeActions';

const OrganizationDetailsForm = ({ prevStep, nextStep, values }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    scrollTop();
  }, []);

  const [errors, setErrors] = useState({
    postalCodeError: '',
    addressError: '',
    address2Error: '',
    cityError: '',
    countyError: '',
    phoneNumberError: '',
    mobileNumberError: '',
    websiteError: '',
  });

  useEffect(() => {
    return () => {
      dispatch(clearValidationErrors());
    };
  }, [dispatch]);

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      const {
        postalCode,
        address,
        address2,
        city,
        county,
        phoneNumber,
        mobileNumber,
        website,
      } = values;

      const errors = {
        postalCodeError: validateFormField(postalCode, null, false, 8, 6),
        addressError: validateFormField(address, null, false, 100),
        address2Error: validateFormField(address2, null, false, 100),
        cityError: validateFormField(city, null, false, 100),
        countyError: validateFormField(county, null, false, 100),
        phoneNumberError: validateFormField(phoneNumber, FormFieldTypes.NUMBER, false, 15),
        mobileNumberError: validateFormField(mobileNumber, FormFieldTypes.NUMBER, false, 15),
        websiteError: validateFormField(website, FormFieldTypes.URL, false, 200),
      };
      setErrors(errors);

      const {
        postalCodeError,
        addressError,
        address2Error,
        cityError,
        phoneNumberError,
        mobileNumberError,
        websiteError,
      } = errors;
      if (
        postalCodeError ||
        addressError ||
        address2Error ||
        cityError ||
        phoneNumberError ||
        mobileNumberError ||
        websiteError
      ) {
        reject();
      }
      resolve();
    });
  };

  const moveToNextStep = () => {
    validateForm()
      .then(async () => {
        const { postalCode } = values;
        if (postalCode) {
          const isPostalCodeValid = await validatePostalCode(postalCode);
          if (!isPostalCodeValid) {
            setErrors(prevErrors => ({
              ...prevErrors,
              postalCodeError: `Provided postal code does not exist`,
            }));
            return;
          }
        }
        dispatch(validateOrganization(values, () => nextStep()));
      })
      .catch(() => {});
  };

  const {
    postalCodeError,
    addressError,
    address2Error,
    cityError,
    countyError,
    mobileNumberError,
    phoneNumberError,
    websiteError,
  } = errors;

  return (
    <Col
      md="12"
      className="register-form-container d-flex flex-column justify-content-center align-items-center"
    >
      <Row md={12} className="register-form-container__title w-100">
        <Col md={12}>
          <h2 className="font-weight-bold">Add company address</h2>
          <p className="mt-1">Enter your company address and contact details.</p>
        </Col>
      </Row>
      <Row md={12} className="register-form-container__content justify-content-center w-100">
        <Form autoComplete="off" className="register-form__address-details">
          <Row className="justify-content-between">
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="address"
                  label="Address"
                  invalid={!!addressError}
                  capitalize
                />
                <CommonErrorLabel value={addressError} />
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="address2"
                  label="Address (line 2)"
                  invalid={!!address2Error}
                  capitalize
                />
                <CommonErrorLabel value={address2Error} />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md="6">
              <div className="p-3">
                <FormInputField name="city" label="Town" invalid={!!cityError} capitalize />
                <CommonErrorLabel value={cityError} />
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="county"
                  label="County"
                  invalid={!!countyError}
                  capitalize
                />
                <CommonErrorLabel value={countyError} />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="postalCode"
                  label="Postal code"
                  upperCase
                  invalid={!!postalCodeError}
                />
                <CommonErrorLabel value={postalCodeError} />
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="mobileNumber"
                  label="Mobile number"
                  invalid={!!mobileNumberError}
                  icon={phoneIcon}
                  iconClassName="input-right-icon"
                />
                <CommonErrorLabel value={mobileNumberError} />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="phoneNumber"
                  label="Landline Number"
                  invalid={!!phoneNumberError}
                  icon={phoneIcon}
                  iconClassName="input-right-icon"
                />
                <CommonErrorLabel value={phoneNumberError} />
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="website"
                  label="Website address"
                  invalid={!!websiteError}
                  icon={webIcon}
                  iconClassName="input-right-icon"
                />
                <CommonErrorLabel value={websiteError} />
              </div>
            </Col>
          </Row>
        </Form>
      </Row>
      <Footer prev={prevStep} next={moveToNextStep} />
    </Col>
  );
};

OrganizationDetailsForm.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default OrganizationDetailsForm;
