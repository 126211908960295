import { apiClient } from '../../../api/ApiClient';
import { handleError } from '../../../common/components/alert/AlertActions';

const POSTAL_CODE_ENDPOINT = '/postal-code';

export const getPostcodes = (search, successCallback) => dispatch => {
  return apiClient
    .post(POSTAL_CODE_ENDPOINT, { search, page: 0, limit: 50 })
    .then(res => successCallback(res.data))
    .catch(err => dispatch(handleError(err)));
};

export const validatePostalCode = async postalCode => {
  const res = await apiClient.get(`${POSTAL_CODE_ENDPOINT}/validate?postalCode=${postalCode}`);
  return res.status === 200 && res.data.data === true;
};
