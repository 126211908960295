import React, { useEffect } from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import FormCurrencyInputField from '../../common/components/formFields/FormCurrencyInputField';
import { AuctionTimeName } from '../../constants/AuctionTime';
import { AuctionStatusType, AuctionStatusTypeName } from '../../constants/AuctionStatusType';
import FormGroupRadioField from '../../common/components/formFields/FormGroupRadioField';
import { AuctionPublishTypeName } from '../../constants/AuctionPublishType';
import FormDatePickerField from '../../common/components/formFields/FormDatePickerField';
import { fetchOrganizationGroup } from '../AddVehicleActions';
import { DueInDateType, DueInDateTypeName } from '../../constants/DueInDateType';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { AccountLayoutContentWrapper } from '../../common/components/AccountLayoutWrapper';
import ConfirmLaunchFormHeader from './ConfirmLaunchFormHeader';

const auctionTimes = constantToSelect(AuctionTimeName);
const auctionTypes = constantToSelect(AuctionStatusTypeName);
const auctionPublishTypes = constantToSelect(AuctionPublishTypeName);
const dueInDateTypes = constantToSelect(DueInDateTypeName);

const ConfirmLaunchForm = ({ form, prevStep, item }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const organizationGroup = useSelector(state => state.addVehicle.get('organizationGroup'));
  const confirmLaunchValidationError = useSelector(state =>
    state.addVehicle.get('confirmLaunchValidationError'),
  );

  useEffect(() => {
    dispatch(fetchOrganizationGroup());
  }, [dispatch]);

  return (
    <>
      <ConfirmLaunchFormHeader
        handleLaunchForm={() => {
          form.handleSubmit();
        }}
        handleCancelForm={prevStep}
        isLaunchFormDisabled={isLoading || !item}
      />
      <AccountLayoutContentWrapper>
        <Form autoComplete="off" className="text-left">
          <Row>
            <Col>
              <h5 className="font-weight-bold m-0">Launch Vehicle</h5>
              {item?.isFreeRepublishAvailable === false && (
                <Alert color="info" className="mt-2">
                  <p className="m-0">
                    You have reached a limit of 3 launches within one transaction. By clicking
                    <span className="font-weight-bold"> Launch </span> you agree to a new
                    launch fee.
                  </p>
                </Alert>
              )}
              <p>You are launching the vehicle with the following settings:</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <div className="mt-3">
                <FormSelectField
                  name="type"
                  label="Choose a status"
                  options={auctionTypes}
                  disabled={!item}
                />
              </div>

              {form?.values?.type?.value === AuctionStatusType.MINIMUM_PRICE && (
                <div className="mt-3">
                  <FormCurrencyInputField
                    name="minimumPriceInPence"
                    label="Minimum Offer"
                    maxLength={14}
                    invalid={
                      !!form.errors.minimumPriceInPence ||
                      confirmLaunchValidationError?.minimumPriceInPence ||
                      confirmLaunchValidationError?.vehicleDescriptionAndSettingsForm
                    }
                    disabled={!item}
                  />
                  <CommonErrorLabel
                    value={
                      confirmLaunchValidationError?.minimumPriceInPence ||
                      (confirmLaunchValidationError?.vehicleDescriptionAndSettingsForm &&
                        'Minimum Offer must be greater than 0')
                    }
                  />
                </div>
              )}
              {form?.values?.type?.value === AuctionStatusType.PHYSICAL && (
                <div className="mt-3">
                  <FormCurrencyInputField
                    name="autoAcceptPriceInPence"
                    label="Reserve Price"
                    maxLength={14}
                    invalid={
                      !!form.errors.autoAcceptPriceInPence ||
                      confirmLaunchValidationError?.autoAcceptPriceInPence ||
                      confirmLaunchValidationError?.vehicleDescriptionAndSettingsForm
                    }
                    disabled={!item}
                  />
                  <CommonErrorLabel
                    value={
                      confirmLaunchValidationError?.autoAcceptPriceInPence ||
                      (confirmLaunchValidationError?.vehicleDescriptionAndSettingsForm &&
                        'Reserve Price must be greater or equal to 100')
                    }
                  />
                </div>
              )}

              {form?.values?.type?.value === AuctionStatusType.TRYING_TO_DEAL && (
                <div className="mt-3">
                  <FormSelectField
                    name="tryingToDealDeliveryDateType"
                    label="Due in date"
                    options={dueInDateTypes}
                    disabled={!item}
                  />
                </div>
              )}
            </Col>

            <Col md={6}>
              <div className="mt-3">
                <FormSelectField
                  name="timeOption"
                  label="Choose a time"
                  options={auctionTimes}
                  disabled={!item}
                />
              </div>

              {form?.values?.type?.value === AuctionStatusType.TRYING_TO_DEAL &&
                form?.values?.tryingToDealDeliveryDateType?.value ===
                  DueInDateType.CUSTOM_DATE && (
                  <div className="mt-3">
                    <FormDatePickerField
                      label="Delivery date"
                      name="tryingToDealDeliveryDate"
                      invalid={
                        !!form.errors.tryingToDealDeliveryDate ||
                        confirmLaunchValidationError?.tryingToDealDeliveryDate
                      }
                      readOnly
                      disabled={!item || isLoading}
                      isPastDateDisabled
                    />
                    <CommonErrorLabel
                      value={confirmLaunchValidationError?.tryingToDealDeliveryDate}
                    />
                  </div>
                )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12} className="pt-3">
              {organizationGroup && (
                <div className="mt-3">
                  <FormGroupRadioField
                    name="publishTarget"
                    radios={auctionPublishTypes}
                    classNames="auction-publish-radios"
                    disabled={!item}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </AccountLayoutContentWrapper>
    </>
  );
};

ConfirmLaunchForm.defaultProps = {
  item: null,
};

ConfirmLaunchForm.propTypes = {
  form: PropTypes.object.isRequired,
  prevStep: PropTypes.func.isRequired,
  item: PropTypes.object,
};

export default ConfirmLaunchForm;
