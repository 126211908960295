import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { validateFormField } from 'common/helpers/validateFormField';
import { useDispatch, useSelector } from 'react-redux';
import FormInputField from '../../common/components/formFields/FormInputField';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import {
  clearBasicDataSaved,
  handleCarDataAutofil,
  sendPaleteNumber,
} from '../AddVehicleActions';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import { AddVehicleStepsMapping } from '../../constants/AddVehicle';

const AddNumber = ({ values, nextForm }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  // const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [errors, setErrors] = useState({
    regPlateError: '',
  });

  const validateForm = () =>
    new Promise((resolve, reject) => {
      const { registrationPlate } = values?.basicData;
      const errors = {
        regPlateError: validateFormField(registrationPlate, null, true, 10),
      };
      setErrors(errors);
      const { regPlateError } = errors;
      if (regPlateError) {
        reject();
      }
      resolve();
    });

  // useEffect(() => {
  //   validateForm()
  //     .then(() => {
  //       setIsNextDisabled(false);
  //     })
  //     .catch(() => {
  //       setIsNextDisabled(true);
  //     });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values]);

  const { regPlateError } = errors;
  const { registrationPlate } = values?.basicData;

  const noAutoCompleteNextStep = () => {
    validateForm().then(() =>
      dispatch(
        sendPaleteNumber(registrationPlate, () => {
          dispatch(handleCarDataAutofil(false));
          nextForm(AddVehicleStepsMapping.VEHICLE_DETAILS);
        }),
      ),
    );
  };

  const moveToNextStep = () => {
    validateForm().then(() => dispatch(sendPaleteNumber(registrationPlate, () => nextForm())));
  };

  const handleEnter = e => {
    if (e.key !== 'Enter') return;
    e.preventDefault();
    moveToNextStep();
  };

  useEffect(() => {
    dispatch(clearBasicDataSaved());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AddVehicleHeader
        hideBackButton={true}
        nextButtonHandler={noAutoCompleteNextStep}
        nextButtonDisabled={!!isLoading}
        hideSaveDraftButton={true}
      />
      <AddVehicleContentWrapper>
        <Form className="text-left add-vehicle__add-number-form" onKeyDown={handleEnter}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <h5 className="font-weight-bold m-0">Registration Number</h5>
                  <p className="text-sm mt-2">
                    Please enter your vehicle registration to start a new listing.
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center text-left mt-3">
                <Col sm={12} md={12}>
                  <FormInputField
                    inputClassName="add-number-form__input-field"
                    buttonClassNames="add-number-form__search-button"
                    wrapperClassName="add-number-form__input-wrapper"
                    name="basicData.registrationPlate"
                    type="text"
                    label="Vehicle registration plate"
                    invalid={!!regPlateError}
                    upperCase
                    withButton={true}
                    buttonLabel="Search"
                    buttonHandleClick={moveToNextStep}
                    buttonDisabled={!!isLoading || !values?.basicData?.registrationPlate}
                    required
                  />
                  <CommonErrorLabel value={regPlateError} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </AddVehicleContentWrapper>
    </>
  );
};

AddNumber.propTypes = {
  values: PropTypes.object.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default AddNumber;
