import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import uuid from 'react-uuid';
import AddNumberForm from './AddNumberForm';
import ConfirmNumberForm from './ConfirmNumberForm';
import AddDescriptionPage from '../adddescription/AddDescriptionPage';
import ServiceHistoryPage from '../servicehistory/ServiceHistoryPage';
import AddFeaturesForm from './AddFeaturesForm';
import AddPhotoForm from './AddPhotoForm';
import AddPhotoSummaryPage from '../addphotosummary/AddPhotoSummaryPage';
import VehicleNotFound from './VehicleNotFound';
import { checkObligatoryPhotos } from '../helpers/checkObligatoryPhotos';
import { setFormStep } from '../AddVehicleActions';
import { AccountLayoutWrapper } from '../../common/components/AccountLayoutWrapper';
import { AddVehicleStepsName, AddVehicleNavMapping } from '../../constants/AddVehicle';
import AddBasicCarDataForm from './AddBasicCarDataForm';
import AddVideoForm from './AddVideoForm';
import TyreTreadDepths from './TyreTreadDepths';
import LaunchVehicleForm from './LaunchVehicle';

const AddVehicleForm = ({ form, values }) => {
  const dispatch = useDispatch();
  const vehicleFound = useSelector(state => state.addVehicle.get('vehicleFound'));
  const formStep = useSelector(state => state.addVehicle.get('formStep'));
  const [step, setStep] = useState(formStep || 1);
  const isAuctionEditing = useSelector(state => state.addVehicle.get('isAuctionEditing'));
  const photos = useSelector(state => state.addVehicle.get('photos'));

  const prevForm = () => {
    if (step === 5 && isAuctionEditing && checkObligatoryPhotos(photos)) {
      dispatch(setFormStep(3));
    } else {
      setStep(prevState => prevState - 1);
    }
  };

  const nextForm = forceStep => {
    if (forceStep) {
      setStep(forceStep);
      dispatch(setFormStep(forceStep));
      return;
    }

    if (step === 3 && isAuctionEditing && checkObligatoryPhotos(photos)) {
      dispatch(setFormStep(5));
    } else {
      setStep(prevState => prevState + 1);
      dispatch(setFormStep(step + 1));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (formStep) {
      setStep(formStep);
    }
  }, [formStep]);

  const navOptions = useCallback(() => {
    const navOrder = Object.keys(AddVehicleNavMapping);
    return navOrder.map(el => {
      const isCurrentStep =
        AddVehicleNavMapping[el] && AddVehicleNavMapping[el]?.includes(step);
      const isActive = Math.max(...AddVehicleNavMapping[el]) < step; // is previous step

      return (
        <div
          key={uuid()}
          className={`account-layout__nav-item add-vehicle__nav-item ${
            isCurrentStep ? 'active' : 'disabled'
          } ${isActive ? 'add-vehicle__nav-prev-item' : ''}`}
        >
          {AddVehicleStepsName[el]}
        </div>
      );
    });
  }, [step]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <AddNumberForm values={values} nextForm={nextForm} vehicleFound={vehicleFound} />
        );
      case 2:
        return vehicleFound ? (
          <ConfirmNumberForm values={values} nextForm={nextForm} prevForm={prevForm} />
        ) : (
          <VehicleNotFound values={values} nextForm={nextForm} prevForm={prevForm} />
        );
      case 3:
        return (
          <AddBasicCarDataForm
            values={values}
            nextForm={nextForm}
            prevForm={prevForm}
            form={form}
          />
        );
      case 4:
        return <AddPhotoForm values={values} prevForm={prevForm} nextForm={nextForm} />;
      case 5:
        return (
          <AddPhotoSummaryPage currentForm={step} prevForm={prevForm} nextForm={nextForm} />
        );
      case 6:
        return <AddVideoForm prevForm={prevForm} nextForm={nextForm} />;
      case 7:
        return (
          <TyreTreadDepths
            form={form}
            prevForm={prevForm}
            values={values}
            nextForm={nextForm}
          />
        );
      case 8:
        return <ServiceHistoryPage prevForm={prevForm} nextForm={nextForm} values={values} />;
      case 9:
        return (
          <AddFeaturesForm
            form={form}
            values={values}
            nextForm={nextForm}
            prevForm={prevForm}
          />
        );
      case 10:
        return (
          <AddDescriptionPage prevForm={prevForm} generalValues={values} nextForm={nextForm} />
        );
      case 11:
        return <LaunchVehicleForm prevForm={prevForm} />;
      default:
        return null;
    }
  };

  return <AccountLayoutWrapper navOptions={navOptions()}>{renderStep()}</AccountLayoutWrapper>;
};

AddVehicleForm.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default AddVehicleForm;
