import React from 'react';
import { Col, Row } from 'reactstrap';
import CommonDownloadIcons from '../../common/CommonDownloadIcons';

const MobileSection = () => {
  return (
    <section className="mobile-section section">
      <Row className="w-100">
        <Col sm={12} md={6} className="text-left d-flex align-items-center">
          <p className="font-weight-bold section-title text-left m-0">
            Download Our APP Today!
            <br /> Faster Vehicle Uploads, Faster Bids and Instant Alerts.
          </p>
        </Col>
        <Col sm={12} md={6} className="d-flex justify-content-center align-items-center">
          <CommonDownloadIcons showQrCode />
        </Col>
      </Row>
    </section>
  );
};

export default MobileSection;
