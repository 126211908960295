import React from 'react';
import * as PropTypes from 'prop-types';

const CommonDeleteIconButton = ({ handleClick, className, buttonProps, icon }) => {
  return (
    <div className={`common-icon-button__wrapper ${className}`}>
      <button
        type="button"
        {...buttonProps}
        className="d-flex justify-content-center align-items-center w-100"
        onClick={handleClick}
      >
        <img src={icon} alt="alt" />
      </button>
    </div>
  );
};

CommonDeleteIconButton.defaultProps = {
  handleClick: null,
  className: '',
  buttonProps: {},
};

CommonDeleteIconButton.propTypes = {
  handleClick: PropTypes.func,
  className: PropTypes.string,
  buttonProps: PropTypes.object,
  icon: PropTypes.object.isRequired,
};

export default CommonDeleteIconButton;
