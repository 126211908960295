import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AccountLayoutNavItem, AccountLayoutWrapper } from './AccountLayoutWrapper';
import { MyOrganizationSubpageButtonLabels } from '../../constants/MyOrganizationSubpageButtonLabels';
import RouterPaths from '../../constants/RouterPaths';
import { MyProfileSubpageButtonLabels } from '../../constants/MyProfileSubpageButtonLabels';

const CommonMyOrganizationContainer = ({ children }) => {
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const MyOrganizationNavItems = [
    {
      label: MyOrganizationSubpageButtonLabels.INFORMATIONS,
      path: RouterPaths.SETTINGS,
      hasAccess: true,
    },
    {
      label: MyOrganizationSubpageButtonLabels.EMPLOYEES,
      path: RouterPaths.EMPLOYEES,
      hasAccess: isOwner,
    },
    {
      label: MyOrganizationSubpageButtonLabels.MY_WALLET,
      path: RouterPaths.MY_WALLET,
      hasAccess: isOwner,
    },
    {
      label: MyOrganizationSubpageButtonLabels.TRANSACTIONS,
      path: RouterPaths.TRANSACTIONS,
      hasAccess: isOwner,
    },
    {
      label: MyOrganizationSubpageButtonLabels.INVOICES,
      path: RouterPaths.INVOICES,
      hasAccess: isOwner,
    },
    {
      label: MyProfileSubpageButtonLabels.MY_PROFILE,
      path: RouterPaths.MY_PROFILE,
    },
    {
      label: MyProfileSubpageButtonLabels.SEARCH_ALERTS,
      path: RouterPaths.SEARCH_ALERTS,
    },
    {
      label: MyProfileSubpageButtonLabels.CREATE_SEARCH_ALERTS,
      path: RouterPaths.CREATE_SEARCH_ALERT,
    },
    {
      label: MyProfileSubpageButtonLabels.EDIT_SEARCH_ALERTS,
      path: RouterPaths.EDIT_SEARCH_ALERT,
    },
    {
      label: MyProfileSubpageButtonLabels.NOTIFICATIONS,
      path: RouterPaths.NOTIFICATIONS,
    },
    {
      label: MyProfileSubpageButtonLabels.MY_PROFILE,
      path: RouterPaths.MY_PROFILE,
      hasAccess: true,
    },
    {
      label: MyProfileSubpageButtonLabels.SEARCH_ALERTS,
      path: RouterPaths.SEARCH_ALERTS,
      hasAccess: true,
    },
    {
      label: MyProfileSubpageButtonLabels.NOTIFICATIONS,
      path: RouterPaths.NOTIFICATIONS,
      hasAccess: true,
    },
  ];

  const getNavOptions = () => {
    return MyOrganizationNavItems.filter(el => el?.hasAccess).map(el => (
      <AccountLayoutNavItem link={el.path || '#'}>{el.label}</AccountLayoutNavItem>
    ));
  };

  return <AccountLayoutWrapper navOptions={getNavOptions()}>{children}</AccountLayoutWrapper>;
};

CommonMyOrganizationContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
};

export default CommonMyOrganizationContainer;
