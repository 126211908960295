import React, { useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { BidStatus } from 'itrade-admin-panel/src/constants/BidStatus';
import { pencesToPounds } from '../../helpers/pencesToPounds';
import AuctionTimeLabel from '../../../myvehicles/AuctionTimeLabel';
import { auctionListType } from '../../../constants/AuctionListType';
import AuctionStatusBox from '../../../myvehicles/AuctionStatusBox';
import CommonButton from '../CommonButton';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import { AuctionStatus, AuctionStatusName } from '../../../constants/AuctionStatus';
import SearchBidModal from '../../../search/bidModals/SearchBidModal';
import { AUCTION_BID_MAX_BIDS_LIMIT } from '../../../constants/AuctionBid';
import placeBidIcon from '../../../assets/img/place-bid-icon.png';
import { AuctionStatusType } from '../../../constants/AuctionStatusType';
import { placeBid } from '../../../search/SearchActions';
import BoxStatusVariants from '../../../constants/BoxStatusVariants';

export const SlotItems = {
  AUCTION_END: 'AUCTION_END',
  TIME_LEFT: 'TIME_LEFT',
  CURRENT_BID: 'CURRENT_BID',
  AUCTION_STATUS_BOX: 'AUCTION_STATUS_BOX',
};

export const DefaultPattern = [
  SlotItems.AUCTION_END,
  SlotItems.TIME_LEFT,
  SlotItems.CURRENT_BID,
  SlotItems.AUCTION_STATUS_BOX,
];

const AuctionMainDescriptionContainer = ({
  item,
  windowFocus,
  organizationId,
  listType,
  pattern,
  patternSizes,
  isDetailsPage,
  bidBarMessage,
  bidBarColor,
  className,
  auctionTimestamp,
  highestBid,
  proxyBid,
  shouldPriceBeGreen,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const isBuyer = useSelector(state => state.auth.get('isBuyer'));
  const memberId = useSelector(state => state.auth.get('id'));
  const isBidAllowed =
    isBuyer &&
    item?.bids?.length < AUCTION_BID_MAX_BIDS_LIMIT &&
    item?.organizationId !== organizationId &&
    moment(item.endsOn).isAfter();

  const getHighestBid = useCallback(() => {
    if (highestBid !== null && highestBid !== undefined) {
      return highestBid;
    }

    return (
      pencesToPounds(item?.highestBidInPence) || pencesToPounds(item?.minimumPriceInPence) || 0
    );
  }, [highestBid, item.highestBidInPence, item.minimumPriceInPence]);

  const highestBidObj = item?.bids?.length && item.bids?.filter(bid => bid.isHighest)?.[0];
  const isHighestBid = highestBidObj?.bidderOrganizationId === organizationId;

  const canShowPlaceBidButton = () => {
    return (
      (listType === auctionListType.MY_BIDS || listType === auctionListType.SEARCH) &&
      item?.organizationId !== organizationId &&
      item?.seller?.organizationContact?.id !== organizationId &&
      item?.status === AuctionStatus.PUBLISHED
    );
  };

  const [showBidModal, setShowBidModal] = useState(false);
  const [currentBidAuction, setCurrentBidAuction] = useState(null);

  const handleBidModal = item => {
    setCurrentBidAuction(item);
    setShowBidModal(true);
  };

  const toggleBidModal = () => {
    setShowBidModal(false);
  };

  const handlePlaceBid = amount => {
    dispatch(placeBid(currentBidAuction?.id, amount * 100));
  };

  const getProxyBid = useCallback(() => {
    if (proxyBid !== null && proxyBid !== undefined) {
      return proxyBid;
    }

    return pencesToPounds(item.proxyBidInPence);
  }, [proxyBid, item.proxyBidInPence]);

  const renderCurrentBid = useCallback(() => {
    const value = getProxyBid() || getHighestBid();
    const priceGreenCondition =
      shouldPriceBeGreen !== null ? shouldPriceBeGreen : item?.shouldPriceBeGreen;

    const isReservePrice = () => {
      return (
        !!item.type &&
        item.type === AuctionStatusType.PHYSICAL &&
        !!item.autoAcceptPriceInPence
      );
    };

    let bidLabel = (
      <p className="m-0 main-description-container__item-title text-xs">Current Bid</p>
    );

    if (
      item.type === AuctionStatusType.PHYSICAL ||
      item.type === AuctionStatusType.MINIMUM_PRICE
    ) {
      bidLabel = priceGreenCondition ? (
        <p className="m-0 main-description-container__item-title text-xs bid-price-label__green">
          Reserve met
        </p>
      ) : (
        <p className="m-0 main-description-container__item-title bid-label-danger-color text-xs">
          Reserve not met
        </p>
      );
    }

    return (
      <div>
        <p
          className={`bid-price-label ${
            priceGreenCondition ? 'bid-price-label__green' : ''
          } m-0`}
        >
          <CurrencyFormat
            value={value || 100}
            thousandSeparator={true}
            allowNegative={false}
            prefix="£"
            displayType="text"
          />
        </p>
        {bidLabel}
        {isDetailsPage && isReservePrice() && (
          <div className="text-xs">
            <span className="main-description-container__item-title">{`Reserve Price: `}</span>
            <span className="summary__auction-price">
              {!!item && (
                <CurrencyFormat
                  value={pencesToPounds(item.autoAcceptPriceInPence)}
                  thousandSeparator={true}
                  allowNegative={false}
                  prefix="£"
                  displayType="text"
                />
              )}
            </span>
          </div>
        )}
      </div>
    );
  }, [getHighestBid, isDetailsPage, item, getProxyBid]);

  const renderAuctionEnd = () => {
    const getTitle = () => {
      if (
        item?.status === AuctionStatus.SOLD ||
        item?.status === AuctionStatus.ENDED ||
        item?.status === AuctionStatus.CANCELLED
      ) {
        return 'Auction Ended';
      }

      return 'Auction Ends';
    };

    return (
      <div>
        <p className="font-weight-bold text-base auction-end m-0">
          {item?.endsOn ? moment(item?.endsOn).format('DD/MM/YY  HH:mmA') : 'N/A'}
        </p>
        <p className="m-0 main-description-container__item-title text-xs">{getTitle()}</p>
      </div>
    );
  };

  const renderTimeLeft = () => {
    return (
      <div>
        <AuctionTimeLabel
          auctionTimestamp={auctionTimestamp || item?.timeStamp}
          item={item}
          listType={auctionListType.MY_VEHICLES}
          organizationId={organizationId}
          windowFocus={windowFocus}
        />
        <p className="m-0 main-description-container__item-title text-xs">Time Left</p>
      </div>
    );
  };

  const renderBoxVariant = () => {
    if (isFinishedAuction) {
      return BoxStatusVariants.SECONDARY;
    } else if (isLostBidder) {
      return BoxStatusVariants.DANGER;
    } else if (item?.status === AuctionStatus.TO_ACCEPT || item?.status === AuctionStatus.WAITING) {
      return (isHighestBid && item?.isBuyerAcceptanceAllowed) ||
      (item?.seller?.id === memberId && item?.isSellerAcceptanceAllowed) ?
          BoxStatusVariants.SUCCESS :
          BoxStatusVariants.WARNING;
    }
    return null;
  }

  const renderStatusBoxContent = () => {
    if (isFinishedAuction) {
      return AuctionStatusName.ENDED;
    } else if (isLostBidder) {
      return BidStatus.LOST
    } else if (
      (listType === auctionListType.MY_BIDS || listType === auctionListType.SEARCH) &&
      item?.status === AuctionStatus.SOLD &&
      isHighestBid
    ) {
      return 'Won';
    } else if (isHighestBid && (item?.status === AuctionStatus.TO_ACCEPT ||
        item?.status === AuctionStatus.WAITING)) {
      return item?.isBuyerAcceptanceAllowed ? AuctionStatusName.TO_ACCEPT : AuctionStatusName.WAITING;
    }
    return undefined;
  };

  const isLostBidder =
    (item?.type === AuctionStatusType.TRYING_TO_DEAL || item?.status === AuctionStatus.SOLD) &&
    item?.status !== AuctionStatus.PUBLISHED &&
    item?.seller?.id !== memberId &&
    item?.bids.length > 0 &&
    !item?.bids?.filter(it => it.isHighest).length;

  const isFinishedAuction =
    item?.type === AuctionStatusType.TRYING_TO_DEAL &&
    item?.status !== AuctionStatus.PUBLISHED &&
    item?.status !== AuctionStatus.SOLD &&
    item?.seller?.id !== memberId &&
    item?.bids.length === 0;

  const renderStatusBox = () => {
    return (
      <>
        {canShowPlaceBidButton() ? (
          <CommonButton
            disabled={isLoading || item?.status !== AuctionStatus.PUBLISHED || !isBidAllowed}
            type="submit"
            label="PLACE BID"
            className="auction-place-bid-box__send-button text-sm"
            variant={CommonButtonVariants.SUCCESS}
            handleClick={e => {
              e.preventDefault();
              handleBidModal(item);
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
            icon={placeBidIcon}
            iconAlt="Place Bid"
            iconLeft={true}
          />
        ) : (
          <AuctionStatusBox
            auction={item}
            content={renderStatusBoxContent()}
            customBoxVariant={renderBoxVariant()}
          />
        )}
      </>
    );
  };

  const renderPattern = () => {
    const finalPattern = pattern || DefaultPattern;
    const getColSize = index => {
      if (patternSizes?.length === finalPattern?.length) {
        return patternSizes[index];
      }

      return 4;
    };

    return finalPattern.map((el, index) => {
      let content;
      switch (el) {
        case SlotItems.CURRENT_BID:
          content = renderCurrentBid();
          break;
        case SlotItems.TIME_LEFT:
          content = renderTimeLeft();
          break;
        case SlotItems.AUCTION_END:
          content = renderAuctionEnd();
          break;
        case SlotItems.AUCTION_STATUS_BOX:
          content = renderStatusBox();
          break;
        default:
          content = el; // custom content
      }

      return (
        <>
          <Col
            sm={12}
            md={getColSize(index)}
            lg={getColSize(index)}
            className="d-flex justify-content-start align-items-center p-0 main-description-pattern-item"
          >
            {content}
          </Col>
        </>
      );
    });
  };

  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center flex-grow-1 ${
        isDetailsPage
          ? 'main-description-container__info-bar'
          : 'main-description-container__basic-data'
      } ${className}`}
    >
      <Row
        sm={12}
        md={3}
        lg={3}
        className="d-flex justify-content-md-start flex-grow-1 w-100 main-description-pattern-wrapper"
      >
        {renderPattern()}
      </Row>
      {bidBarMessage && bidBarColor && (
        <span
          className={`d-flex justify-content-center align-items-center status-box box-${bidBarColor} p-1 font-weight-bold text-sm w-100 mt-4`}
        >
          {bidBarMessage}
        </span>
      )}
      {showBidModal && (
        <SearchBidModal
          toggleShow={toggleBidModal}
          item={currentBidAuction}
          handlePlaceBid={handlePlaceBid}
        />
      )}
    </div>
  );
};

AuctionMainDescriptionContainer.defaultProps = {
  organizationId: '',
  windowFocus: false,
  listType: '',
  pattern: [],
  patternSizes: [],
  isDetailsPage: false,
  bidBarMessage: '',
  bidBarColor: '',
  className: '',
  auctionTimestamp: null,
  highestBid: null,
  proxyBid: null,
  shouldPriceBeGreen: null,
};

AuctionMainDescriptionContainer.propTypes = {
  item: PropTypes.object.isRequired,
  organizationId: PropTypes.string,
  windowFocus: PropTypes.bool,
  listType: PropTypes.string,
  pattern: PropTypes.array,
  patternSizes: PropTypes.array,
  isDetailsPage: PropTypes.bool,
  bidBarMessage: PropTypes.string,
  bidBarColor: PropTypes.string,
  className: PropTypes.string,
  auctionTimestamp: PropTypes.object,
  highestBid: PropTypes.number,
  proxyBid: PropTypes.number,
  shouldPriceBeGreen: PropTypes.bool,
};

export default AuctionMainDescriptionContainer;
