import React from 'react';
import * as PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { CommonButtonSize, CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { AuctionStatus } from '../../constants/AuctionStatus';

const CommonButton = ({
  label,
  variant,
  className,
  handleClick,
  type,
  disabled,
  size,
  navLink,
  path,
  icon,
  iconAlt,
  iconLeft,
  color,
  buttonProperties,
}) => {
  const setVariantClassName = () => {
    switch (variant) {
      case CommonButtonVariants.PRIMARY:
        return 'primary-button';
      case CommonButtonVariants.SECONDARY:
        return 'secondary-button';
      case CommonButtonVariants.DISABLED:
        return 'disabled-button';
      case CommonButtonVariants.ORGANIZATION_NAV:
        return 'my-organization-nav-link';
      case CommonButtonVariants.AUCTION:
        return 'tertiary-button';
      case CommonButtonVariants.SUCCESS:
        return 'success-button';
      case CommonButtonVariants.DANGER:
        return 'btn-danger';
      case CommonButtonVariants.GREY:
        return 'btn-grey';
      case CommonButtonVariants.PUBLISH:
        return 'publish-auction-button';
      case CommonButtonVariants.CAR_FEATURE:
        return 'car-feature-button';
      case CommonButtonVariants.CAR_FEATURE_SELECTED:
        return 'car-feature-button--selected';
      case CommonButtonVariants.DELETE:
        return 'delete-button';
      case CommonButtonVariants.OUTLINED:
        return 'outline-primary';
      default:
        return 'primary-button';
    }
  };

  const setSizeClassName = () => {
    switch (size) {
      case CommonButtonSize.LARGE:
        return 'common-button--large';
      case CommonButtonSize.SMALL:
        return 'common-button--small';
      case CommonButtonSize.MEDIUM:
        return 'common-button--medium';
      default:
        return null;
    }
  };

  const setColorClassName = () => {
    switch (color) {
      case AuctionStatus.WAITING:
        return 'common-button--waiting';
      case AuctionStatus.PUBLISHED:
        return 'common-button--published';
      case AuctionStatus.REJECTED:
        return 'common-button--rejected';
      case AuctionStatus.TO_ACCEPT:
        return 'common-button--to-accept';
      case AuctionStatus.SOLD:
        return 'common-button--sold';
      case AuctionStatus.CANCELLED:
        return 'common-button--canceled';
      case AuctionStatus.ENDED:
        return 'common-button--ended';
      default:
        return null;
    }
  };

  const checkActive = (match, location) => {
    if (!location) return false;
    const { pathname } = location;
    return pathname === path;
  };

  return (
    <>
      {navLink ? (
        <NavLink
          to={path}
          className={`common-nav-link ${setVariantClassName()} ${setSizeClassName()} ${className}`}
          activeClassName="common-nav-link--active"
          isActive={checkActive}
        >
          {label}
        </NavLink>
      ) : (
        <button
          /* eslint-disable-next-line react/button-has-type */
          type={type}
          className={`common-button ${setVariantClassName()} ${setSizeClassName()} ${setColorClassName()} ${className}`}
          onClick={handleClick}
          disabled={disabled}
          {...buttonProperties}
        >
          <div className={`common-button__content ${iconLeft ? 'flex-row-reverse' : ''}`}>
            <span>{label}</span>
            {icon && iconAlt && (
              <img
                className="common-button__icon"
                src={icon}
                alt={iconAlt}
                style={{
                  filter: disabled ? 'grayscale(1)' : 'grayscale(0)',
                  opacity: disabled ? '0.5' : '1',
                }}
              />
            )}
          </div>
        </button>
      )}
    </>
  );
};

CommonButton.defaultProps = {
  variant: CommonButtonVariants.PRIMARY,
  className: '',
  type: 'button',
  disabled: false,
  size: CommonButtonSize.DEFAULT,
  navLink: false,
  path: '',
  handleClick: null,
  icon: '',
  iconAlt: '',
  color: '',
  iconLeft: false,
  buttonProperties: {},
};

CommonButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  variant: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  navLink: PropTypes.bool,
  path: PropTypes.string,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  color: PropTypes.string,
  iconLeft: PropTypes.bool,
  buttonProperties: PropTypes.object,
};

export default CommonButton;
