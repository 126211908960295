import { fromJS } from 'immutable';

const initialData = fromJS({
  items: [],
  myLatestAuctions: [],
  myLatestAuctionsTimeStamp: null,
  myLatestBids: [],
  myLatestBidsTimeStamp: null,
  latestAuctionsTimeStamp: null,
  vehicleMakeVisible: false,
  aggregation: [],
  recentlySold: [],
  hotAuction: null,
});

export const LATEST_AUCTIONS_SUCCESS = 'LATEST_AUCTIONS_SUCCESS';
export const AGGREGATED_BY_MAKE_SUCCESS = 'AGGREGATED_BY_MAKE_SUCCESS';
export const MY_LATEST_AUCTIONS_SUCCESS = 'MY_LATEST_AUCTIONS_SUCCESS';
export const MY_LATEST_BIDS_SUCCESS = 'MY_LATEST_BIDS_SUCCESS';
export const MY_BIDS_REFRESH_SUCCESS = 'MY_BIDS_REFRESH_SUCCESS';
export const LATEST_AUCTIONS_REFRESH_SUCCESS = 'LATEST_AUCTIONS_REFRESH_SUCCESS';
export const RECENTLY_SOLD_SUCCESS = 'RECENTLY_SOLD_SUCCESS';
export const HOT_AUCTION_SUCCESS = 'HOT_AUCTION_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case HOT_AUCTION_SUCCESS:
      return state.merge({
        hotAuction: payload.data || null,
      });
    case RECENTLY_SOLD_SUCCESS:
      return state.merge({
        recentlySold: payload.items || null,
      });
    case LATEST_AUCTIONS_SUCCESS:
      return state.merge({
        items: payload.offset
          ? state.get('items').merge(fromJS(payload.items))
          : fromJS(payload.items),
        latestAuctionsTimeStamp: payload.timeStamp,
      });
    case AGGREGATED_BY_MAKE_SUCCESS:
      return state.merge({
        vehicleMakeVisible: payload.vehicleMakeVisible,
        aggregation: payload.aggregation,
      });
    case MY_LATEST_AUCTIONS_SUCCESS:
      return state.merge({
        myLatestAuctions: payload.offset
          ? state.get('myLatestAuctions').merge(fromJS(payload.myLatestAuctions))
          : fromJS(payload.myLatestAuctions),
        myLatestAuctionsTimeStamp: payload.timeStamp,
      });
    case MY_LATEST_BIDS_SUCCESS:
      return state.merge({
        myLatestBids: payload.offset
          ? state.get('myLatestBids').merge(fromJS(payload.myLatestBids))
          : fromJS(payload.myLatestBids),
        myLatestBidsTimeStamp: payload.timeStamp,
      });
    case MY_BIDS_REFRESH_SUCCESS: {
      const myLastestBidsIndex = state
        .get('myLatestBids')
        .toJS()
        .findIndex(item => item.id === payload.item.id);
      if (myLastestBidsIndex !== -1) {
        const itemData = payload.item;
        itemData.timeStamp = new Date();
        itemData.organizationId = itemData.organizationId
          ? itemData.organizationId
          : itemData?.seller?.organizationContact?.id;
        return state.merge({
          myLatestBids: state.get('myLatestBids').update(myLastestBidsIndex, () => itemData),
        });
      }
      return state;
    }
    case LATEST_AUCTIONS_REFRESH_SUCCESS: {
      const myLastestAuctionsIndex = state
        .get('myLatestAuctions')
        .toJS()
        .findIndex(item => item.id === payload.item.id);
      if (myLastestAuctionsIndex !== -1) {
        const itemData = payload.item;
        itemData.timeStamp = new Date();
        itemData.organizationId = itemData.organizationId
          ? itemData.organizationId
          : itemData?.seller?.organizationContact?.id;
        return state.merge({
          myLatestAuctions: state
            .get('myLatestAuctions')
            .update(myLastestAuctionsIndex, () => itemData),
        });
      }
      return state;
    }
    default:
      return state;
  }
};
