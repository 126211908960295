import React, { useState } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import EmployeesTableRow from './EmployeesTableRow';
import CommonTable from '../../../common/components/CommonTable';
import CommonSweetAlert from '../../../common/components/CommonSweetAlert';
import { CommonSweetAlertVariants } from '../../../constants/CommonSweetAlertVariants';

const EmployeesTable = ({ data, offset, changeOffset, removeEmployeeHandler }) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [activeEmployee, setActiveEmployee] = useState(null);

  const handleDeleteEmployee = item => {
    setActiveEmployee({
      id: item?.id,
      firstName: item?.firstName || '',
      lastName: item?.lastName || '',
    });
    setShowDeleteAlert(true);
  };

  return (
    <Col xs="12">
      <CommonTable
        className="employee-common-table"
        headerOptions={['First Name', 'Last Name', 'Position', 'Status', 'Email', 'Actions']}
        lastIsActionColumn={true}
        rowComponent={EmployeesTableRow}
        rowComponentProps={{ handleDeleteEmployee }}
        data={data}
        offset={offset}
        onOffsetChange={changeOffset}
        title="List of my employees"
      />
      <CommonSweetAlert
        show={showDeleteAlert}
        variant={CommonSweetAlertVariants.ERROR}
        onConfirm={() => {
          if (activeEmployee) {
            removeEmployeeHandler(activeEmployee.id);
          }

          setActiveEmployee(null);
          setShowDeleteAlert(false);
        }}
        onCancel={() => {
          setActiveEmployee(null);
          setShowDeleteAlert(false);
        }}
        confirmButtonText="REMOVE"
      >
        <p className="common-sweet-alert__title">
          Please confirm that you want to remove this employee?
        </p>
        {activeEmployee ? (
          <p className="common-sweet-alert__text">
            {activeEmployee.firstName} {activeEmployee.lastName}
          </p>
        ) : (
          ''
        )}
      </CommonSweetAlert>
    </Col>
  );
};

EmployeesTable.propTypes = {
  changeOffset: PropTypes.func.isRequired,
  removeEmployeeHandler: PropTypes.func.isRequired,
};

export default EmployeesTable;
