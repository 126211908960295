import React from 'react';
import * as PropTypes from 'prop-types';
import penIcon from '../../assets/img/icons/pen.svg';

const CommonEditIconButton = ({ handleClick, className, dataStep, dataIndex }) => {
  return (
    <div className={`common-edit-button__icon-wrapper ${className}`}>
      <button
        type="button"
        data-step={dataStep}
        data-index={dataIndex}
        className="common-delete w-100"
        onClick={handleClick}
      >
        <img src={penIcon} alt="alt" width="20" height="20" />
      </button>
    </div>
  );
};

CommonEditIconButton.defaultProps = {
  handleClick: null,
  className: '',
  dataStep: null,
  dataIndex: null,
};

CommonEditIconButton.propTypes = {
  handleClick: PropTypes.func,
  className: PropTypes.string,
  dataStep: PropTypes.number,
  dataIndex: PropTypes.number,
};

export default CommonEditIconButton;
