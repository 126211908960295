import React from 'react';
import PropTypes from 'prop-types';
import HotAuction from '../../common/components/HotAuction/HotAuction';

const AuctionSection = ({ auctionData }) => {
  return (
    <section className="auction-section section">
      <HotAuction auctionData={auctionData} />
    </section>
  );
};

AuctionSection.propTypes = {
  auctionData: PropTypes.object.isRequired,
};

export default AuctionSection;
