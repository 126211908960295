import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import CurrencyFormat from 'react-currency-format';
import { CommonString } from 'constants/CommonString';
import { AuctionStatus, AuctionStatusName } from '../../constants/AuctionStatus';
import CommonButton from './CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import {
  acceptBySeller,
  cancelAuction,
  deleteAuction,
  rejectBySeller,
} from '../../myvehicles/MyVehiclesActions';
import { clearPhotosInServiceHistory, editAuction } from '../../addvehicle/AddVehicleActions';
import { preparePriceFormat } from '../helpers/preparePriceFormat';
import { AuctionPublishTypeName } from '../../constants/AuctionPublishType';
import { StaticValues } from '../../constants/StaticValues';

const {
  REJECTED: OFFER_DECLINED_STATUS_NAME,
  CANCELLED: CANCELLED_STATUS_NAME,
  ENDED: ENDED_STATUS_NAME,
} = AuctionStatusName;
const { CANCELLED: CANCELLED_STATUS, ENDED: ENDED_STATUS } = AuctionStatus;

const AuctionDetailsSellerSection = ({
  item,
  refreshItem,
  bidNotification,
  status,
  changeStatus,
  isAuctionRelaunched,
  setIsAuctionRelaunched,
  nextStep,
  setIsRepublish,
}) => {
  const dispatch = useDispatch();
  const { id: auctionId } = useParams();
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const id = useSelector(state => state.auth.get('id'));
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const isSseRefresh = useSelector(state => state.addVehicle.get('isSseRefresh'));
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [highestBidAmount, setHighestBidAmount] = useState();

  useEffect(() => {
    if (status) {
      changeStatus(false);
      refreshItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  useEffect(() => {
    let highestBid = null;
    if (bidNotification) {
      setHighestBidAmount(bidNotification);
    } else if (item && item.bids.length) {
      highestBid =
        item?.proxyBidInPence ?? item?.bids?.find(bid => bid.isHighest)?.amountInPence;
      setHighestBidAmount(preparePriceFormat(highestBid, true));
    } else if (isAuctionRelaunched) {
      setHighestBidAmount('');
    } else {
      setHighestBidAmount(bidNotification);
    }
  }, [item, bidNotification, isAuctionRelaunched]);

  const handlePublishAuction = () => {
    nextStep(prevStep => prevStep + 1);
    setIsRepublish(false);
  };

  const handleCancelAuction = () => {
    dispatch(
      cancelAuction(item, data => {
        refreshItem(data);
      }),
    );
  };

  const handleAcceptAuction = () => {
    dispatch(
      acceptBySeller(item, data => {
        refreshItem(data);
      }),
    );
  };

  const handleRelaunchAuction = () => {
    setIsAuctionRelaunched(true);
    nextStep(prevStep => prevStep + 1);
    setIsRepublish(true);
  };

  const handleRejectAuction = () => {
    dispatch(
      rejectBySeller(item, data => {
        refreshItem(data);
      }),
    );
  };

  const displayBidAmountOrDash = bid => {
    return bid ? (
      <CurrencyFormat
        value={bid}
        thousandSeparator={true}
        allowNegative={false}
        prefix="£"
        displayType="text"
      />
    ) : (
      <div>-</div>
    );
  };

  return (
    <>
      {(() => {
        switch (item.status) {
          case AuctionStatus.DRAFT: {
            const isLaunchEnabled = (isOwner || item?.seller?.id === id) && item?.isDraftValid;
            return (
              <Row
                xs="12"
                className="summary__main-description-container auction-detail-seller auction-detail-seller-draft"
              >
                <Col xs="12" lg="6" className="text-center">
                  {AuctionPublishTypeName[item?.publishTarget] ? (
                    <>
                      <p>{AuctionPublishTypeName[item?.publishTarget]}</p>
                      <strong>The best bid:</strong>
                      <div className="auction-detail-bid">-</div>{' '}
                    </>
                  ) : (
                    <p>
                      <strong>{CommonString.TARGET_NOT_SPECIFIED}</strong>
                    </p>
                  )}

                  <CommonButton label="Parked" variant={CommonButtonVariants.AUCTION} />
                </Col>
                <Col
                  xs="12"
                  lg="6"
                  className="auction-seller-section__draft-buttons-outer-col"
                >
                  <Col
                    xs={12}
                    sm={`${isLaunchEnabled ? 6 : 12}`}
                    className={`${
                      isLaunchEnabled
                        ? 'auction-seller-section__draft-buttons-wrapper--with-launch'
                        : 'auction-seller-section__draft-buttons-wrapper'
                    } `}
                  >
                    {(isOwner || item?.seller?.id === id) && (
                      <>
                        <CommonButton
                          className={`basic-data__button mt-0 ${
                            isLaunchEnabled ? 'order-1 mt-3' : 'order-0 mb-3 mb-sm-0'
                          }`}
                          label="Delete"
                          variant={CommonButtonVariants.DELETE}
                          handleClick={() => setConfirmDelete(true)}
                        />
                        <SweetAlert
                          danger
                          showCancel
                          show={confirmDelete}
                          onConfirm={() => {
                            dispatch(
                              deleteAuction({
                                id: auctionId,
                                version: item?.version,
                              }),
                            );
                            setConfirmDelete(false);
                          }}
                          onCancel={() => setConfirmDelete(false)}
                          confirmBtnBsStyle="info"
                          cancelBtnCssClass="cancel-button"
                          cancelBtnBsStyle="default"
                          title=""
                        >
                          <span className="sweet-alert-text">
                            Are you sure you want to delete this vehicle?
                          </span>
                        </SweetAlert>
                      </>
                    )}
                    {(isOwner || item?.seller?.id === id) && (
                      <CommonButton
                        className="basic-data__button btn-primary mt-0"
                        label="Edit"
                        variant={CommonButtonVariants.PRIMARY}
                        handleClick={() => {
                          dispatch(
                            editAuction({
                              auctionId,
                              version: item?.version,
                              status: item?.status,
                            }),
                          );
                          dispatch(clearPhotosInServiceHistory());
                        }}
                      />
                    )}
                  </Col>
                  <Col xs={12} sm={6} className="auction-details__launch-button">
                    {isLaunchEnabled && (
                      <CommonButton
                        className={`basic-data__button mt-0 ${isLaunchEnabled && 'mb-3'}`}
                        label="Launch"
                        variant={CommonButtonVariants.SUCCESS}
                        handleClick={handlePublishAuction}
                      />
                    )}
                  </Col>
                </Col>
              </Row>
            );
          }
          case AuctionStatus.PUBLISHED:
            return (
              <Row
                xs={12}
                className="summary__main-description-container auction-detail-seller auction-detail-seller-published"
              >
                <Col xs={12} md={6} className="text-center">
                  <strong>The best bid:</strong>
                  <div className="auction-detail-bid">
                    {displayBidAmountOrDash(highestBidAmount)}
                  </div>
                  <CommonButton
                    label="Live"
                    variant={CommonButtonVariants.AUCTION}
                    color={AuctionStatus.PUBLISHED}
                  />
                </Col>
                {item?.isCancellationAllowed && (
                  <Col
                    xs={12}
                    md={6}
                    className="auction-details__buttons-section-wrapper--single-button"
                  >
                    <CommonButton
                      className="basic-data__button mt-md-0"
                      label="Cancel"
                      variant={CommonButtonVariants.PRIMARY}
                      handleClick={() => setConfirmCancel(true)}
                    />
                    <SweetAlert
                      danger
                      showCancel
                      show={confirmCancel}
                      onConfirm={() => {
                        handleCancelAuction();
                        setConfirmCancel(false);
                      }}
                      onCancel={() => setConfirmCancel(false)}
                      confirmBtnBsStyle="info"
                      cancelBtnCssClass="cancel-button"
                      cancelBtnBsStyle="default"
                      title=""
                    >
                      <span className="sweet-alert-text">Are you sure?</span>
                    </SweetAlert>
                  </Col>
                )}
              </Row>
            );
          case AuctionStatus.TO_ACCEPT:
            return (
              <Row className="summary__main-description-container auction-detail-seller auction-detail-seller-to-accept">
                <Col xs={12} lg={6} className="text-center">
                  <strong>The best bid:</strong>
                  <div className="auction-detail-bid">
                    {displayBidAmountOrDash(highestBidAmount)}
                  </div>

                  <CommonButton
                    label=" Waiting for you to accept"
                    variant={CommonButtonVariants.AUCTION}
                    color={AuctionStatus.TO_ACCEPT}
                  />
                </Col>
                <Col xs={12} lg={6} className="auction-details__buttons-section-wrapper">
                  <CommonButton
                    className="basic-data__button reject-button"
                    label="Reject"
                    color={AuctionStatus.CANCELLED}
                    handleClick={handleRejectAuction}
                    disabled={!item?.isSellerAcceptanceAllowed}
                  />
                  <CommonButton
                    className="basic-data__button"
                    label="Accept"
                    variant={CommonButtonVariants.PRIMARY}
                    handleClick={handleAcceptAuction}
                    disabled={!item?.isSellerAcceptanceAllowed}
                  />
                </Col>
              </Row>
            );
          case AuctionStatus.WAITING:
            return (
              <Row className="summary__main-description-container auction-detail-seller auction-detail-seller-to-accept">
                <Col className="text-center">
                  <strong>The best bid:</strong>
                  <div className="auction-detail-bid">
                    {displayBidAmountOrDash(highestBidAmount)}
                  </div>
                  <CommonButton
                    label=" Waiting for the buyer's acceptance"
                    variant={CommonButtonVariants.AUCTION}
                    color={AuctionStatus.WAITING}
                  />
                </Col>
              </Row>
            );
          case AuctionStatus.CANCELLED:
          case AuctionStatus.ENDED:
            return (
              <Row
                xs={12}
                className="summary__main-description-container auction-detail-seller auction-detail-seller-cancelled"
              >
                <Col xs={12} lg={6} className="text-center">
                  <strong>The best bid:</strong>
                  <div className="auction-detail-bid">
                    {displayBidAmountOrDash(highestBidAmount)}
                  </div>
                  <CommonButton
                    label={
                      item.status === CANCELLED_STATUS
                        ? CANCELLED_STATUS_NAME
                        : ENDED_STATUS_NAME
                    }
                    variant={CommonButtonVariants.AUCTION}
                    color={item.status === CANCELLED_STATUS ? CANCELLED_STATUS : ENDED_STATUS}
                  />
                </Col>
                <Col xs={12} lg={6} className="auction-details__buttons-section-wrapper">
                  <Col xs={12} sm={6} className="auction-details__buttons-subsection">
                    {item.isRepublishAllowed && (
                      <CommonButton
                        className="basic-data__button btn-primary"
                        label="Edit"
                        variant={
                          isLoading || !isSseRefresh
                            ? CommonButtonVariants.DISABLED
                            : CommonButtonVariants.PRIMARY
                        }
                        disabled={isLoading || !isSseRefresh}
                        handleClick={() =>
                          dispatch(
                            editAuction({
                              auctionId,
                              version: item?.version,
                              status: item?.status,
                            }),
                          )
                        }
                      />
                    )}
                    {item.status === ENDED_STATUS && (
                      <>
                        <CommonButton
                          className="basic-data__button"
                          label="Delete"
                          variant={CommonButtonVariants.DELETE}
                          handleClick={() => setConfirmDelete(true)}
                        />
                        <SweetAlert
                          danger
                          showCancel
                          show={confirmDelete}
                          onConfirm={() => {
                            dispatch(
                              deleteAuction({
                                id: auctionId,
                                version: item?.version,
                              }),
                            );
                            setConfirmDelete(false);
                          }}
                          onCancel={() => setConfirmDelete(false)}
                          confirmBtnBsStyle="info"
                          cancelBtnCssClass="cancel-button"
                          cancelBtnBsStyle="default"
                          title=""
                        >
                          <span className="sweet-alert-text">
                            Are you sure you want to delete this vehicle?
                          </span>
                        </SweetAlert>
                      </>
                    )}
                  </Col>

                  {item.isRepublishAllowed && (
                    <Col
                      xs={12}
                      sm={6}
                      className={
                        item.status === ENDED_STATUS
                          ? 'auction-details__relaunch-button--ended'
                          : 'auction-details__relaunch-button--cancelled'
                      }
                    >
                      <CommonButton
                        className="basic-data__button "
                        label="Relaunch"
                        variant={
                          isLoading || !isSseRefresh
                            ? CommonButtonVariants.DISABLED
                            : CommonButtonVariants.SUCCESS
                        }
                        handleClick={handleRelaunchAuction}
                        disabled={isLoading || !isSseRefresh}
                      />
                    </Col>
                  )}
                </Col>
              </Row>
            );
          case AuctionStatus.SOLD: {
            const location = item?.buyer?.organizationContact?.location;
            return (
              <Row className="summary__main-description-container auction-detail-seller auction-detail-seller-sold">
                <Col className="text-center">
                  <strong>The best bid:</strong>
                  <div className="auction-detail-bid">
                    {displayBidAmountOrDash(highestBidAmount)}
                  </div>
                  <CommonButton
                    label="Sold"
                    variant={CommonButtonVariants.AUCTION}
                    color={AuctionStatus.SOLD}
                  />
                </Col>
                <Col className="d-flex justify-content-center">
                  <div
                    className={`d-flex flex-column ${
                      item?.buyer?.organizationContact?.tradeName
                        ? 'justify-content-between'
                        : 'justify-content-center'
                    } `}
                  >
                    {item?.buyer?.organizationContact?.tradeName ? (
                      <>
                        <strong>Buyer&apos;s information:</strong>
                        <span>{item?.buyer?.organizationContact?.tradeName || ''}</span>
                        <span>
                          {item?.buyer?.firstName || ''} {item?.buyer?.lastName || ''}
                        </span>
                        <span>{location?.address || ''}</span>
                        <span>{location?.address2 || ''}</span>
                        <span>{location?.city || ''}</span>
                        <span>{location?.county || ''}</span>
                        <span>{location?.postalCode?.postcode || ''}</span>
                        <span>{item?.buyer?.email || ''}</span>
                        <span>
                          {item?.buyer?.landlinePhoneNumber || ''}
                          {item?.buyer?.phoneNumber ? ` / ${item?.buyer?.phoneNumber}` : ''}
                        </span>
                      </>
                    ) : (
                      <span>{StaticValues.PAYMENT_IN_PROGRESS}</span>
                    )}
                  </div>
                </Col>
              </Row>
            );
          }
          case AuctionStatus.REJECTED: {
            return (
              <Row className="summary__main-description-container auction-detail-seller">
                <Col xs={12} lg={6} className="text-center">
                  <strong>The best bid:</strong>
                  <div className="auction-detail-bid">
                    {displayBidAmountOrDash(highestBidAmount)}
                  </div>
                  <CommonButton
                    label={OFFER_DECLINED_STATUS_NAME}
                    variant={CommonButtonVariants.AUCTION}
                    color={AuctionStatus.REJECTED}
                  />
                </Col>
                <Col xs={12} lg={6} className="auction-details__buttons-section-wrapper">
                  {item.isRepublishAllowed === true && (
                    <>
                      <CommonButton
                        className="basic-data__button"
                        label="Edit"
                        variant={CommonButtonVariants.PRIMARY}
                        handleClick={() =>
                          dispatch(
                            editAuction({
                              auctionId,
                              version: item?.version,
                              status: item?.status,
                            }),
                          )
                        }
                      />
                      <CommonButton
                        label="Relaunch"
                        variant={CommonButtonVariants.SUCCESS}
                        handleClick={handleRelaunchAuction}
                      />
                    </>
                  )}
                </Col>
              </Row>
            );
          }
          default:
            return null;
        }
      })()}
    </>
  );
};

AuctionDetailsSellerSection.defaultProps = {
  bidNotification: '',
};

AuctionDetailsSellerSection.propTypes = {
  item: PropTypes.object.isRequired,
  refreshItem: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  changeStatus: PropTypes.func.isRequired,
  bidNotification: PropTypes.string,
  isAuctionRelaunched: PropTypes.bool.isRequired,
  setIsAuctionRelaunched: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default AuctionDetailsSellerSection;
