import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { v4 } from 'uuid';
import { isEmpty } from 'lodash';
import { Fancybox as FancyBoxNative } from '@fancyapps/ui';
import { PhotoPerspective } from '../../../constants/PhotoPerspective';
import warningIcon from '../../../assets/img/Ripple-2.7s-211px.gif';
import iconPoint from '../../../assets/img/grupa11.png';
import { prepareDamagesDescription } from '../../helpers/prepareDamagesDescription';
import CommonButton from '../CommonButton';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import galleryIcon from '../../../assets/img/icons/gallery.svg';
import { FancyBoxHiddenGallery, fancyBoxOptions } from '../FancyBox';
import { DamageTypesName } from '../../../constants/DamageTypes';

const ImageSlider = ({ videos, images }) => {
  const allMedia = [
    ...images.filter(image => image?.fileUrl),
    ...videos.filter(video => video?.fileUrl),
  ];

  const [mainSlide, setMainSlide] = useState(allMedia[0]);
  const [mainSlideIndex, setMainSlideIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    variableWidth: true,
    arrows: false,
    afterChange: index => {
      setMainSlideIndex(index);
      setMainSlide(allMedia[index]);
    },
  };

  const filteredRequiredPhotos = images?.filter(item => item?.id);

  useEffect(() => {
    FancyBoxNative.bind(`[data-fancybox="gallery"]`, {
      ...fancyBoxOptions,
      on: {
        done: (fancybox, slide) => {
          // eslint-disable-next-line radix
          if (!parseInt(slide?.damages) || !slide?.id) return;

          const mediaData = allMedia.find(el => el.id === slide.id);
          if (!mediaData || !mediaData?.damages?.length) return;

          // show damages icon and text over main slider
          const overlayWrapper = document.createElement('div');
          overlayWrapper.style.position = 'absolute';
          overlayWrapper.style.width = '100%';
          overlayWrapper.style.height = '100%';
          overlayWrapper.style.display = 'flex';
          overlayWrapper.style.justifyContent = 'center';
          overlayWrapper.style.alignItems = 'center';

          const damagedTextElement = document.createElement('div');
          damagedTextElement.className = 'photo_gallery_damage_info';

          const damagedTextSpanWrapperElement = document.createElement('span');

          const damagedTextImgElement = document.createElement('img');
          damagedTextImgElement.src = iconPoint;
          damagedTextImgElement.alt = 'Warning icon';
          damagedTextImgElement.className = 'damages__warning-icon';
          damagedTextImgElement.style.width = '16px';
          damagedTextImgElement.style.height = '16px';

          damagedTextSpanWrapperElement.append(
            'Tap ',
            damagedTextImgElement,
            'to see ',
            prepareDamagesDescription(mediaData.damages),
          );

          damagedTextElement.appendChild(damagedTextSpanWrapperElement);

          const damagedButtonElement = document.createElement('button');
          damagedButtonElement.className = 'photo_gallery_damage_button';

          const imgElement = document.createElement('img');
          imgElement.src = warningIcon;
          imgElement.alt = 'Warning icon';
          imgElement.className = 'damages__warning-icon';

          damagedButtonElement.appendChild(imgElement);

          damagedButtonElement.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            openDamagedGallery(mediaData.damages);
          });

          overlayWrapper.appendChild(damagedButtonElement);
          overlayWrapper.appendChild(damagedTextElement);

          slide.contentEl.appendChild(overlayWrapper);
        },
        shouldClose: (fancybox, event, eventComponent) => {
          if (!eventComponent) {
            event.preventDefault(); // prevent double click
          }

          event.stopPropagation();
        },
      },
    });
    return () => {
      FancyBoxNative.unbind(`[data-fancybox="gallery"]`);
      FancyBoxNative.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDamagedGallery = damages => {
    if (damages?.length) {
      FancyBoxNative.show(
        damages?.map(image => ({
          src: image?.fileUrl,
          caption: DamageTypesName?.[image?.damageType] || '',
        })),
        {
          ...fancyBoxOptions,
          on: {
            shouldClose: (fancybox, event, eventComponent) => {
              if (!eventComponent) {
                event.preventDefault(); // prevent double click
              }

              event.stopPropagation();
            },
          },
        },
      );
    }
  };

  const openGallery = (group, index = 0) => {
    FancyBoxNative.fromSelector(`[data-fancybox="${group}"]`, { startIndex: index });
  };

  return (
    <>
      <div className="common-images-slider">
        <div className="common-images-slider__main-image">
          {mainSlide.perspective !== PhotoPerspective.MASTER && !isEmpty(mainSlide?.damages) && (
            <>
              <button
                type="button"
                className="photo_gallery_damage_button"
                onClick={() => {
                  openDamagedGallery(mainSlide?.damages);
                }}
              >
                <img className="damages__warning-icon" src={warningIcon} alt="Warning icon" />
              </button>
              <div className="photo_gallery_damage_info">
                <span>
                  Tap <img src={iconPoint} alt="tab icon" width="16" /> to see{' '}
                  {prepareDamagesDescription(mainSlide?.damages)}
                </span>
              </div>
            </>
          )}
          <button
            className="h-100 w-100"
            type="button"
            onClick={() => {
              openGallery('gallery', mainSlideIndex);
            }}
          >
            <img
              src={
                mainSlide?.type === 'video'
                  ? mainSlide?.previewUrl || mainSlide?.fileUrl
                  : mainSlide.fileUrl
              }
              alt="Main"
              className="main-image"
            />
          </button>
          <div className="summary__photo-counter-wrapper">
            <CommonButton
              label={`${filteredRequiredPhotos?.length} PHOTOS`}
              variant={CommonButtonVariants.OUTLINED}
              icon={galleryIcon}
              iconLeft={true}
              iconAlt="Vehicle Gallery"
              handleClick={() => {
                openGallery('gallery');
              }}
            />
          </div>
        </div>
        <Slider
          className="common-images-slider__slider"
          {...settings}
          style={{ marginTop: '10px' }}
        >
          {allMedia.map((media, idx) => (
            <>
              <div className="slider__images-wrapper" key={v4()}>
                <img
                  className="slider__image"
                  src={
                    media?.type === 'video'
                      ? media?.previewUrl || media?.fileUrl
                      : media.fileUrl
                  }
                  alt={`Media ${idx}`}
                />
              </div>
            </>
          ))}
        </Slider>
      </div>
      <FancyBoxHiddenGallery media={allMedia} group="gallery" />
    </>
  );
};

export default ImageSlider;
