import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

const AuctionVehicleLocation = ({ item }) => {
  const { address, address2, city, county, postalCode } = item?.location || {};

  return (
    <>
      <Row className="main-description-container__section-title mt-4" id="vehicle-location">
        <Col md={12} className="p-0 pb-2 text-lg">
          Location of the Vehicle
        </Col>
      </Row>
      <Row className="mt-3 summary__selling-person-list text-base">
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Address - Line 1</p>
          <p className="m-0 fw-600">{address || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Address - line 2</p>
          <p className="m-0 fw-600">{address2 || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">City</p>
          <p className="m-0 fw-600">{city || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Post Code</p>
          <p className="m-0 fw-600">{postalCode?.postcode || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">County</p>
          <p className="m-0 fw-600">{county || '-'}</p>
        </Col>
      </Row>
    </>
  );
};

AuctionVehicleLocation.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AuctionVehicleLocation;
