import React from 'react';
import googlePlayBadge from 'assets/img/google-play-badge.png';
import googlePlayQR from 'assets/img/google-play-qr-code.svg';
import appStoreBadge from 'assets/img/app-store-badge.png';
import appStoreQR from 'assets/img/app-store-qr-code.svg';
import PropTypes from 'prop-types';

const downloadLinks = [
  {
    link: 'https://play.google.com/store/apps/details?id=com.sensilabs.itrade',
    badge: googlePlayBadge,
    alt: 'Google Play',
    qr: googlePlayQR,
  },
  {
    link: 'https://apps.apple.com/us/app/itrade30/id1526466103',
    badge: appStoreBadge,
    alt: 'App Store',
    qr: appStoreQR,
  },
];

const CommonDownloadIcons = ({ showQrCode }) => {
  return (
    <div className="footer-nav__download-app-wrapper">
      {downloadLinks.map(item => (
        <a key={item.link} href={item.link} className="download-badge">
          <img src={item.badge} alt={item.alt} />
          {showQrCode && item?.qr ? (
            <img src={item.qr} alt={item.alt} className="download-badge__qr" />
          ) : (
            ''
          )}
        </a>
      ))}
    </div>
  );
};

CommonDownloadIcons.defaultProps = {
  showQrCode: false,
};

CommonDownloadIcons.propTypes = {
  showQrCode: PropTypes.bool,
};

export default CommonDownloadIcons;
