import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import heroImage from '../../assets/img/AboutUs/HeroImage.png';
import checkExample from '../../assets/img/AboutUs/check-example.svg';

const HeroSection = () => {
  return (
    <section className="au-hero section">
      <Container className="hero-content h-100" fluid>
        <div className="hero-title fw-700 text-7xl text-left color-primary">
          We make car trading easy, transparent and efficient.
        </div>
        <h3 className="au-hero-subtitle color-primary text-xl">
          Volutpat nibh mauris est iaculis urna facilisis ultricies donec. Sit nibh nec
          pharetra lacus.
        </h3>
        <div className="au-hero__check-example">
          <Row>
            <Col md={6} sm={12} className="d-flex align-items-center mb-3">
              <img src={checkExample} alt="Automotive Expertise" />
              <p className="fw-400 m-0 ml-2">Automotive Expertise</p>
            </Col>
            <Col md={6} sm={12} className="d-flex align-items-center mb-3">
              <img src={checkExample} alt="Hundreds of Offers" />
              <p className="fw-400 m-0 ml-2">Hundreds of Offers</p>
            </Col>
            <Col md={6} sm={12} className="d-flex align-items-center mb-3">
              <img src={checkExample} alt="Best Deals" />
              <p className="fw-400 m-0 ml-2">Best Deals</p>
            </Col>
            <Col md={6} sm={12} className="d-flex align-items-center mb-3">
              <img src={checkExample} alt="Verified Dealers" />
              <p className="fw-400 m-0 ml-2">Verified Dealers</p>
            </Col>
            <Col md={6} sm={12} className="d-flex align-items-center mb-3">
              <img src={checkExample} alt="Free Search & Listing Tools" />
              <p className="fw-400 m-0 ml-2">Free Search & Listing Tools</p>
            </Col>
            <Col md={6} sm={12} className="d-flex align-items-center mb-3">
              <img src={checkExample} alt="Innovative Platform" />
              <p className="fw-400 m-0 ml-2">AInnovative Platform</p>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="hero-right-background" />
      <img src={heroImage} alt="register bid" className="au-hero-content-image" />
    </section>
  );
};

export default HeroSection;
