import React from 'react';
import PropTypes from 'prop-types';
import { getVehicleLiveOfferStatusStyle } from '../common/helpers/auctionColorHelper';
import { AuctionStatus, AuctionStatusName } from '../constants/AuctionStatus';

const AuctionStatusBox = ({ auction, content, customBoxVariant }) => {
  return (
    <div
      className={`auction-status-box status-box box-${customBoxVariant ||
        getVehicleLiveOfferStatusStyle(
          auction?.status,
        )} w-100 m-0 d-flex justify-content-center align-items-center font-weight-bold `}
    >
      <span
        className={`text-uppercase ${
          auction?.status === AuctionStatus.PUBLISHED ? 'dot live-dot' : ''
        }`}
      >
        {content || AuctionStatusName[auction?.status] || ''}
      </span>
    </div>
  );
};

AuctionStatusBox.defaultProps = {
  content: '',
  customBoxVariant: null,
};

AuctionStatusBox.propTypes = {
  auction: PropTypes.object.isRequired,
  content: PropTypes.string,
  customBoxVariant: PropTypes.string,
};

export default AuctionStatusBox;
