import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import CommonTable from '../../../common/components/CommonTable';
import InvoiceTableRow from './InvoiceTableRow';

const InvoiceTable = ({ data, offset, changeOffset }) => {
  return (
    <Col xs="12">
      <CommonTable
        headerOptions={['Date', 'Invoice Number', 'Price', 'Actions']}
        lastIsActionColumn={true}
        rowComponent={InvoiceTableRow}
        data={data}
        offset={offset}
        onOffsetChange={changeOffset}
      />
    </Col>
  );
};

InvoiceTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
};

export default InvoiceTable;
