import { CardNumberElement } from '@stripe/react-stripe-js';
import {
  StripeCustomErrorMessage,
  StripeValidationErrorCode,
  StripeValidationErrorParam,
  StripeValidationErrorType,
} from '../../constants/StripeValidationError';
import { GET_STRIPE_CLIENT_SECRET_FAILURE } from '../../register/RegisterReducer';
import { showAlert } from '../components/alert/AlertActions';
import AlertVariant from '../components/alert/AlertVariant';

export const confirmStripeCardSetup = async (stripe, elements, res, values, dispatch) => {
  const result = await stripe.confirmCardSetup(
    res?.data?.data?.stripeSetupIntentClientSecret,
    {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: values?.cardholder,
        },
      },
    },
  );

  if (result.error) {
    if (result.error.type === StripeValidationErrorType.VALIDATION_ERROR) {
      dispatch({
        type: GET_STRIPE_CLIENT_SECRET_FAILURE,
        payload: result?.error?.message,
      });
    } else if (result.error.code === StripeValidationErrorCode.AUTH_FAILURE) {
      dispatch(showAlert(StripeCustomErrorMessage.AUTH_FAILURE, AlertVariant.DANGER));
    } else if (result.error.param === StripeValidationErrorParam.INVALID_CARDHOLDER) {
      dispatch(
        showAlert("Cardholder's name must be at most 5000 characters", AlertVariant.DANGER),
      );
    } else {
      dispatch(showAlert(result?.error?.message, AlertVariant.DANGER));
    }
  }
  return result;
};
