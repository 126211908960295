import React, { useCallback, useState } from 'react';
import { FormGroup } from 'reactstrap';
import magnifierIcon from 'assets/img/icons/magnifier.svg';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import CommonInput from '../common/components/CommonInput';

const MyBidsSearch = ({ handleSearch }) => {
  const [value, setValue] = useState('');
  const search = useCallback(
    debounce(value => {
      handleSearch(value);
    }, 1000),
    [],
  );

  const handleChange = e => {
    setValue(e.target.value);
    search(e.target.value);
  };

  return (
    <>
      <FormGroup className="m-0">
        <CommonInput
          onChange={handleChange}
          placeHolder="Search"
          icon={magnifierIcon}
          iconClassName="search-icon"
          value={value}
        />
      </FormGroup>
    </>
  );
};

MyBidsSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default MyBidsSearch;
