import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import FormCurrencyInputField from '../../common/components/formFields/FormCurrencyInputField';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { fetchAuctionBids } from '../SearchActions';
import { preparePriceFormat } from '../../common/helpers/preparePriceFormat';
import { AUCTION_BID_MAX_BIDS_LIMIT } from '../../constants/AuctionBid';
import { getTitle } from '../../common/helpers/vehicleHelper';
import { validateBidAmount } from '../../common/helpers/AuctionBidHelper';
import { prepareBidsData } from '../../common/helpers/prepareBidsData';

const SearchPlaceBidModal = ({ item, toggleShow, toggleStep }) => {
  const dispatch = useDispatch();
  const [validationRequiredBid, setValidationRequiredBid] = useState(null);
  let auctionBids = useSelector(state => state.search.get('auctionBids'));
  const organizationId = useSelector(state => state.auth.get('organizationId'));

  useEffect(() => {
    if (auctionBids?.length) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      auctionBids = auctionBids.sort((a, b) => a.amountInPence - b.amountInPence);
    }
  }, [auctionBids]);

  useEffect(() => {
    dispatch(fetchAuctionBids(item?.id));
  }, [dispatch, item]);

  const isBidAllowed = item?.bids?.length < AUCTION_BID_MAX_BIDS_LIMIT;
  const { highestBidMyOrganization } = prepareBidsData(item.bids, organizationId);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required('Amount value is required')
      .integer('Bid must be an integer')
      .test(
        'required bid amount',
        `Your bid must be greater than or equal to ${preparePriceFormat(
          validationRequiredBid,
        )}`,
        value =>
          validateBidAmount(value, item, highestBidMyOrganization, setValidationRequiredBid),
      ),
  });

  const handlePlaceBid = (values, { setSubmitting }) => {
    setSubmitting(false);
    toggleStep(+values.amount);
  };

  return (
    <Modal isOpen={true} backdrop="static" keyboard={false} className="place-bid-modal">
      <Formik
        initialValues={{ amount: '' }}
        onSubmit={handlePlaceBid}
        validationSchema={validationSchema}
      >
        <Form autoComplete="off" className="text-left">
          <ModalBody>
            <h3 className="place-bid-modal__bids-list-title font-weight-bold text-center">
              Place a bid
            </h3>
            <p className="text-center text-xl mt-4">Please review and confirm your bid:</p>
            <p className="text-center text-xl font-weight-bold mb-5">{getTitle(item)}</p>
            <FormCurrencyInputField
              inputClassName="font-weight-bold"
              maxLength={14}
              name="amount"
              label="Enter your maximum bid"
              disabled={!isBidAllowed}
            />
          </ModalBody>
          <ModalFooter>
            <div className="actions-wrapper">
              <CommonButton
                variant={CommonButtonVariants.OUTLINED}
                label="Cancel"
                handleClick={toggleShow}
                className="w-100"
              />
              <CommonButton
                variant={CommonButtonVariants.SUCCESS}
                label="Bid"
                type="submit"
                disabled={!isBidAllowed}
                className="w-100"
              />
            </div>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};

export default SearchPlaceBidModal;
