import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useKeenSlider } from 'keen-slider/react';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import { isEmpty } from 'lodash';
import { DamageTypesName } from 'constants/DamageTypes';
import { prepareDamagesDescription } from 'common/helpers/prepareDamagesDescription';
import iconPoint from '../../assets/img/grupa11.png';
import warningIcon from '../../assets/img/Ripple-2.7s-211px.gif';
import 'keen-slider/keen-slider.min.css';
import { UploadFileTypes } from '../../constants/UploadFileTypes';
import leftArrowIcon from '../../assets/img/icons/left-thin-arrow.svg';
import rightArrowIcon from '../../assets/img/icons/right-thin-arrow.svg';
import CommonButton from './CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import closeIcon from '../../assets/img/icons/close.svg';

const VehicleImagesPopupSlider = ({
  photos,
  videos,
  initial,
  onDamageClick,
  showImageTitle,
  closeHandler,
}) => {
  const [currentSlide, setCurrentSlide] = useState(initial);
  const [hidden, setHidden] = useState(true);
  const [sliderRef, slider] = useKeenSlider({
    initial,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    mounted: () => {
      setHidden(false);
    },
  });
  const [secondSliderRef, secondSlider] = useKeenSlider({
    spacing: 20,
    initial,
    slidesPerView: 6,
  });

  const filteredRequiredPhotos = photos?.filter(item => item?.id);

  const getDamageText = useCallback(() => {
    if (
      currentSlide !== undefined &&
      currentSlide !== null &&
      filteredRequiredPhotos?.length
    ) {
      return filteredRequiredPhotos[currentSlide]?.damageType
        ? DamageTypesName[filteredRequiredPhotos[currentSlide].damageType] || ''
        : '';
    }

    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRequiredPhotos, currentSlide]);
  return (
    <>
      <div className="popup__slider-wrapper">
        <div className="popup__slider-close">
          <CommonButton
            label="CLOSE"
            handleClick={closeHandler}
            variant={CommonButtonVariants.OUTLINED}
            icon={closeIcon}
            iconLeft={true}
            iconAlt="Close"
          />
        </div>
        <div className="popup__slider-counter">
          <span className="slider-counter__current">{currentSlide + 1}</span> /{' '}
          {[...(photos || []), ...(videos || [])]?.length || 0}
        </div>
        {showImageTitle && <span className="popup__damage-type">{getDamageText()}</span>}
        <div ref={sliderRef} className="keen-slider popup__slider">
          {filteredRequiredPhotos?.length &&
            filteredRequiredPhotos.map(item =>
              item?.fileUrl ? (
                <div
                  key={item.id}
                  className={`keen-slider__slide popup__slide-container ${
                    hidden ? 'hidden' : ''
                  }`}
                >
                  <div className="popup__slide">
                    <img
                      src={item.fileUrl}
                      alt="Auction vehicle"
                      className="popup__slide-image"
                      data-damage={item?.damageType}
                    />
                    {!isEmpty(item?.damages) && (
                      <>
                        <button
                          type="button"
                          className="photo_gallery_damage_button"
                          onClick={() => onDamageClick(item.damages, item.perspective)}
                        >
                          <img
                            className="damages__warning-icon"
                            src={warningIcon}
                            alt="Warning icon"
                          />
                        </button>
                        <div className="photo_gallery_damage_info">
                          <span>
                            Tap <img src={iconPoint} alt="tab icon" /> to see{' '}
                            {item?.damages.length > 1 ? 'damages' : 'damage'}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : null,
            )}
          {videos.length &&
            videos.map(item => (
              <div
                key={item.id}
                className={`keen-slider__slide popup__slide-container summary__slider_video_container ${
                  hidden ? 'hidden' : ''
                }`}
              >
                <Player src={item.fileUrl} className="video-react_popup_slider">
                  <BigPlayButton position="center" />
                  <ControlBar />

                  {!isEmpty(item?.damages) && (
                    <>
                      <button
                        type="button"
                        className="photo_gallery_damage_button"
                        onClick={() => onDamageClick(item.damages, null)}
                      >
                        <img
                          className="damages__warning-icon"
                          src={warningIcon}
                          alt="Warning icon"
                        />
                      </button>
                      <div className="photo_gallery_damage_info">
                        <span>
                          Tap <img src={iconPoint} alt="tab icon" /> to see{' '}
                          {prepareDamagesDescription(item?.damages)}
                        </span>
                      </div>
                    </>
                  )}
                </Player>
                {item?.damageType && (
                  <span className="popup__damage-type">
                    {DamageTypesName[item.damageType]}
                  </span>
                )}
              </div>
            ))}
        </div>
        <div className="position-relative">
          <div
            ref={secondSliderRef}
            className={`keen-slider popup__thumbnails-slider ${
              (filteredRequiredPhotos?.length || videos?.length) &&
              filteredRequiredPhotos?.length + videos?.length < 5
                ? `popup__thumbnails-slider-${filteredRequiredPhotos.length + videos?.length}`
                : ''
            }`}
          >
            {filteredRequiredPhotos.concat(videos).length &&
              filteredRequiredPhotos.concat(videos).map((item, idx) => (
                <aside key={item.id} className="keen-slider__slide popup-slider__slide">
                  <button
                    className="popup__slide-button"
                    type="button"
                    onClick={() => {
                      slider.moveToSlideRelative(idx, true);
                      secondSlider.moveToSlideRelative(idx, true);
                    }}
                  >
                    <aside
                      className={`popup__thumbnails-slide ${
                        idx === currentSlide ? 'popup__slide-active' : ''
                      }`}
                    >
                      <img
                        className="popup__thumbnails-slide-image"
                        src={
                          item?.type === UploadFileTypes.VIDEO_TYPE
                            ? item.previewUrl
                            : item.fileUrl
                        }
                        alt="Auction vehicle thumbnail"
                      />
                    </aside>
                  </button>
                </aside>
              ))}
          </div>
          {secondSlider && (
            <>
              <button
                type="button"
                onClick={e => e.stopPropagation() || secondSlider.prev() || slider.prev()}
                className="popup__slider-arrow-button carousel-button-left popup__slider-arrow-button--left"
              >
                <img src={leftArrowIcon} alt="recently-sold-left-icon" />
              </button>
              <button
                type="button"
                onClick={e => e.stopPropagation() || secondSlider.next() || slider.next()}
                className="popup__slider-arrow-button carousel-button-right popup__slider-arrow-button--right"
              >
                <img src={rightArrowIcon} alt="recently-sold-right-icon" />
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

VehicleImagesPopupSlider.propTypes = {
  photos: PropTypes.array,
  videos: PropTypes.array,
  initial: PropTypes.number,
  onDamageClick: PropTypes.func,
  showImageTitle: PropTypes.bool,
  closeHandler: PropTypes.func,
};

VehicleImagesPopupSlider.defaultProps = {
  photos: [],
  videos: [],
  initial: 0,
  showImageTitle: true,
  closeHandler: () => {},
  onDamageClick: () => {},
};

export default VehicleImagesPopupSlider;
