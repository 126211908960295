export const BidStatusType = {
  PLACED_BIDS: 'PLACED_BIDS',
  WAITING: 'WAITING',
  TO_ACCEPT: 'TO_ACCEPT',
  WON: 'WON',
  LOST: 'LOST',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
};

export const BidStatusTypeName = {
  PLACED_BIDS: 'Placed bids',
  WAITING: 'Waiting',
  TO_ACCEPT: 'To accept',
  WON: 'Won',
  LOST: 'Lost',
  REJECTED: 'Declined',
  CANCELLED: 'Cancelled',
};

export const BidStatusTypeAnalyticsColors = {
  PLACED_BIDS: '#6189CC',
  CANCELLED: '#EEB6CB',
  REJECTED: '#D87FA1',
  TO_ACCEPT: '#9CB6DF',
  WAITING: '#FFCA5B',
  WON: '#00B394',
  LOST: '#F2F4F7',
};
