import { cloneDeep, get } from 'lodash';
import { reverse } from 'named-urls';
import { PhotoUploadState } from 'itrade-admin-panel/src/constants/PhotoUploadState';
import { apiClient } from '../api/ApiClient';
import { handleError, showAlert } from '../common/components/alert/AlertActions';
import {
  ADD_PHOTO_IN_SERVICE_HISTORY_SUCCESS,
  ADD_PHOTO_IN_SUMMARY_SUCCESS,
  ADD_PHOTO_START,
  ADD_PHOTO_SUCCESS,
  ADD_VIDEO_IN_SUMMARY_SUCCESS,
  AUCTION_CLEAR_SUCCESS,
  AUTOFILL_SUCCESS,
  CLEAR_BASIC_DATA_SAVED,
  CLEAR_DAMAGES_TO_DELETE,
  CLEAR_DAMAGES_TO_WITH_NO_DAMAGE_TYPE,
  CLEAR_DESCRIPTION_AND_SETTINGS_ERRORS_SUCCESS,
  CLEAR_PHOTOS_IN_SERVICE_HISTORY,
  DELETE_DAMAGE_SUCCESS,
  DELETE_PHOTO_SUCCESS,
  DELETE_VIDEO_IN_SUMMARY_SUCCESS,
  DISABLE_AUCTION_EDITING_SUCCESS,
  FETCH_ALL_VEHICLE_FEATURES_SUCCESS,
  FETCH_DESCRIPTION_TEMPLATE_SUCCESS,
  FETCH_ORGANIZATION_GROUP_SUCCESS,
  FETCH_ORGANIZATION_LOCATIONS_SUCCESS,
  GET_DRAFT_DATA,
  HANDLE_UPLOAD_PHOTO_ERROR,
  READ_CAP_DATA_FAILURE,
  READ_CAP_DATA_SUCCESS,
  REDIRECT_TO_EDIT_FORM_STEP,
  REMOVE_PHOTO_IN_SERVICE_HISTORY,
  SAVE_AUCTION_SUCCESS,
  SET_ADD_VEHICLE_FORM_STEP,
  SET_ADDITIONAL_PHOTO_POSITION,
  SET_BASIC_DATA_SUCCESS,
  SET_DAMAGE_TYPE,
  SET_PHOTO_STEP_SUCCESS,
  SORTED_PHOTOS_SUCCESS,
  UPDATE_PHOTOS_SUCCESS,
  VALIDATE_DESCRIPTION_AND_SETTINGS_FAILURE,
  VALIDATE_DESCRIPTION_AND_SETTINGS_SUCCESS,
  VALIDATE_TYRE_THREAD_DEPTHS_SUCCESS,
  VALIDATE_VEHICLE_FEATURES_SUCCESS,
  VEHICLE_FOUND_FAILURE,
  VEHICLE_FOUND_SUCCESS,
} from './AddVehicleReducer';
import { hideLoader, showLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import { reverseDateToString } from '../common/utils/date/Date.utils';
import AlertVariant from '../common/components/alert/AlertVariant';
import RouterPaths from '../constants/RouterPaths';
import history from '../browserHistory';
import { getPhotoDescription } from '../common/helpers/getPhotoDescription';
import { parseCurrencyToIntIfString } from '../common/helpers/parseCurrencyToIntIfString';
import { sortedByPerspective } from './helpers/sortByPerspective';
import { AuctionStatus } from '../constants/AuctionStatus';
import { AuctionStatusType } from '../constants/AuctionStatusType';
import { DueInDateType } from '../constants/DueInDateType';

const MEDIA_API_ENDPOINT = '/media';
const REGISTRATION_PALETE_ENDPOINT = '/cap/make';
const VALIDATE_BASIC_CAR_DATA_ENDPOINT = '/auction/validate-car-basic-data';
const VEHICLE_MAKE_LIST_ENDPOINT = '/vehicle-make';
const AUCTION_DRAFT_ENDPOINT = 'auction/draft';
const VALIDATE_VEHICLE_FEATURES_ENDPOINT = '/auction/validate-vehicle-specification';
const VALIDATE_VEHICLE_MEDIA_ENDPOINT = '/auction/validate-vehicle-media';
const VEHICLE_DESCRIPTION_TEMPLATE_ENDPOINT = '/vehicle-description-template';
const VEHICLE_DESCRIPTION_AND_SETTINGS_ENDPOINT = '/auction/validate-description-and-settings';
const ORGANIZATION_LOCATIONS_ENDPOINT = '/account/organization-locations';
const ALL_VEHICLE_FEATURES = '/vehicle-specification';
const ORGANIZATION_GROUP_ENDPOINT = '/account/organization-group';
const VALIDATE_TYRE_TREAD_DEPTHS_ENDPOINT = '/auction/validate-tyre-tread-depths';
const VALIDATE_SERVICE_HISTORY_ENDPOINT = '/auction/validate-service-history';
const AUCTION_ENDPOINT = '/auction';

export const saveToSendVehiclePhoto = (mediaFile, currentState, index) => dispatch => {
  currentState[index] = {
    file: mediaFile,
    fileUrl: mediaFile.fileUrl,
    mediaMetadataId: null,
    status: PhotoUploadState.PENDING,
    perspective: getPhotoDescription(index + 1).PERSPECTIVE,
    damages: currentState[index]?.damages ? currentState[index]?.damages : [],
    damagesToDelete: currentState[index]?.damagesToDelete
      ? currentState[index]?.damagesToDelete
      : [],
  };
  dispatch({
    type: ADD_PHOTO_SUCCESS,
    payload: {
      photos: currentState,
    },
  });
};

export const sortPhotosAndFillEmpty = sentPhotos => dispatch => {
  return dispatch({
    type: SORTED_PHOTOS_SUCCESS,
    payload: {
      photos: sortedByPerspective(sentPhotos),
    },
  });
};

export const updatePhotos = sentPhotos => dispatch => {
  return dispatch({
    type: UPDATE_PHOTOS_SUCCESS,
    payload: {
      photos: sentPhotos,
    },
  });
};

export const sendVehiclePhoto = (
  mediaFile,
  currentState,
  index,
  successCallback,
  noChangeUrl = false,
) => dispatch => {
  const formData = new FormData();
  formData.append('file', mediaFile);

  dispatch(showLoader());
  currentState[index] = {
    ...currentState[index],
    status: PhotoUploadState.IN_PROGRESS,
  };
  dispatch({
    type: ADD_PHOTO_START,
    payload: {
      photos: currentState,
    },
  });
  return apiClient
    .post(MEDIA_API_ENDPOINT, formData, {
      timeout: 60000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        currentState[index] = {
          status: PhotoUploadState.SENT,
          fileUrl: noChangeUrl ? currentState[index].fileUrl : res?.data?.data?.fileUrl,
          mediaMetadataId: res?.data?.data?.id,
          perspective: getPhotoDescription(index + 1).PERSPECTIVE,
          damages: currentState[index]?.damages ? currentState[index]?.damages : [],
          damagesToDelete: currentState[index]?.damagesToDelete
            ? currentState[index]?.damagesToDelete
            : [],
        };
        dispatch({
          type: ADD_PHOTO_SUCCESS,
          payload: {
            photos: currentState,
          },
        });
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const setDamageType = (damageType, damageIndex, photoIndex) => (dispatch, getState) => {
  const updatedPhotos = cloneDeep(getState().addVehicle.toJS()?.photos);

  updatedPhotos[photoIndex].damages[damageIndex] = {
    ...updatedPhotos[photoIndex].damages[damageIndex],
    damageType,
  };

  dispatch({
    type: SET_DAMAGE_TYPE,
    payload: {
      photos: updatedPhotos,
    },
  });
};

export const sendVehicleDamage = (mediaFile, currentState, sentPhotos, index) => dispatch => {
  const formData = new FormData();
  formData.append('file', mediaFile);
  dispatch(showLoader());
  return apiClient
    .post(MEDIA_API_ENDPOINT, formData, {
      timeout: 60000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        sentPhotos[index].damages = [
          ...currentState,
          {
            fileUrl: res?.data?.data?.fileUrl,
            mediaMetadataId: res?.data?.data?.id,
            damageType: mediaFile.damageType,
          },
        ];
        dispatch({
          type: ADD_PHOTO_SUCCESS,
          payload: {
            photos: sentPhotos,
          },
        });
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const sendPaleteNumber = (file, successCallback) => dispatch => {
  const message = { registrationPlate: file };
  dispatch(showLoader());
  return apiClient
    .post(REGISTRATION_PALETE_ENDPOINT, message)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: READ_CAP_DATA_SUCCESS,
          payload: {
            basicData: res?.data?.data,
            registrationPlate: file,
          },
        });
        dispatch({
          type: VEHICLE_FOUND_SUCCESS,
          payload: true,
        });
      }
      if (typeof successCallback === 'function') {
        successCallback();
      }
    })
    .catch(err => {
      if (get(err, 'response.status', null) === 412) {
        if (typeof successCallback === 'function') {
          successCallback();
        }
        dispatch({
          type: READ_CAP_DATA_FAILURE,
          payload: {
            registrationPlate: file,
          },
        });
        dispatch({
          type: VEHICLE_FOUND_FAILURE,
          payload: false,
        });
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const validateBasicCarData = (values, successCallback) => dispatch => {
  const { modelId, makeId } = values;
  const data = {
    ...values?.values,
    makeId,
    modelId,
  };
  dispatch(showLoader());

  return apiClient
    .post(VALIDATE_BASIC_CAR_DATA_ENDPOINT, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(saveBasicCarDataDraft(null, data, id => dispatch(getDraft(id))));
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => {
      if (get(err, 'response.status', null) === 412) {
        dispatch(showAlert('Invalid values', AlertVariant.DANGER));
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const getVehicleMakeList = successCallback => dispatch => {
  return apiClient
    .get(VEHICLE_MAKE_LIST_ENDPOINT)
    .then(res => successCallback(res?.data))
    .catch(err => dispatch(handleError(err)));
};

export const getVehicleModelList = (vehicleMakeId, succesCallback) => dispatch => {
  return apiClient
    .get(`${VEHICLE_MAKE_LIST_ENDPOINT}/${vehicleMakeId}`)
    .then(res => succesCallback(res?.data))
    .catch(err => dispatch(handleError(err)));
};

export const handleCarDataAutofil = isAccepted => dispatch => {
  dispatch({
    type: AUTOFILL_SUCCESS,
    payload: {
      autofill: isAccepted,
    },
  });
};

export const setPhotoStep = currentStep => dispatch => {
  dispatch({
    type: SET_PHOTO_STEP_SUCCESS,
    payload: {
      step: currentStep,
    },
  });
};

export const saveBasicCarDataDraft = (
  id = null,
  values,
  getDraftCallback,
  photoData = null,
) => (dispatch, getState) => {
  dispatch(showLoader());
  const addVehicleData = getState().addVehicle.toJS();
  const { version } = addVehicleData;
  const data = {
    ...addVehicleData,
    id: id || addVehicleData?.id,
    version,
    basicData: {
      ...values,
      registrationPlate: values?.registrationPlate?.toUpperCase() || '',
      mot: values?.mot || null,
    },
    vehicleMedia: { photos: photoData?.photos, photosToDelete: photoData?.photosToDelete },
  };

  return apiClient
    .post(AUCTION_DRAFT_ENDPOINT, data)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: SET_BASIC_DATA_SUCCESS,
          payload: {
            basicData: data?.basicData,
            id: res?.data?.data?.id || id,
          },
        });

        dispatch(showAlert('Draft saved successfully', AlertVariant.SUCCESS));
        dispatch(clearDamagesToDelete(photoData?.photos));
        if (typeof getDraftCallback === 'function') {
          getDraftCallback(res?.data?.data?.id || id);
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const validateVehicleFeatures = (values, successCallback) => (dispatch, getState) => {
  const { vehicleFeaturesDictionary, vehicleFeaturesCustom } = values;

  const prepareVehicleFeatures = () => {
    if (vehicleFeaturesDictionary?.length) {
      return vehicleFeaturesDictionary.map(item => {
        if (typeof item === 'object') {
          return item.id;
        }
        return item;
      });
    }
    return null;
  };

  const vehicleFeatures = {
    vehicleFeaturesDictionaryIds: prepareVehicleFeatures(),
    vehicleFeaturesCustom: vehicleFeaturesCustom?.length ? vehicleFeaturesCustom : null,
  };
  dispatch(showLoader());
  return apiClient
    .post(VALIDATE_VEHICLE_FEATURES_ENDPOINT, vehicleFeatures)
    .then(res => {
      const addVehicleData = getState().addVehicle.toJS();
      if (res.status === 200) {
        dispatch({
          type: VALIDATE_VEHICLE_FEATURES_SUCCESS,
          payload: {
            vehicleFeatures,
            vehicleFeaturesDictionary,
          },
        });
        dispatch(
          saveVehicleFeaturesDraft(vehicleFeatures, () =>
            dispatch(getDraft(addVehicleData.id)),
          ),
        );
        successCallback();
      }
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        dispatch(
          showAlert('Please select at least one of the default feature', AlertVariant.DANGER),
        );
      } else {
        dispatch(handleError(err));
      }
    });
};

export const removePhotosSoft = (index, photos, vehicleMedia, photosToDelete) => dispatch => {
  const clonedData = cloneDeep(photos);
  clonedData[index].fileUrl = null;
  clonedData[index].mediaMetadataId = null;
  dispatch({
    type: DELETE_PHOTO_SUCCESS,
    payload: {
      photos: clonedData,
      vehicleMedia,
      photosToDelete: [
        ...photosToDelete,
        photos[index]?.data?.id || photos[index]?.mediaMetadataId,
      ],
    },
  });
};

export const removePhotos = (index, photos, vehicleMedia, photosToDelete) => dispatch => {
  const clonedData = cloneDeep(photos);
  clonedData.splice(index, 1);
  clonedData.forEach((item, index) => {
    item.perspective = getPhotoDescription(index + 1).PERSPECTIVE;
    item.sortNumber = index;
  });
  dispatch({
    type: DELETE_PHOTO_SUCCESS,
    payload: {
      photos: clonedData,
      vehicleMedia,
      photosToDelete: [
        ...photosToDelete,
        photos[index]?.data?.id || photos[index]?.mediaMetadataId,
      ],
    },
  });
};

export const removeDamages = (
  index,
  sentPhotos,
  damageId,
  damageIndex,
  vehicleMedia,
) => dispatch => {
  const clonedState = cloneDeep(sentPhotos);
  const clonedDamages = clonedState[index]?.damagesToDelete || [];
  clonedState[index].damagesToDelete = [...clonedDamages, damageId];
  clonedState[index].damages.splice(damageIndex, 1);

  dispatch({
    type: DELETE_DAMAGE_SUCCESS,
    payload: {
      vehicleMedia,
      photos: clonedState,
    },
  });
};

export const clearDamagesToDelete = () => (dispatch, getState) => {
  const photos = getState()?.addVehicle?.toJS()?.photos;
  if (!photos) return;

  const clonedState = cloneDeep(photos);

  dispatch({
    type: CLEAR_DAMAGES_TO_DELETE,
    payload: {
      photos: clonedState.map(photo => ({
        ...photo,
        damagesToDelete: [],
      })),
    },
  });
};

export const clearDamagesWithNoDamageType = () => (dispatch, getState) => {
  const photos = getState()?.addVehicle?.toJS()?.photos;
  if (!photos) return;

  const clonedState = cloneDeep(photos);

  dispatch({
    type: CLEAR_DAMAGES_TO_WITH_NO_DAMAGE_TYPE,
    payload: {
      photos: clonedState.map(photo => ({
        ...photo,
        ...photo?.damages,
        damages: photo?.damages?.filter(el => el.damageType),
      })),
    },
  });
};

export const fetchDescriptionTemplate = () => dispatch =>
  apiClient
    .get(VEHICLE_DESCRIPTION_TEMPLATE_ENDPOINT)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_DESCRIPTION_TEMPLATE_SUCCESS,
          payload: {
            descriptionTemplate: res?.data?.data,
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });

export const validateDescriptionAndSettings = (values, setErrors) => dispatch => {
  const {
    description,
    vehicleLocation,
    type,
    minimumPriceInPence,
    autoAcceptPriceInPence,
    tryingToDealDeliveryDate,
    tryingToDealDeliveryDateType,
    timeOption,
    publishTarget,
  } = values;

  const data = {
    description: description?.trim(),
    locationId: vehicleLocation?.value,
    type: type?.value,
    minimumPriceInPence: parseCurrencyToIntIfString(minimumPriceInPence) * 100 || null,
    autoAcceptPriceInPence: parseCurrencyToIntIfString(autoAcceptPriceInPence) * 100 || null,
    tryingToDealDeliveryDate:
      type?.value === AuctionStatusType.TRYING_TO_DEAL &&
      tryingToDealDeliveryDateType?.value === DueInDateType.CUSTOM_DATE
        ? reverseDateToString(tryingToDealDeliveryDate)
        : null,
    tryingToDealDeliveryDateType:
      type?.value === AuctionStatusType.TRYING_TO_DEAL
        ? tryingToDealDeliveryDateType?.value
        : null,
    timeOption: timeOption?.value,
    publishTarget,
  };
  dispatch(showLoader());

  return apiClient
    .post(VEHICLE_DESCRIPTION_AND_SETTINGS_ENDPOINT, data)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: VALIDATE_DESCRIPTION_AND_SETTINGS_SUCCESS,
          payload: {
            descriptionAndSettings: data,
          },
        });

        dispatch(saveAuction(id => history.push(`${RouterPaths.MY_VEHICLES}/${id}`)));
      }
    })
    .catch(err => {
      dispatch(hideLoader());
      const error = err?.response?.data?.data;
      if (get(err, 'response.status', null) === 412) {
        dispatch({ type: VALIDATE_DESCRIPTION_AND_SETTINGS_FAILURE, payload: error });
        setErrors({
          minimumPriceInPence: error?.vehicleDescriptionAndSettingsForm
            ? 'Minimum Offer price must be greater than 0'
            : '',
          tryingToDealDeliveryDate: error?.tryingToDealDeliveryDate
            ? error?.tryingToDealDeliveryDate
            : '',
        });
      } else {
        dispatch(handleError(err));
      }
    });
};

export const clearDescriptionsAndSettingsErrors = () => dispatch => {
  dispatch({ type: CLEAR_DESCRIPTION_AND_SETTINGS_ERRORS_SUCCESS });
};

export const fetchOrganizationLocations = () => dispatch =>
  apiClient
    .get(ORGANIZATION_LOCATIONS_ENDPOINT)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_ORGANIZATION_LOCATIONS_SUCCESS,
          payload: {
            organizationLocations: res?.data?.data,
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });

export const fetchOrganizationGroup = () => dispatch =>
  apiClient
    .get(ORGANIZATION_GROUP_ENDPOINT)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_ORGANIZATION_GROUP_SUCCESS,
          payload: {
            organizationGroup: res?.data?.data,
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });

export const fetchAllVehicleFeatures = () => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(ALL_VEHICLE_FEATURES)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_ALL_VEHICLE_FEATURES_SUCCESS,
          payload: {
            allVehicleFeatures: res?.data?.data,
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const validateMedia = successCallback => (dispatch, getState) => {
  const addVehicleData = getState().addVehicle.toJS();

  dispatch(showLoader());

  return apiClient
    .post(VALIDATE_VEHICLE_MEDIA_ENDPOINT, addVehicleData.vehicleMedia)
    .then(res => {
      if (res.status === 200) {
        dispatch(saveAddPhotoDraft(() => dispatch(getDraft(addVehicleData.id))));

        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
      return null;
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        dispatch(showAlert(err?.response?.data?.data?.photos, AlertVariant.DANGER));
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const validateTyres = (values, successCallback) => (dispatch, getState) => {
  const addVehicleData = getState().addVehicle.toJS();

  dispatch(showLoader());

  const { nearsideFront, nearsideRear, offsideFront, offsideRear } = values;
  const data = {
    nearsideFront: nearsideFront.label,
    nearsideRear: nearsideRear.label,
    offsideFront: offsideFront.label,
    offsideRear: offsideRear.label,
  };
  const tyreThreadDepths = [];
  Object.keys(data).forEach(key => {
    const preparedKey = `${key[0]}S${key[key.length - 1]}`;
    tyreThreadDepths.push({ name: preparedKey.toUpperCase(), depths: data[key] });
  });

  return apiClient
    .post(VALIDATE_TYRE_TREAD_DEPTHS_ENDPOINT, data)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: VALIDATE_TYRE_THREAD_DEPTHS_SUCCESS,
          payload: {
            tyreThreadDepths: data,
            tyreThreadDepthsDictionary: tyreThreadDepths,
          },
        });
        dispatch(saveTyresDraft(data, () => dispatch(getDraft(addVehicleData.id))));
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const saveTyresDraft = (tyreThreadDepths, successCallback = null) => (
  dispatch,
  getState,
) => {
  dispatch(showLoader());
  const addVehicleData = getState().addVehicle.toJS();

  const draft = {
    ...addVehicleData,
    tyreThreadDepths,
  };
  return apiClient
    .post(AUCTION_DRAFT_ENDPOINT, draft)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Draft saved successfully', AlertVariant.SUCCESS));
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const saveServiceHistoryDraft = (serviceHistory, successCallback = null) => (
  dispatch,
  getState,
) => {
  dispatch(showLoader());
  const addVehicleData = getState().addVehicle.toJS();
  const draft = {
    ...addVehicleData,
    serviceHistory,
  };
  return apiClient
    .post(AUCTION_DRAFT_ENDPOINT, draft)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Draft saved successfully', AlertVariant.SUCCESS));
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const getDraft = auctionId => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(`${AUCTION_DRAFT_ENDPOINT}/${auctionId}`)
    .then(res => {
      if (res.status === 200) {
        const auctionPhotos = res?.data?.data?.vehicleMedia?.photos;
        const auctionVideos = res?.data?.data?.vehicleMedia?.videos;
        const arePhotosLoaded = res?.data?.data?.vehicleMedia?.photos?.length;
        dispatch({
          type: GET_DRAFT_DATA,
          payload: {
            data: res?.data?.data,
            photos: arePhotosLoaded ? sortedByPerspective(auctionPhotos) : [],
            videos: auctionVideos,
          },
        });
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const saveVehicleFeaturesDraft = (vehicleFeatures, successCallback) => (
  dispatch,
  getState,
) => {
  dispatch(showLoader());
  const addVehicleData = getState().addVehicle.toJS();
  const draft = {
    ...addVehicleData,
    vehicleFeatures,
  };
  return apiClient
    .post(AUCTION_DRAFT_ENDPOINT, draft)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Draft saved successfully', AlertVariant.SUCCESS));
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => dispatch(handleError(err)));
};

export const saveAuction = redirectCallback => (dispatch, getState) => {
  dispatch(showLoader());
  const addVehicleData = getState().addVehicle.toJS();

  return apiClient
    .post(AUCTION_ENDPOINT, addVehicleData)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: SAVE_AUCTION_SUCCESS, payload: res?.data?.data });
        dispatch(showAlert('Auction saved successfully', AlertVariant.SUCCESS));
        if (typeof redirectCallback === 'function') {
          redirectCallback(addVehicleData?.id);
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const saveDescriptionAndSettingsDraft = (descriptionAndSettings, successCallback) => (
  dispatch,
  getState,
) => {
  dispatch(showLoader());
  const addVehicleData = getState().addVehicle.toJS();
  const draft = {
    ...addVehicleData,
    descriptionAndSettings,
  };

  return apiClient
    .post(AUCTION_DRAFT_ENDPOINT, draft)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Draft saved successfully', AlertVariant.SUCCESS));
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const sendVehiclePhotoInSummary = (
  mediaFile,
  vehicleMedia,
  PhotoPerspective,
) => dispatch => {
  dispatch(showLoader());
  const formData = new FormData();
  formData.append('file', mediaFile);

  return apiClient
    .post(MEDIA_API_ENDPOINT, formData, {
      timeout: 60000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        const photo = {
          fileUrl: res?.data?.data?.fileUrl,
          mediaMetadataId: res?.data?.data?.id,
          perspective: PhotoPerspective.OTHER,
        };

        dispatch({
          type: ADD_PHOTO_IN_SUMMARY_SUCCESS,
          payload: {
            photos: [...vehicleMedia.photos, photo],
            vehicleMedia,
          },
        });
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const sendVehicleVideoInSummary = (mediaFile, thumbnail, callback) => (
  dispatch,
  getState,
) => {
  dispatch(showLoader());
  const { vehicleMedia } = getState().addVehicle.toJS();

  const formData = new FormData();
  formData.append('file', mediaFile);
  apiClient.defaults.timeout = 0;
  return apiClient
    .post(MEDIA_API_ENDPOINT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        dispatch(
          sendVehicleVideoThumbnail(thumbnail, preview => {
            const video = {
              fileUrl: res?.data?.data?.fileUrl,
              mediaMetadataId: res?.data?.data?.id,
              previewUrl: preview?.fileUrl,
              previewMediaMetadataId: preview?.id,
            };
            dispatch({
              type: ADD_VIDEO_IN_SUMMARY_SUCCESS,
              payload: {
                videos: [...vehicleMedia.videos, video],
                vehicleMedia,
              },
            });
            if (typeof callback === 'function') {
              callback({ video: res?.data?.data, thumbnail: preview });
            }
          }),
        );
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      if (typeof callback === 'function') {
        callback();
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const prepareThumbnailFile = async mediaFile => {
  const res = await fetch(mediaFile);
  const blob = await res.blob();
  const file = new File([blob], 'thumbnail', { type: 'image/jpeg', lastModified: Date.now() });
  const formData = new FormData();
  formData.append('file', file);
  return formData;
};

const sendVehicleVideoThumbnail = (mediaFile, callback) => dispatch => {
  return apiClient
    .post(MEDIA_API_ENDPOINT, mediaFile, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        callback(res?.data?.data);
      }
    })
    .catch(err => dispatch(handleError(err)));
};

export const deleteVehicleVideoInSummary = id => (dispatch, getState) => {
  dispatch(showLoader());
  const { vehicleMedia } = getState().addVehicle.toJS();
  return apiClient
    .delete(`${MEDIA_API_ENDPOINT}/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Video removed successfully', AlertVariant.SUCCESS));
        const newVideos = vehicleMedia.videos.filter(item => item.mediaMetadataId !== id);
        dispatch({
          type: DELETE_VIDEO_IN_SUMMARY_SUCCESS,
          payload: {
            videos: newVideos,
            vehicleMedia,
            videosToDelete: Array.isArray(vehicleMedia?.videosToDelete)
              ? [...vehicleMedia.videosToDelete, id]
              : [id],
          },
        });
      }
      return true;
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const sendServiceHistoryPhotoRecords = mediaFile => dispatch => {
  dispatch(showLoader());
  const formData = new FormData();
  formData.append('file', mediaFile);

  return apiClient
    .post(MEDIA_API_ENDPOINT, formData, {
      timeout: 60000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        const photoRecord = {
          fileUrl: res?.data?.data?.fileUrl,
          mediaMetadataId: res?.data?.data?.id,
        };
        dispatch({
          type: ADD_PHOTO_IN_SERVICE_HISTORY_SUCCESS,
          payload: {
            photoRecord,
          },
        });
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const removePhotosFromServiceHistory = id => (dispatch, getState) => {
  const state = getState().addVehicle.toJS();
  const updatedPhotoRecords = state.photoRecords.filter(e => e.mediaMetadataId !== id);

  dispatch({
    type: REMOVE_PHOTO_IN_SERVICE_HISTORY,
    payload: {
      photoRecords: updatedPhotoRecords,
    },
  });
};

export const validateServiceHistory = (values, successCallback) => (dispatch, getState) => {
  dispatch(showLoader());
  const addVehicleData = getState().addVehicle.toJS();
  return apiClient
    .post(VALIDATE_SERVICE_HISTORY_ENDPOINT, values)
    .then(res => {
      if (res.status === 200) {
        dispatch(saveServiceHistoryDraft(values, () => dispatch(getDraft(addVehicleData.id))));
        successCallback();
      }
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        dispatch(showAlert('Invalid service history data', AlertVariant.DANGER));
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const handleUploadPhotoError = value => dispatch => {
  dispatch({ type: HANDLE_UPLOAD_PHOTO_ERROR, payload: value });
};

export const clearAuction = () => dispatch => {
  dispatch({ type: AUCTION_CLEAR_SUCCESS });
};

export const saveAddPhotoDraft = successCallback => (dispatch, getState) => {
  dispatch(showLoader());
  const addVehicleData = getState().addVehicle.toJS();
  const filteredPhotoWithId = addVehicleData?.photos.filter(item => !!item.mediaMetadataId);
  filteredPhotoWithId.forEach((item, index) => {
    item.perspective = getPhotoDescription(index + 1).PERSPECTIVE;
    item.sortNumber = index;
  });
  const draft = {
    ...addVehicleData,
    vehicleMedia: {
      ...addVehicleData.vehicleMedia,
      photos: filteredPhotoWithId,
      photosToDelete: addVehicleData?.photosToDelete,
    },
  };

  return apiClient
    .post(AUCTION_DRAFT_ENDPOINT, draft)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Draft saved successfully', AlertVariant.SUCCESS));
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const redirectToEdit = (auctionId, getDraftCallback) => dispatch => {
  dispatch({
    type: REDIRECT_TO_EDIT_FORM_STEP,
    payload: {
      formStep: 3,
      id: auctionId,
      isAuctionEditing: true,
      autofill: true,
    },
  });
  history.push(RouterPaths.ADD_VEHICLE);
  if (typeof getDraftCallback === 'function') {
    getDraftCallback();
  }
};

export const setFormStep = step => dispatch => {
  dispatch({
    type: SET_ADD_VEHICLE_FORM_STEP,
    payload: {
      formStep: step,
    },
  });
};
export const disableAuctionEditing = () => dispatch => {
  dispatch({
    type: DISABLE_AUCTION_EDITING_SUCCESS,
  });
};

export const setAdditionalPhoto = (photoId, currentState, vehicleMedia) => dispatch => {
  const additionlPhoto = currentState.filter(element => element.mediaMetadataId === photoId);
  const filteredPhotos = currentState.filter(element => element.mediaMetadataId !== photoId);
  const restPhotos = filteredPhotos.slice(7, filteredPhotos.length);
  const mandatoryPhotos = filteredPhotos.slice(0, 7);
  const additionalPhotoNewPosition = [...mandatoryPhotos, ...additionlPhoto, ...restPhotos];

  dispatch({
    type: SET_ADDITIONAL_PHOTO_POSITION,
    payload: {
      vehicleMedia,
      photos: additionalPhotoNewPosition,
    },
  });
};

export const editAuction = ({ auctionId, version, status }) => dispatch => {
  if (
    status !== AuctionStatus.REJECTED &&
    status !== AuctionStatus.CANCELLED &&
    status !== AuctionStatus.ENDED
  ) {
    return dispatch(redirectToEdit(auctionId, () => dispatch(getDraft(auctionId))));
  }
  dispatch(showLoader());
  return apiClient
    .post(`${AUCTION_ENDPOINT}/${auctionId}/edit`, { id: auctionId, version })
    .then(res => {
      if (res.status === 200) {
        dispatch(redirectToEdit(auctionId, () => dispatch(getDraft(res?.data?.data?.id))));
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const redirectToConfirmLaunchPage = (item, isRepublish) => () => {
  history.push(
    reverse(RouterPaths.MY_VEHICLES_DETAILS_CONFIRM, {
      id: item?.id,
    }),
    { allowRedirect: true, item, isRepublish },
  );
};

export const clearBasicDataSaved = () => dispatch => {
  dispatch({
    type: CLEAR_BASIC_DATA_SAVED,
  });
};

export const clearPhotosInServiceHistory = () => dispatch => {
  dispatch({
    type: CLEAR_PHOTOS_IN_SERVICE_HISTORY,
  });
};
