import React from 'react';
import { Col, Row } from 'reactstrap';
import businessModelImage from '../../assets/img/AboutUs/business-model.png';

const BusinessModelSection = () => {
  return (
    <section className="au-business-model section">
      <div className="au-business-model__background-right hide-on-mobile-md" />
      <Row className="w-100 ml-0">
        <Col md={12} className="au-business-model__content-column">
          <Row className="m-auto">
            <Col xs={12} md={6} lg={6} className="pl-0 pr-4 au-business-model-content-left">
              <h5 className="color-light-primary text-lg font-weight-bolder">
                OUR BUSINESS MODEL
              </h5>
              <h5 className="font-weight-bold text-3xl mb-4">
                Habitant sed fermentum id ipsum porttitor justo iaculis proin vitae.
              </h5>
              <p>
                Erat commodo, dui in cursus pharetra eget lorem. Congue sed suspendisse cras id
                tellus amet imperdiet ut. Felis gravida dui sagittis, tempor risus at ut. Eget
                risus id aliquet quam. Aliquam morbi lobortis augue pellentesque. Vel, sed
                turpis sit semper. Scelerisque porta vel pellentesque fermentum, ipsum. Diam
                sed commodo est est donec enim. Ac viverra ac ullamcorper a adipiscing
                imperdiet. A justo dolor.
              </p>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="pr-0 pl-4 text-right au-business-model-content-right"
            >
              <img
                className="au-business-model__image"
                src={businessModelImage}
                alt="live online auctions"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default BusinessModelSection;
