import { Col, Label, Row } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import radioCompleted from '../../../assets/img/radio-completed.png';
import radio from '../../../assets/img/radio-image.png';

const steps = [
  { id: 1, title: 'Dealer Type' },
  { id: 2, title: 'Company Details' },
  { id: 3, title: 'Company Address' },
  { id: 4, title: 'Personal Details' },
  { id: 5, title: 'Payment Details' },
];

const ProgressItem = ({ step, currentStep }) => {
  const getProgressStepIcon = () => {
    if (currentStep === step.id) {
      return <Label className="progress-checked" />;
    }

    return currentStep > step.id ? (
      <img src={radioCompleted} alt="progress-icon" />
    ) : (
      <img src={radio} alt="progress-icon" />
    );
  };

  return (
    <Row className={`register-progress__item ${step.id === 1 ? 'mt-auto' : ''}`}>
      <Col className="font-weight-bold text-uppercase">{step.title}</Col>
      <Col className="register-progress__item-icon align-items-center flex-grow-0">
        {getProgressStepIcon()}
      </Col>
    </Row>
  );
};

const Progress = ({ step }) => {
  return (
    <Col className="register-progress register-page__hide-on-mobile">
      {steps.map(stepData => (
        <ProgressItem step={stepData} currentStep={step} key={stepData.id} />
      ))}
    </Col>
  );
};

Progress.propTypes = {
  step: PropTypes.number.isRequired,
};

export default Progress;
