import { get } from 'lodash';
import { apiClient } from '../api/ApiClient';
import { prepareSearchData, SORT_DESC } from '../common/helpers/prepareSearchData';
import { hideLoader, showLoader } from '../common/components/NavbarLoader/NavbarLoaderActions';
import { handleError, showAlert } from '../common/components/alert/AlertActions';

import {
  AGGREGATED_BY_MAKE_SUCCESS,
  LATEST_AUCTIONS_SUCCESS,
  MY_LATEST_AUCTIONS_SUCCESS,
  MY_LATEST_BIDS_SUCCESS,
  RECENTLY_SOLD_SUCCESS,
  HOT_AUCTION_SUCCESS,
} from './HomePageReducer';

import AlertVariant from '../common/components/alert/AlertVariant';

const AUCTION_SEARCH_ENDPOINT = '/auction/search';
const AGGREGATED_BY_MAKE_ENDPOINT = '/auction/aggregation';
const AUCTION_RECENTLY_SOLD_VEHICLES_ENDPOINT = '/auction/recently-sold-vehicles';
const HOT_AUCTION_ENDPOINT = '/auction/hot-auction-vehicle';

const MY_AUCTIONS_ENDPOINT = '/auction/my-auctions/search';
const MY_BIDS_ENDPOINT = '/auction/bids';

export const getLatestAuctions = values => dispatch => {
  dispatch(showLoader());
  const sortOption = { launchedOn: SORT_DESC };

  return apiClient
    .post(AUCTION_SEARCH_ENDPOINT, prepareSearchData(values, sortOption))
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: LATEST_AUCTIONS_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []).slice(0, 3),
            timeStamp: new Date(),
          },
        });
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const getRecentlySoldVehicles = () => dispatch => {
  dispatch(showLoader());

  return apiClient
    .get(AUCTION_RECENTLY_SOLD_VEHICLES_ENDPOINT)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: RECENTLY_SOLD_SUCCESS,
          payload: {
            items: res?.data?.data || [],
          },
        });
      }
    })
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(hideLoader()));
};

export const getAuctionsAggregatedByMake = () => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(AGGREGATED_BY_MAKE_ENDPOINT)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: AGGREGATED_BY_MAKE_SUCCESS,
          payload: {
            vehicleMakeVisible: res?.data?.data?.vehicleMakeVisible,
            aggregation: res?.data?.data?.aggregation,
          },
        });
      }
    })
    .catch(err => dispatch(handleError(err)))

    .finally(() => dispatch(hideLoader()));
};

export const getMyLatestAuctions = (values, getAuctionsCallback) => dispatch => {
  dispatch(showLoader());
  const sortOption = { launchedOn: SORT_DESC };

  return apiClient
    .post(MY_AUCTIONS_ENDPOINT, prepareSearchData(values, sortOption))
    .then(res => {
      if (res.status === 200) {
        const items = get(res, 'data.data.content', []).slice(0, 3);
        items.map(item => {
          item.timeStamp = new Date();
          return item;
        });
        dispatch({
          type: MY_LATEST_AUCTIONS_SUCCESS,
          payload: {
            myLatestAuctions: items,
            timeStamp: new Date(),
          },
        });
        if (typeof getAuctionsCallback === 'function') {
          const auctionsIds = get(res, 'data.data.content', [])
            .slice(0, 3)
            .map(item => item.id);
          getAuctionsCallback(auctionsIds);
        }
      }
    })
    .catch(err => {
      if (err?.response?.status === 403) {
        return dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const getMyLatestBids = (values, getAuctionsCallback) => dispatch => {
  const sortOption = { createdOn: SORT_DESC };

  dispatch(showLoader());
  return apiClient
    .post(MY_BIDS_ENDPOINT, prepareSearchData(values, sortOption))
    .then(res => {
      if (res.status === 200) {
        const items = get(res, 'data.data.content', []).slice(0, 3);
        items.map(item => {
          item.timeStamp = new Date();
          return item;
        });
        dispatch({
          type: MY_LATEST_BIDS_SUCCESS,
          payload: {
            myLatestBids: items,
            timeStamp: new Date(),
          },
        });
        if (typeof getAuctionsCallback === 'function') {
          const auctionsIds = get(res, 'data.data.content', [])
            .slice(0, 3)
            .map(item => item.id);
          getAuctionsCallback(auctionsIds);
        }
      }
    })

    .catch(err => {
      if (err?.response?.status === 403) {
        return dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const getHotAuction = () => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(HOT_AUCTION_ENDPOINT)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: HOT_AUCTION_SUCCESS,
          payload: res?.data || null,
        });
      }
    })
    .catch(err => {
      if (err?.response?.status === 403) {
        return dispatch(showAlert(err?.response?.data?.message, AlertVariant.DANGER));
      }
      return dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};
