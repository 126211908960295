import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import uuid from 'react-uuid';
import { checkIsPropertySpecified } from '../../helpers/checkIsPropertySpecified';

const Derivative = ({ derivative, limit, className }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const id = useMemo(() => uuid(), []);

  const shortMeText = value => {
    if (!limit) {
      return value;
    }

    if (value?.length > limit) {
      return `${value?.slice(0, limit)}...`;
    }

    return value || '';
  };

  return (
    <>
      <span className="position-relative" style={{ zIndex: 100 }} id={`tooltip-${id}`}>
        {shortMeText(checkIsPropertySpecified(derivative, false, '', 'N/S'), 9)}
      </span>
      <Tooltip
        placement="top"
        autohide={false}
        className={className}
        target={`tooltip-${id}`}
        isOpen={tooltipOpen}
        toggle={() => {
          if (derivative?.length > limit) {
            setTooltipOpen(!tooltipOpen);
          }
        }}
      >
        {derivative}
      </Tooltip>
    </>
  );
};

Derivative.defaultProps = {
  limit: null,
  className: '',
};

Derivative.propTypes = {
  derivative: PropTypes.string.isRequired,
  limit: PropTypes.number,
  className: PropTypes.string,
};

export default Derivative;
