import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reverse } from 'named-urls';
import { Col, Row } from 'reactstrap';
import SearchAlertsTable from './table/SearchAlertsTable';
import { setQueryString, useQueryString } from '../../common/helpers/queryString';
import CommonMyOrganizationContainer from '../../common/components/CommonMyOrganizationContainer';
import CommonButton from '../../common/components/CommonButton';
import RouterPaths from '../../constants/RouterPaths';
import {
  clearCopiedSearchAlertState,
  clearEditSearchAlertState,
  copyCopiedSearchAlertState,
  removeSearchAlert,
  searchSearchAlerts,
} from './SearchAlertActions';
import SettingsHeader from '../SettingsHeader';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import plusIcon from '../../assets/img/icons/plus.svg';
import CommonSweetAlert from '../../common/components/CommonSweetAlert';
import { CommonSweetAlertVariants } from '../../constants/CommonSweetAlertVariants';
import { AccountLayoutContentWrapper } from '../../common/components/AccountLayoutWrapper';

const SearchAlertsPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();
  const [searchAlertToDelete, setSearchAlertToDelete] = useState(null);

  const items = useSelector(state => state.searchAlerts.get('searchAlerts'));
  const totalItems = useSelector(state => state.searchAlerts.get('searchAlertsTotalItems'));

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(searchSearchAlerts(searchParams));
  }, [dispatch, searchParams]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  const removeItemHandler = id => {
    if (id) {
      dispatch(removeSearchAlert(id)).then(() => {
        dispatch(searchSearchAlerts(searchParams));
      });
    }
  };

  const copyItemHandler = values => {
    if (values) {
      dispatch(clearCopiedSearchAlertState());
      dispatch(copyCopiedSearchAlertState(values));
      history.push(RouterPaths.CREATE_SEARCH_ALERT);
    }
  };

  const editItemHandler = id => {
    if (id) {
      dispatch(clearEditSearchAlertState(id));
      history.push(reverse(RouterPaths.EDIT_SEARCH_ALERT, { id }));
    }
  };

  return (
    <CommonMyOrganizationContainer>
      <SettingsHeader>
        <Row>
          <Col className="d-flex justify-content-end align-items-center">
            <CommonButton
              className="text-sm"
              label="ADD ALERT"
              variant={CommonButtonVariants.PRIMARY}
              icon={plusIcon}
              iconAlt="Add New Alert"
              iconLeft={true}
              handleClick={() => {
                dispatch(clearCopiedSearchAlertState());
                history.push(RouterPaths.CREATE_SEARCH_ALERT);
              }}
            />
          </Col>
        </Row>
      </SettingsHeader>
      <AccountLayoutContentWrapper>
        <SearchAlertsTable
          data={{ items, total: totalItems }}
          offset={searchParams.offset}
          changeOffset={changeOffset}
          removeItemHandler={removeItemHandler}
          copyItemHandler={copyItemHandler}
          editItemHandler={editItemHandler}
          setSearchAlertToDelete={setSearchAlertToDelete}
        />
        <CommonSweetAlert
          variant={CommonSweetAlertVariants.ERROR}
          show={searchAlertToDelete !== null}
          onConfirm={() => {
            removeItemHandler(searchAlertToDelete);
            setSearchAlertToDelete(null);
          }}
          onCancel={() => setSearchAlertToDelete(null)}
        >
          ​Are you sure you want to delete this alert? We will no longer notify you when this
          type of vehicle is added.
        </CommonSweetAlert>
      </AccountLayoutContentWrapper>
    </CommonMyOrganizationContainer>
  );
};
export default withRouter(SearchAlertsPage);
