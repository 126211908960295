import React from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import CommonButton from '../common/components/CommonButton';
import RouterPaths from '../constants/RouterPaths';
import userSuccess from '../assets/img/user-success.png';

const RegisterSuccess = ({ history }) => {
  return (
    <Col
      md="12"
      className="register-form-container d-flex flex-column justify-content-center align-items-center"
    >
      <Row md={12} className="register-form-container__title w-100">
        <Col md="12">
          <h2 className="font-weight-bold">Thank you for registering</h2>
          <p className="mt-1">
            We will now verify the details you have provided and a member of our team will
            contact you shortly.
          </p>
        </Col>
      </Row>
      <Row md={12} className="register-form-container__content justify-content-center w-100">
        <Col md="12">
          <CommonButton
            label="Home page"
            handleClick={() => history.push(RouterPaths.HOMEPAGE)}
            className="register-form-success-button"
          />
        </Col>
      </Row>
      <img src={userSuccess} className="register-success-image" alt="register-success" />
    </Col>
  );
};

export default withRouter(RegisterSuccess);
