import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import validationSchema from '../forms/ProfileDetailsForm.schema';
import ProfileDetailsForm from '../forms/ProfileDetailsForm';
import { fetchMyProfileDetails, updateMyProfileDetails } from './MyProfileActions';
import { capitalizeString } from '../../common/helpers/capitalizeString';
import { preparePositionString } from '../../common/helpers/preparePositionString';
import { UserRole } from '../../constants/UserRole';
import CommonMyOrganizationContainer from '../../common/components/CommonMyOrganizationContainer';

const MyProfilePage = () => {
  const dispatch = useDispatch();
  const userAuthStatus = useSelector(state => state.auth.get('status'));

  useEffect(() => {
    dispatch(fetchMyProfileDetails());
  }, [dispatch, userAuthStatus]);

  const myProfileDetails = useSelector(state => state.myProfile.get('myProfileDetails'));
  const getUserRole = () => {
    const role = [];
    if (myProfileDetails) {
      if (myProfileDetails?.isSeller) {
        role.push(UserRole.SELLER);
      }
      if (myProfileDetails?.isBuyer) {
        role.push(UserRole.BUYER);
      }
    }
    return role;
  };

  const initialValues = {
    firstName: myProfileDetails?.firstName || '',
    lastName: myProfileDetails?.lastName || '',
    phoneNumber: myProfileDetails?.phoneNumber || '',
    email: myProfileDetails?.email || '',
    password: '',
    passwordRepeat: '',
    location:
      {
        value: myProfileDetails?.organizationLocationDto?.id,
        label: myProfileDetails?.organizationLocationDto?.postalCode?.postcode,
      } || '',
    position:
      {
        value: myProfileDetails?.position,
        label: preparePositionString(capitalizeString(myProfileDetails?.position)),
      } || '',

    role: getUserRole() || [],
    acceptNewsletter: myProfileDetails?.acceptNewsletter || false,
    version: myProfileDetails?.version !== undefined ? myProfileDetails?.version : '',
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await dispatch(updateMyProfileDetails(values));
    setSubmitting(false);
  };

  return (
    <CommonMyOrganizationContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {form => <ProfileDetailsForm form={form} />}
      </Formik>
    </CommonMyOrganizationContainer>
  );
};

export default withRouter(MyProfilePage);
