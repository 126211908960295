import React from 'react';
import PropTypes from 'prop-types';
import { Col, Nav, NavItem, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { CommonButtonSize } from '../constants/CommonButtonVariants';
import CommonButton from '../common/components/CommonButton';
import { BidStatusTypeName } from '../constants/BidStatusType';

const MyBidsHeader = ({ handlerChange }) => {
  const selected = useSelector(state => state.myBids.get('filter'));
  const handleClick = status => {
    if (status === selected) return;
    handlerChange(status);
  };

  return (
    <Row className="mb-2">
      <Col>
        <Nav className="d-flex flex-row flex-wrap my-vehicles-header">
          <NavItem className="my-vehicles-header__item">
            <CommonButton
              label="All"
              className={`${selected === '' ? 'selected' : ''} position-relative text-sm`}
              handleClick={() => handleClick('')}
              size={CommonButtonSize.MEDIUM}
            />
          </NavItem>
          {Object.keys(BidStatusTypeName).map(status => (
            <NavItem className="my-vehicles-header__item" key={status}>
              <CommonButton
                label={BidStatusTypeName[status]}
                className={`${
                  selected === status ? 'selected' : ''
                } position-relative text-sm`}
                handleClick={() => handleClick(status)}
                size={CommonButtonSize.MEDIUM}
              />
            </NavItem>
          ))}
        </Nav>
      </Col>
    </Row>
  );
};

MyBidsHeader.propTypes = {
  handlerChange: PropTypes.func.isRequired,
};

export default MyBidsHeader;
