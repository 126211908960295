export const getCardElementOptions = disabled => {
  return {
    style: {
      base: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'normal',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        color: '#66615b',
        iconColor: '#66615b',
        ':-webkit-autofill': {
          color: '#66615b',
        },
        '::placeholder': {
          color: disabled ? '#d0d5dd' : '#d9d9d9',
          fontWeight: 'normal',
        },
        ':disabled': {
          color: '#d0d5dd',
        },
      },
      invalid: {
        iconColor: '#ff0000',
        color: '#ff0000',
      },
    },
    hidePostalCode: true,
    disabled,
  };
};
