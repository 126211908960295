import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { FieldArray } from 'formik';
import { sendVehicleDamage } from '../AddVehicleActions';
import { resizeImage } from '../../common/utils/image/Image.utils';
import { DamageTypes } from '../../constants/DamageTypes';
import { checkInteriorPerspective } from '../helpers/checkInteriorPerspective';
import plusIcon from '../../assets/img/plus-icon.png';

const AddDamageDragInner = ({ form }) => {
  const [files, setFiles] = useState({});
  const [style, setStyle] = useState({});
  const currentStep = useSelector(state => state.addVehicle.get('step'));
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const index = currentStep - 1;
  // const damageNumber = form.values.vehicleMedia?.photos[index]?.damages?.length + 1 || 1;
  const sentPhotos = Array.from(useSelector(state => state.addVehicle.get('photos')));
  const dispatch = useDispatch();
  const sentDamages = sentPhotos[index]?.damages || [];
  const isInteriorPerspective = checkInteriorPerspective(
    form.values.vehicleMedia?.photos[index].perspective,
  );

  const onDrop = useCallback(
    async acceptedFiles => {
      const files = await Promise.all(
        acceptedFiles.map(async image => {
          const imageResized = await resizeImage(image);
          Object.assign(imageResized, {
            fileUrl: URL.createObjectURL(imageResized),
            damageType: isInteriorPerspective ? DamageTypes.OTHER : null,
          });
          return imageResized;
        }),
      );
      return setFiles(files);
    },
    [isInteriorPerspective],
  );

  useEffect(() => {
    if (files.length) {
      dispatch(sendVehicleDamage(files[0], sentDamages, sentPhotos, index));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, files]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    onDrop,
    noDrag: false,
    multiple: false,
  });

  useEffect(() => {
    if (isDragActive) {
      setStyle('add-photo__base-style add-photo__add-damage-style add-photo__active-style');
    } else if (isDragAccept) {
      setStyle('add-photo__base-style add-photo__add-damage-style add-photo__accept-style');
    } else if (isDragReject) {
      setStyle('add-photo__base-style add-photo__add-damage-style add-photo__accept-style');
    } else if (isLoading) {
      setStyle(
        'add-photo__base-style add-photo__add-damage-style add-photo__base-style--disabled',
      );
    } else {
      setStyle('add-photo__base-style add-photo__add-damage-style');
    }
  }, [isDragActive, isDragReject, isDragAccept, isLoading]);

  return (
    <>
      <div {...getRootProps({ className: style })}>
        <input {...getInputProps()} disabled={isLoading} />
        <p className="add-photo__drop-text m-0">CLICK TO BROWSE OR</p>
        <p className="add-photo__drop-text m-0">DRAG & DROP PHOTO HERE</p>
        <div className="add-photo__add_center-icon mt-3">
          <img src={plusIcon} alt="Add Damage" />
        </div>
      </div>
    </>
  );
};

const AddDamageDrag = props => {
  const currentStep = useSelector(state => state.addVehicle.get('step'));
  const index = currentStep - 1;

  return (
    <FieldArray
      {...props}
      name={`vehicleMedia.photos[${index}].damages`}
      component={AddDamageDragInner}
    />
  );
};

export default AddDamageDrag;
