import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { reverse } from 'named-urls';
import _ from 'lodash';
import { Row, Col, Alert } from 'reactstrap';
import MyVehiclesHeader from './MyVehiclesHeader';
import {
  clearMyAuctions,
  getAuction,
  handleAuctionEvent,
  searchMyAuctions,
  setVehicleFilter,
} from './MyVehiclesActions';
import MyVehiclesItem from './MyVehiclesItem';
import InfinityLoader from '../common/components/InfinityLoader';
import { AUCTION_DELETED, SSE_SELLER, SSEClient } from '../api/SSEClient';
import MyVehicleSearch from './MyVehicleSearch';
import RouterPaths from '../constants/RouterPaths';
import { MY_VEHICLES_REFRESH_SUCCESS } from './MyVehiclesReducer';
import {
  AccountLayoutNavItem,
  AccountLayoutTitle,
  AccountLayoutWrapper,
} from '../common/components/AccountLayoutWrapper';

const sseClient = new SSEClient(SSE_SELLER);

const MyVehiclesPage = ({ history, location }) => {
  const dispatch = useDispatch();
  const itemsRef = useRef({});
  const [scrollTo, setScrollTo] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [auctionsIds, setAuctionsIds] = useState([]);

  const status = useSelector(state => state.myVehicles.get('filter'));
  const hasMore = useSelector(state => state.myVehicles.get('hasMore'));
  const searchItems = useSelector(state => state.myVehicles.get('items').toJS());
  const searchTotalItems = useSelector(state => state.myVehicles.get('totalItems'));
  const auctionsTimestamp = useSelector(state => state.myVehicles.get('timeStamp'));
  const isBuyer = useSelector(state => state.auth.get('isBuyer'));
  const isSeller = useSelector(state => state.auth.get('isSeller'));
  const [hasAccess, setHasAccess] = useState(false);
  const [searchParams, setSearchParams] = useState({
    offset: 0,
    status,
    search: '',
  });

  const updateSearchItems = newItem => {
    dispatch({
      type: MY_VEHICLES_REFRESH_SUCCESS,
      payload: {
        item: newItem,
      },
    });
  };

  useEffect(() => {
    if (!isSeller) {
      setHasAccess(false);
      if (isBuyer) {
        return history.push(RouterPaths.MY_BIDS);
      }

      return history.push(RouterPaths.SEARCH);
    }
    return setHasAccess(true);
  }, [isBuyer, isSeller, history]);

  useEffect(() => {
    sseClient.listen(auctionsIds, response => {
      dispatch(
        handleAuctionEvent(response, () => {
          if (response.type === AUCTION_DELETED) {
            return dispatch(
              searchMyAuctions(searchParams, auctionsIds => {
                setAuctionsIds(prev => prev.concat(auctionsIds));
                setLoaded(true);
              }),
            );
          }
          return dispatch(
            getAuction(response.auctionId, auction => {
              updateSearchItems(auction);
            }),
          );
        }),
      );
    });
    return () => {
      sseClient.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionsIds, dispatch, searchParams]);

  const scrollToItem = () => {
    if (!scrollTo) return;
    if (itemsRef.current[scrollTo]) {
      itemsRef.current[scrollTo].scrollIntoView({ behavior: 'auto', block: 'center' });
      setScrollTo(null);
    }
  };

  useEffect(() => {
    if (location.state && location.state.offset && location.state.auctionId) {
      setSearchParams(prev => ({
        ...prev,
        offset: _.get(location, 'state.offset', 0) + 1,
        status,
      }));
      setScrollTo(location.state.auctionId);
      history.replace({ ...history.location, state: {} });
      return;
    }
    dispatch(
      searchMyAuctions(searchParams, auctionsIds => {
        setAuctionsIds(auctionsIds);
        setLoaded(true);
        scrollToItem();
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dispatch]);

  const loadData = () => {
    setSearchParams(prev => ({
      ...prev,
      offset: prev.offset + 1,
    }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearResults = () => {
    dispatch(clearMyAuctions());
    setAuctionsIds([]);
    setLoaded(false);
  };

  const handleFilter = status => {
    if (searchParams.status !== status) {
      clearResults();
      dispatch(setVehicleFilter(status));
      setSearchParams(prev => ({
        ...prev,
        offset: 0,
        status,
      }));
    }
  };

  useEffect(() => {
    const { hash } = location;
    if (hash && hash === '#vehicleFilter') {
      history.replace(RouterPaths.MY_VEHICLES);
    } else {
      dispatch(setVehicleFilter(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRef = (ref, id) => {
    itemsRef.current[id] = ref;
  };

  const handleSearch = useCallback(
    value => {
      setSearchParams(prev => {
        if (prev?.search !== value) {
          clearResults();
          return {
            ...prev,
            offset: 0,
            search: value,
          };
        }

        return prev;
      });
    },
    [clearResults],
  );

  const navItems = useCallback(() => {
    return [
      <AccountLayoutNavItem link={RouterPaths.MY_VEHICLES} hide={!hasAccess}>
        MY VEHICLES
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem link={RouterPaths.MY_BIDS} hide={!isBuyer}>
        MY BIDS
      </AccountLayoutNavItem>,
      <AccountLayoutNavItem link={RouterPaths.ANALYTICS}>ANALYTICS</AccountLayoutNavItem>,
    ];
  }, [hasAccess, isBuyer]);
  return (
    <AccountLayoutWrapper
      contentClassName="content-column__listing"
      navTitle={
        <AccountLayoutTitle>
          {searchTotalItems} {`${searchTotalItems === 1 ? 'VEHICLE' : 'VEHICLES'}`} SELLING
        </AccountLayoutTitle>
      }
      navOptions={navItems()}
    >
      <>
        {hasAccess && (
          <>
            <Row className="auction-detail-header">
              <Col md={12} className="p-0 d-flex flex-row">
                <h5 className="m-0 font-weight-bold page-title">My Vehicles</h5>
                <div className="ml-auto d-flex flex-row auction-detail-header__actions-container">
                  <MyVehicleSearch handleSearch={handleSearch} />
                </div>
              </Col>
            </Row>
            <MyVehiclesHeader handlerChange={handleFilter} />
            {loaded && (
              <>
                {searchTotalItems > 0 ? (
                  <InfiniteScroll
                    next={loadData}
                    hasMore={hasMore}
                    loader={<InfinityLoader />}
                    dataLength={searchItems.length}
                  >
                    {searchItems.map(item => (
                      <MyVehiclesItem
                        getRef={el => setRef(el, item.id)}
                        onClickItem={() =>
                          history.push({
                            pathname: reverse(RouterPaths.MY_VEHICLES_DETAILS, {
                              id: item.id,
                            }),
                            state: {
                              offset: searchParams.offset,
                              auctionId: item.id,
                              status: searchParams.status,
                            },
                          })
                        }
                        item={item}
                        key={item.id}
                        auctionTimestamp={auctionsTimestamp}
                      />
                    ))}
                  </InfiniteScroll>
                ) : (
                  <div className="mt-2">
                    {searchParams.search ? (
                      <Alert color="info">
                        Oops, we could not find any result for the given phrase
                      </Alert>
                    ) : (
                      <Alert color="info">There are no Vehicles</Alert>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    </AccountLayoutWrapper>
  );
};

export default MyVehiclesPage;
