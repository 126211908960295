import { Row } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import CommonButton from '../common/components/CommonButton';
import { CommonButtonVariants } from '../constants/CommonButtonVariants';

const Footer = ({
  next,
  prev,
  skip,
  prevLabel,
  nextLabel,
  message,
  skipButton,
  prevButtonVariant,
  nextButtonVariant,
  nextButtonClassName,
  prevButtonClassName,
  prevButtonDisabled,
  nextButtonDisabled,
}) => {
  return (
    <Row className="register-footer align-items-center">
      {prev && (
        <div className="mr-auto">
          <CommonButton
            className={`ml-5 ${prevButtonClassName || ''}`}
            label={prevLabel || 'BACK'}
            handleClick={prev}
            variant={prevButtonVariant || CommonButtonVariants.OUTLINED}
            asyncButton
            disabled={prevButtonDisabled}
          />
        </div>
      )}

      {next && (
        <div className="ml-auto">
          <div className="d-inline-block mr-3">{message || ''}</div>
        </div>
      )}
      {skipButton && (
        <div className="ml-auto">
          <div className="d-inline-block">
            <CommonButton
              className={`mr-1 ${nextButtonClassName || ''}`}
              label="SKIP"
              handleClick={skip}
              asyncButton
              variant={CommonButtonVariants.OUTLINED}
            />
          </div>
        </div>
      )}
      {next && (
        <div className="">
          <div className="d-inline-block mr-3">{message || ''}</div>
          <div className="d-inline-block">
            <CommonButton
              className={`mr-5 ${nextButtonClassName || ''}`}
              label={nextLabel || 'NEXT'}
              handleClick={next}
              asyncButton
              variant={nextButtonVariant || CommonButtonVariants.PRIMARY}
              disabled={nextButtonDisabled}
            />
          </div>
        </div>
      )}
    </Row>
  );
};

Footer.defaultProps = {
  next: null,
  prev: () => {},
  skip: () => {},
  prevLabel: 'Prev',
  nextLabel: 'Next',
  skipButton: false,
  prevButtonVariant: null,
  nextButtonVariant: null,
  nextButtonClassName: '',
  prevButtonClassName: '',
  prevButtonDisabled: false,
  nextButtonDisabled: false,
};

Footer.propTypes = {
  next: PropTypes.func,
  prev: PropTypes.func,
  skip: PropTypes.func,
  prevLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  skipButton: PropTypes.bool,
  prevButtonVariant: PropTypes.string,
  nextButtonVariant: PropTypes.string,
  nextButtonClassName: PropTypes.string,
  prevButtonClassName: PropTypes.string,
  prevButtonDisabled: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
};

export default Footer;
