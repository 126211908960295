import React, { useRef } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import SlickCarousel from '../../common/components/SlickCarousel';
import defPhoto from '../../assets/img/vehicles/car-default-photo.svg';
import leftArrowIcon from '../../assets/img/left-arrow-primary.png';
import rightArrowIcon from '../../assets/img/right-arrow-white.png';

const CarouselItem = ({ item }) => {
  const shortMeDerivative = () => {
    if (item?.derivative && item?.derivative?.length > 8) {
      return `${item?.derivative?.slice(0, 12)}...`;
    }

    return item?.derivative || '';
  };

  return (
    <Row className="recently-sold-carousel__item m-0">
      <Col md={12} className="p-0 d-flex flex-column mb-3">
        <div className="recently-sold-carousel__item-image">
          <img src={item?.imageUrl || defPhoto} alt="Vehicle" className="w-100" />
        </div>
        <div className="recently-sold-carousel__item-content">
          <p className="text-sm font-weight-bold mb-1">{item?.title || ''}</p>
          <p className="text-sm font-weight-bold">{item?.model || ''}</p>
          <p className="text-sm">
            {shortMeDerivative()} {item?.fuel || ''} &#183; {item?.year || ''}
          </p>
          <div className="item-content__sold-box">SOLD</div>
        </div>
      </Col>
    </Row>
  );
};

const carouselSettings = {
  adaptiveHeight: true,
  variableWidth: true,
  swipe: false,
  appendArrows: '.recently-sold-carousel__buttons',
  arrows: false,
};

const RecentlySoldSection = ({ items }) => {
  const slickRef = useRef(null);

  const nextItem = () => {
    if (slickRef?.current) {
      slickRef.current.slickNext();
    }
  };

  const prevItem = () => {
    if (slickRef?.current) {
      slickRef.current.slickPrev();
    }
  };

  return (
    <section className="recently-sold section">
      <Row className="w-100 h-100 mb-5">
        <Col md={12} className="text-right mb-3">
          <span className="font-weight-bold text-xl ml-auto color-light-primary">
            RECENTLY SOLD
          </span>
        </Col>
        <Col md={12}>
          <Row>
            <Col sm={12} md={3}>
              <div className="recently-sold-carousel__buttons">
                <button onClick={prevItem} className="carousel-button-left mr-2" type="button">
                  <img src={leftArrowIcon} alt="recently-sold-left-icon" />
                </button>
                <button
                  onClick={nextItem}
                  className="carousel-button-right ml-2"
                  type="button"
                >
                  <img src={rightArrowIcon} alt="recently-sold-right-icon" />
                </button>
              </div>
            </Col>
            <Col sm={12} md={9} className="text-right">
              <span className="font-weight-bold section-title ml-auto">
                A small selection of recently sold vehicles.
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="w-100 h-100">
        <Col md={12} className="w-100 h-100 recently-sold-carousel">
          <SlickCarousel
            settings={carouselSettings}
            slickRef={slickRef}
            items={(() => {
              return items.map(el => <CarouselItem key={uuid()} item={el} />);
            })()}
          />
        </Col>
      </Row>
    </section>
  );
};

RecentlySoldSection.propTypes = {
  items: PropTypes.array.isRequired,
};

export default RecentlySoldSection;
