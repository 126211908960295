import * as Yup from 'yup';
import { AuctionStatusType } from '../../constants/AuctionStatusType';
import { FormRegExp } from '../../constants/FormRegExp';

export default () => {
  return Yup.object().shape({
    description: Yup.string()
      .required('Field is required')
      .min(1, 'Description must be between 1 and 10000 characters')
      .max(10000, 'Description must be between 1 and 10000 characters'),
    vehicleLocation: Yup.string().required('Field is required'),
    type: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }),
    autoAcceptPriceInPence: Yup.string().when('type', {
      is: value => value.value === AuctionStatusType.PHYSICAL,
      then: Yup.string()
        .matches(FormRegExp.INTEGER, 'Type only numbers')
        .max(10, 'Reserve Price must be between 1 and 10 characters')
        .required('Field is required')
        .test('minValue', 'Reserve Price must be greater or equal to 100', value => {
          return Number(value) >= 100;
        }),
    }),
    minimumPriceInPence: Yup.string().when('type', {
      is: value => value.value === AuctionStatusType.MINIMUM_PRICE,
      then: Yup.string()
        .matches(FormRegExp.INTEGER, 'Type only numbers')
        .max(10, 'Minimum Offer must be between 1 and 10 characters')
        .required('Field is required'),
    }),
    tryingToDealDeliveryDate: Yup.string()
      .nullable()
      .when('type', {
        is: value => value.value === AuctionStatusType.TRYING_TO_DEAL,
        then: Yup.string().required('Field is required'),
      }),
    timeOption: Yup.string()
      .required('Field is required')
      .nullable(),
    publishTarget: Yup.string().required('Field is required'),
  });
};
