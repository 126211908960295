import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EmployeeStatusTypes } from 'constants/EmployeeStatusTypes';
import CommonMyOrganizationContainer from '../../../common/components/CommonMyOrganizationContainer';
import {
  fetchEmployeeDetails,
  updateEmployeeDetails,
  updateRejectedEmployeeDetails,
} from '../../SettingsActions';
import { constantToSelect } from '../../../common/helpers/constantToSelect';
import { DealerPositionName } from '../../../constants/DealerPosition';
import { UserRole } from '../../../constants/UserRole';
import EmployeeForm from '../forms/EmployeeForm';
import validationSchema from '../forms/EditEmployeeForm.schema';
import { capitalizeSnakeCaseStringFromApi } from '../../../common/helpers/capitalizeSnakeCaseStringFromApi';

const dealerRole = constantToSelect(DealerPositionName);

const EditEmployeePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchEmployeeDetails(id));
  }, [dispatch, id]);

  const employeeDetails = useSelector(state => state.myOrganization.get('employeeDetails'));
  const getUserRole = () => {
    const role = [];
    if (employeeDetails) {
      if (employeeDetails?.employee?.isSeller) {
        role.push(UserRole.SELLER);
      }
      if (employeeDetails?.employee?.isBuyer) {
        role.push(UserRole.BUYER);
      }
      if (employeeDetails?.employee?.isTransactionAcceptor) {
        role.push(UserRole.TRANSACTION_MANAGEMENT);
      }
    }
    return role;
  };

  const initialValues = {
    firstName: employeeDetails?.employee?.firstName,
    lastName: employeeDetails?.employee?.lastName,
    phoneNumber: employeeDetails?.employee?.phoneNumber,
    email: employeeDetails?.employee?.email,
    password: '',
    passwordRepeat: '',
    location:
      {
        value: employeeDetails?.employee?.location?.id,
        label: employeeDetails?.employee?.location?.postalCode?.postcode,
      } || '',
    position:
      {
        value: employeeDetails?.employee?.position,
        label: capitalizeSnakeCaseStringFromApi(employeeDetails?.employee?.position),
      } || dealerRole,
    role: getUserRole() || [],
    version: employeeDetails?.employee?.version,
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    if (employeeDetails?.employee?.status === EmployeeStatusTypes.REJECTED) {
      await dispatch(updateRejectedEmployeeDetails(values, id, setErrors));
    } else {
      await dispatch(updateEmployeeDetails(values, id, setErrors));
    }
    setSubmitting(false);
  };

  return (
    <CommonMyOrganizationContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {form => <EmployeeForm id={id} form={form} values={form?.values} />}
      </Formik>
    </CommonMyOrganizationContainer>
  );
};

export default EditEmployeePage;
