import React, { useRef, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { get } from 'lodash';
import CommonButton from '../CommonButton';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import { capitalizeString } from '../../helpers/capitalizeString';
import eyeIcon from '../../../assets/img/icons/eye.svg';
import eyeCrossedIcon from '../../../assets/img/icons/eye-crossed.svg';

export const FormInputFieldType = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  TIME: 'time',
  PASSWORD: 'password',
  TEXTAREA: 'textarea',
  EMAIL: 'email',
};

const FormInputFieldInner = props => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  let ref = useRef(null);
  const {
    label,
    placeholder,
    type,
    form,
    field,
    noStyling = false,
    icon,
    disabled,
    invalid,
    iconClassName,
    id,
    suffix,
    maxLength,
    withButton,
    buttonLabel,
    buttonSize,
    buttonClassNames,
    buttonHandleClick,
    buttonDisabled,
    setRef,
    handleKeyDown,
    upperCase,
    capitalize,
    capitalizeAllWords,
    nolabel,
    initialValue,
    inputClassName,
    showPasswordIcon,
    wrapperClassName,
    lengthCounter,
    required,
  } = props;
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  const _value = get(form.values, field.name, '');

  const value =
    _value !== undefined && _value !== null && _value !== '' ? _value : initialValue;

  const renderValue = () => {
    if (upperCase) {
      return value.toUpperCase();
    }
    if (capitalize || capitalizeAllWords) {
      return capitalizeString(value, capitalizeAllWords);
    }
    return value;
  };

  const element = (
    <div className={`${icon ? 'icon-form-input-field-wrapper' : ''} ${wrapperClassName}`}>
      {icon && (
        <div
          className={`input-icon ${iconClassName || 'icon-form-input-field-image-wrapper'} ${
            disabled ? 'input-icon__disabled' : ''
          }`}
        >
          <img src={icon} alt="Input icon" />
        </div>
      )}
      <Input
        placeholder={placeholder}
        type={
          type === FormInputFieldType.PASSWORD && isPasswordVisible
            ? FormInputFieldType.TEXT
            : type
        }
        value={renderValue()}
        disabled={form.isSubmitting || disabled}
        onBlur={() => form.setFieldTouched(field.name, true)}
        onChange={({ target }) => {
          form.setFieldValue(field.name, target.value);
        }}
        className={`${inputClassName} ${
          icon && !iconClassName ? 'icon-form-input-field' : ''
        } ${suffix && !invalid && !error ? 'form-input-field__suffix-padding' : ''} ${
          suffix && (invalid || error) ? 'form-input-field__suffix-padding-error' : ''
        }`}
        invalid={invalid || (error && touched)}
        id={id}
        name={field.name}
        maxLength={maxLength}
        innerRef={input => {
          if (setRef) {
            ref = input;
            setRef(ref);
          }
        }}
        onKeyDown={handleKeyDown ? e => handleKeyDown(e) : null}
      />
      {maxLength && lengthCounter ? (
        <Row>
          <Col className="form-input__length-counter">
            <span>
              {value?.length || 0} / <b>{maxLength}</b>
            </span>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {type === FormInputFieldType.PASSWORD &&
        showPasswordIcon &&
        !invalid &&
        !(error && touched) && (
          <div
            className={`icon-form-input-password-field-image-wrapper ${
              isPasswordVisible ? 'icon-password-visible' : ''
            }`}
          >
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              src={isPasswordVisible ? eyeCrossedIcon : eyeIcon}
              onClick={() => {
                setIsPasswordVisible(prevState => !prevState);
              }}
              alt="Password input icon"
            />
          </div>
        )}
      {suffix && (value || typeof value === 'number') ? (
        <div
          className={`form-input-field__suffix ${
            invalid || error ? 'form-input-field__suffix-error' : ''
          }`}
        >
          {suffix}
        </div>
      ) : null}
      {withButton && (
        <CommonButton
          label={buttonLabel}
          size={buttonSize}
          className={buttonClassNames}
          handleClick={buttonHandleClick}
          disabled={buttonDisabled}
          variant={
            buttonDisabled ? CommonButtonVariants.DISABLED : CommonButtonVariants.PRIMARY
          }
        />
      )}
      {(form.submitCount > 0 || touched) && error && (
        <label className="form-input-field__label error-label">{error}</label>
      )}
    </div>
  );

  return noStyling ? (
    element
  ) : (
    <Row>
      <Col>
        {!nolabel && (
          <Label
            className={`form-input-field-label ${required ? 'label-required' : ''} ${
              disabled ? 'form-input-field-label__disabled' : ''
            }`}
          >
            {label}
          </Label>
        )}
        <FormGroup>{element}</FormGroup>
      </Col>
    </Row>
  );
};

const FormInputField = props => {
  return <Field {...props} component={FormInputFieldInner} />;
};

FormInputField.defaultProps = {
  type: FormInputFieldType.TEXT,
  placeholder: '',
  label: '',
  icon: '',
  disabled: false,
  invalid: '',
  iconClassName: '',
  id: null,
  suffix: '',
  maxLength: null,
  withButton: false,
  buttonLabel: '',
  buttonSize: '',
  buttonClassNames: '',
  buttonHandleClick: null,
  buttonDisabled: false,
  setRef: null,
  handleKeyDown: null,
  upperCase: false,
  capitalize: false,
  capitalizeAllWords: false,
  nolabel: false,
  initialValue: '',
  inputClassName: '',
  showPasswordIcon: true,
  wrapperClassName: '',
  lengthCounter: false,
  required: false,
};

FormInputField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  iconClassName: PropTypes.string,
  id: PropTypes.string,
  suffix: PropTypes.string,
  maxLength: PropTypes.number,
  withButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonClassNames: PropTypes.string,
  buttonHandleClick: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  setRef: PropTypes.func,
  handleKeyDown: PropTypes.func,
  upperCase: PropTypes.bool,
  capitalize: PropTypes.bool,
  capitalizeAllWords: PropTypes.bool,
  nolabel: PropTypes.bool,
  initialValue: PropTypes.string,
  inputClassName: PropTypes.string,
  showPasswordIcon: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  lengthCounter: PropTypes.bool,
  required: PropTypes.bool,
};

export default FormInputField;
