import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';

const SearchConfirmBidModal = ({ toggleShow, placeBid, bidAmount, vehicleTitle }) => {
  return (
    <Modal
      isOpen={true}
      backdrop="static"
      keyboard={false}
      fade={false}
      className="place-bid-modal"
    >
      <ModalBody>
        <h3 className="place-bid-modal__bids-list-title font-weight-bold text-center">
          PLACE A BID
        </h3>
        <p className="text-center text-xl mt-3">Please review and confirm your bid:</p>
        {vehicleTitle ? (
          <p className="text-center text-2xl">
            Vehicle: <span className="font-weight-bold">{vehicleTitle}</span>
          </p>
        ) : (
          ''
        )}
        <p className="text-center text-2xl">
          Your Bid:{' '}
          <span className="font-weight-bold">
            <CurrencyFormat
              value={bidAmount}
              thousandSeparator={true}
              allowNegative={false}
              prefix="£"
              displayType="text"
            />
          </span>
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="actions-wrapper">
          <CommonButton
            variant={CommonButtonVariants.OUTLINED}
            label="CANCEL"
            handleClick={toggleShow}
            className="w-100"
          />
          <CommonButton
            variant={CommonButtonVariants.SUCCESS}
            label="CONFIRM"
            handleClick={placeBid}
            className="w-100"
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SearchConfirmBidModal;
