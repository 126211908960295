import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import eyeCrossedIcon from '../../assets/img/icons/eye-crossed.svg';
import eyeIcon from '../../assets/img/icons/eye.svg';

const CommonInput = ({
  onChange,
  value,
  className,
  placeHolder,
  disabled,
  name,
  icon,
  iconClassName,
  type,
  showPasswordIcon,
  invalid,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div className={icon ? 'icon-form-input-field-wrapper' : ''}>
      {icon && (
        <div className={iconClassName || 'icon-form-input-field-image-wrapper'}>
          <img src={icon} alt="Input icon" />
        </div>
      )}
      <Input
        placeholder={placeHolder}
        disabled={disabled}
        onChange={onChange}
        value={value}
        className={`${className} ${icon && !iconClassName ? 'icon-form-input-field' : ''}`}
        name={name}
        type={type === 'password' && isPasswordVisible ? 'text' : type}
        invalid={invalid}
      />
      {type === 'password' && showPasswordIcon && (
        <div
          className={`icon-form-input-password-field-image-wrapper ${
            isPasswordVisible ? 'icon-password-visible' : ''
          }`}
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            src={isPasswordVisible ? eyeCrossedIcon : eyeIcon}
            onClick={() => {
              setIsPasswordVisible(prevState => !prevState);
            }}
            alt="Password input icon"
          />
        </div>
      )}
    </div>
  );
};

CommonInput.defaultProps = {
  value: '',
  className: '',
  placeHolder: '',
  disabled: false,
  name: null,
  icon: null,
  iconClassName: '',
  showPasswordIcon: false,
  type: 'text',
  invalid: false,
};

CommonInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  type: PropTypes.string,
  showPasswordIcon: PropTypes.bool,
  invalid: PropTypes.bool,
};

export default CommonInput;
