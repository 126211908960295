import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { debounce } from 'lodash';

import magnifierIcon from 'assets/img/icons/magnifier.svg';
import CommonInput from '../common/components/CommonInput';

const MyVehicleSearch = ({ handleSearch }) => {
  const [value, setValue] = useState('');
  const debounceSearch = useCallback(
    debounce(searchValue => {
      handleSearch(searchValue);
    }, 1000),
    [],
  );

  const handleChange = e => {
    setValue(e.target.value);
    debounceSearch(e.target.value);
  };

  return (
    <>
      <FormGroup className="m-0">
        <CommonInput
          onChange={handleChange}
          placeHolder="Search"
          icon={magnifierIcon}
          iconClassName="search-icon"
          value={value}
        />
      </FormGroup>
    </>
  );
};

MyVehicleSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default MyVehicleSearch;
