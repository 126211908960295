import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import FormInputField, {
  FormInputFieldType,
} from '../../common/components/formFields/FormInputField';
import {
  fetchDescriptionTemplate,
  getDraft,
  saveDescriptionAndSettingsDraft,
} from '../AddVehicleActions';
import { checkIsFieldEmpty } from '../../common/helpers/checkIsFieldEmpty';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';

const AddDescriptionForm = ({ form, prevForm }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const descriptionAndSettings = useSelector(state =>
    state.addVehicle.get('descriptionAndSettings'),
  );

  const descriptionTemplate = useSelector(state =>
    state.addVehicle.get('descriptionTemplate'),
  );
  const id = useSelector(state => state.addVehicle.get('id'));
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    const checkIfDisableNextButton = () => {
      const { description } = form?.values;
      checkIsFieldEmpty([description], isDisabled => setIsNextDisabled(isDisabled));
    };
    if (form.values) {
      checkIfDisableNextButton();
    }
  }, [form]);

  const templateDescription =
    descriptionTemplate &&
    descriptionTemplate.map(item => {
      return {
        value: item?.id,
        label: item?.template,
      };
    });

  useEffect(() => {
    dispatch(fetchDescriptionTemplate());
  }, [dispatch]);

  const handleSaveDraft = () => {
    const { description } = form?.values;

    const data = {
      ...descriptionAndSettings,
      description,
    };

    dispatch(saveDescriptionAndSettingsDraft(data, () => dispatch(getDraft(id))));
    form.setErrors({});
  };

  return (
    <>
      <AddVehicleHeader
        nextButtonHandler={() => {
          form.handleSubmit();
        }}
        backButtonHandler={prevForm}
        saveDraftButtonHandler={handleSaveDraft}
        nextButtonDisabled={isLoading || isNextDisabled}
        saveDraftButtonDisabled={isLoading}
      />
      <AddVehicleContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">Description</h5>
            <p className="text-sm mt-2">
              Add an accurate detailed description of the vehicle and its condition.
            </p>
          </Col>
        </Row>
        <Form autoComplete="off" className="text-left">
          <Row className="mt-4">
            <Col>
              <FormSelectField
                name="templateDescription"
                label="Select A Description Template"
                options={[...templateDescription]}
                classNames="example-description-select"
                setRef={setRef}
                onChangeHandler={option => {
                  form.setFieldValue(
                    'description',
                    `${form?.values?.description ?? ''}${option?.label} `,
                  );
                  ref.focus();
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <FormInputField
                label="Now add your own wording to describe the vehicle condition in more detail"
                type={FormInputFieldType.TEXTAREA}
                id="add-description__user-description"
                name="description"
                setRef={setRef}
                required
              />
            </Col>
          </Row>
        </Form>
      </AddVehicleContentWrapper>
    </>
  );
};

AddDescriptionForm.propTypes = {
  form: PropTypes.object.isRequired,
  prevForm: PropTypes.func.isRequired,
};

export default AddDescriptionForm;
