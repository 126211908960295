import React from 'react';
import { Col, FormGroup, Label, Row, CustomFileInput } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormInputFieldType } from './FormInputField';

const FormInputFileFieldInner = props => {
  const { label, placeholder, form, field } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || '';

  return (
    <Row>
      <Col>
        <Label>{label}</Label>
        <FormGroup>
          <CustomFileInput
            id={field.name}
            placeholder={placeholder}
            type="file"
            value={value}
            disabled={form.isSubmitting}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onChange={({ target }) => form.setFieldValue(field.name, target.value)}
          />
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: 'red ' }}>{error}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormInputFileField = props => {
  return <Field {...props} component={FormInputFileFieldInner} />;
};

FormInputFileField.defaultProps = {
  type: FormInputFieldType.TEXT,
  placeholder: '',
};

FormInputFileField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FormInputFileField;
