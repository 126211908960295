import React from 'react';
import Slider from 'react-slick'; // https://www.npmjs.com/package/react-slick
import PropTypes from 'prop-types';

const defaultSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const SlickCarousel = ({ items, settings, slickRef }) => {
  const sliderSettings = {
    ...defaultSettings,
    ...settings,
  };

  return (
    // eslint-disable-next-line no-return-assign
    <Slider ref={slider => (slickRef.current = slider)} {...sliderSettings}>
      {items}
    </Slider>
  );
};

SlickCarousel.defaultProps = {
  settings: {},
  ref: {},
};

SlickCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  settings: PropTypes.object,
  ref: PropTypes.object,
};

export default SlickCarousel;
