import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import RouterPaths from 'constants/RouterPaths';
import FormInputField from '../../common/components/formFields/FormInputField';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import FormCheckboxField from '../../common/components/formFields/FormCheckboxField';
import { validateFormField } from '../../common/helpers/validateFormField';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { FormFieldTypes } from '../../constants/FormFieldTypes';
import { DealerPositionName } from '../../constants/DealerPosition';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { validateMainProfile } from '../RegisterActions';
import Footer from '../Footer';
import { scrollTop } from '../../common/helpers/generalHelper';

const dealerRole = constantToSelect(DealerPositionName);

const AddProfileForm = ({ prevStep, nextStep, values }) => {
  const dispatch = useDispatch();
  const [isFieldEmpty, setFieldEmpty] = useState(true);
  const validateErrors = useSelector(state => state.register.get('validateErrors'));

  useEffect(() => {
    scrollTop();
  }, []);

  const checkIsFieldEmpty = () => {
    const {
      firstName,
      lastName,
      email,
      position,
      password,
      passwordRepeat,
      acceptTos,
    } = values;
    const valuesArray = [
      firstName,
      lastName,
      email,
      position,
      password,
      passwordRepeat,
      acceptTos,
    ];
    const isFieldEmpty = valuesArray.some(item => !item);
    return setFieldEmpty(isFieldEmpty);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkIsFieldEmpty(), [values]);
  const [errors, setErrors] = useState({
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    positionError: '',
    passwordError: '',
    passwordRepeatError: '',
    acceptTosError: '',
  });

  const validateForm = () =>
    new Promise((resolve, reject) => {
      const {
        firstName,
        lastName,
        email,
        position,
        password,
        passwordRepeat,
        acceptTos,
      } = values;

      const errors = {
        firstNameError: validateFormField(firstName, FormFieldTypes.STRING, true, 50),
        lastNameError: validateFormField(lastName, FormFieldTypes.STRING, true, 50),
        emailError: validateFormField(email, FormFieldTypes.EMAIL, true, 50),
        positionError: validateFormField(position, FormFieldTypes.ENUM, true),
        passwordError: validateFormField(
          password,
          FormFieldTypes.PASSWORD,
          true,
          null,
          6,
          passwordRepeat,
        ),
        passwordRepeatError: validateFormField(
          passwordRepeat,
          FormFieldTypes.PASSWORD,
          true,
          null,
          6,
          password,
        ),
        acceptTosError: validateFormField(acceptTos, FormFieldTypes.BOOLEAN, true),
      };
      setErrors(errors);
      const {
        firstNameError,
        lastNameError,
        emailError,
        positionError,
        passwordError,
        passwordRepeatError,
        acceptTosError,
      } = errors;
      if (
        firstNameError ||
        lastNameError ||
        emailError ||
        positionError ||
        passwordError ||
        passwordRepeatError ||
        acceptTosError
      ) {
        reject();
      }
      resolve();
    });

  const moveToNextStep = () => {
    validateForm()
      .then(() => dispatch(validateMainProfile(values, () => nextStep())))
      .catch(() => {});
  };
  const {
    firstNameError,
    lastNameError,
    emailError,
    positionError,
    passwordError,
    passwordRepeatError,
    acceptTosError,
  } = errors;
  return (
    <Col
      md="12"
      className="register-form-container d-flex flex-column justify-content-center align-items-center"
    >
      <Row md={12} className="register-form-container__title w-100">
        <Col md={12}>
          <h2 className="font-weight-bold">Add personal details</h2>
          <p className="mt-2">Enter your personal and account details.</p>
        </Col>
      </Row>
      <Row md={12} className="register-form-container__content justify-content-center w-100">
        <Form autoComplete="off" className="register-form__profile-details">
          <Row className="justify-content-between">
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="firstName"
                  label="First name"
                  invalid={!!firstNameError}
                  required
                  capitalize
                />
                <CommonErrorLabel value={firstNameError} />
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="lastName"
                  label="Last name"
                  invalid={!!lastNameError}
                  required
                  capitalize
                />
                <CommonErrorLabel value={lastNameError} />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md="6">
              <div className="p-3">
                <FormSelectField
                  name="position"
                  label="Choose a position"
                  options={dealerRole}
                  required
                />
                <CommonErrorLabel value={positionError} />
                <FormSelectField rowClassNames="form-select-field-hidden" />
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="email"
                  label="E-mail address"
                  invalid={!!emailError || validateErrors?.email}
                  required
                />
                <CommonErrorLabel value={emailError || validateErrors?.email} />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  type="password"
                  name="password"
                  label="Password"
                  invalid={!!passwordError}
                  required
                />
                <CommonErrorLabel value={passwordError} />
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  type="password"
                  name="passwordRepeat"
                  label="Repeat password"
                  invalid={!!passwordRepeatError}
                  required
                />
                <CommonErrorLabel value={passwordRepeatError} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="p-3 w-100">
                <FormCheckboxField
                  label={
                    <label className="label-required">
                      I accept the{' '}
                      <a
                        href={RouterPaths.PRIVACY_POLICY}
                        className="color-green"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>{' '}
                      and{' '}
                      <a
                        href={RouterPaths.TERMS_AND_CONDITIONS}
                        className="color-green"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>
                    </label>
                  }
                  name="acceptTos"
                  id="acceptTos"
                />
                <CommonErrorLabel value={acceptTosError} />
                <FormCheckboxField
                  label="Sign me for newsletter"
                  name="acceptNewsletter"
                  id="acceptNewsletter"
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Row>
      <Footer prev={prevStep} next={moveToNextStep} nextButtonDisabled={isFieldEmpty} />
    </Col>
  );
};

AddProfileForm.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default AddProfileForm;
