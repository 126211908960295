import moment from 'moment';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mmA';
export const REVERSE_DASH_DATE_FORMAT = 'YYYY-MM-DD';

export function dateToString(date) {
  return date ? moment(date).format(DATE_FORMAT) : '';
}

export function dateTimeToString(date) {
  return date ? moment(date).format(DATE_TIME_FORMAT) : '';
}

export function reverseDateToString(date) {
  return date ? moment(date).format(REVERSE_DASH_DATE_FORMAT) : '';
}

export function isDateValid(dateTime) {
  return moment(dateTime, DATE_FORMAT, true).isValid();
}

export function isDateBefore(firstDate, secondDate, guaranty = null) {
  const newFirstDate = new Date(firstDate);
  const newSecondDate = new Date(secondDate);

  return moment(newFirstDate).isBefore(newSecondDate, guaranty);
}

export function isDateAfter(firstDate, secondDate) {
  const newFirstDate = new Date(firstDate);
  const newSecondDate = new Date(secondDate);

  return moment(newFirstDate).isAfter(newSecondDate);
}

export const timerFromSeconds = seconds => {
  return `${showDays(seconds)}${moment.utc(seconds * 1000).format(timeFormat(seconds))}`;
};

/**
 * @param timeParts
 * @param showEmpty - object { minutes: true, hours: true etc.. }
 * @returns {string}
 */
export const timePartsToString = (timeParts, showEmpty = null) => {
  let result = '';

  if (showEmpty?.days || (!showEmpty?.days && timeParts?.days)) {
    result += `${timeParts?.days || '00'}d:`;
  }

  if (
    showEmpty?.hours ||
    (!showEmpty?.hours && timeParts?.hours) ||
    (!showEmpty?.hours && timeParts?.days)
  ) {
    result += `${timeParts?.hours < 10 ? `0${timeParts?.hours}` : timeParts?.hours || '00'}h:`;
  }

  if (
    showEmpty?.minutes ||
    (!showEmpty?.minutes && timeParts?.minutes) ||
    (!showEmpty?.minutes && (timeParts?.hours || timeParts?.days))
  ) {
    result += `${
      timeParts?.minutes < 10 ? `0${timeParts?.minutes}` : timeParts?.minutes || '00'
    }m:`;
  }

  if (
    showEmpty?.seconds ||
    (!showEmpty?.seconds && timeParts?.seconds) ||
    (!showEmpty?.seconds && (timeParts?.minutes || timeParts?.hours || timeParts?.days))
  ) {
    result += `${
      timeParts?.seconds < 10 ? `0${timeParts?.seconds}` : timeParts?.seconds || '00'
    }s`;
  }

  return result;
};

export const formatTimeZero = value => {
  return value < 10 ? `0${value}` : value;
};

export const getTimeParts = milliseconds => {
  if (milliseconds > 0) {
    const duration = moment.duration(milliseconds);

    const days = Math.floor(duration.asDays());
    duration.subtract(days, 'days');

    const hours = Math.floor(duration.asHours());
    duration.subtract(hours, 'hours');

    const minutes = Math.floor(duration.asMinutes());
    duration.subtract(minutes, 'minutes');

    const seconds = Math.floor(duration.asSeconds());

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }
  return {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
};

const showDays = seconds => {
  if (seconds > 86400) {
    return `${Math.floor(seconds / 86400)}d `;
  }
  return '';
};
const timeFormat = seconds => {
  if (seconds < 3600) {
    return 'mm:ss';
  }
  if (seconds < 86400) {
    return 'H:mm:ss';
  }
  return 'H:mm';
};
