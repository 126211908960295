import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../AuthActions';
import CommonButton from '../../common/components/CommonButton';
import validationSchema from './ForgotPasswordPage.schema';
import FormInputField from '../../common/components/formFields/FormInputField';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import RouterPaths from '../../constants/RouterPaths';
import { setSearchQuery } from '../../search/SearchActions';
import logo from '../../assets/img/logo-new.png';

const { DISABLED, PRIMARY } = CommonButtonVariants;

const initialValues = {
  email: '',
};

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();

  const handleSubmit = ({ email }) => {
    dispatch(forgotPassword({ email }));
  };

  return (
    <Row className="auth-page m-0 p-0">
      <Col className="auth-page__left-column" lg={3} md={3} sm={12}>
        <Link
          to={RouterPaths.HOMEPAGE}
          onClick={() => dispatch(setSearchQuery(''))}
          className="page-logo-link"
        >
          <img src={logo} alt="logo" />
        </Link>
        <h2 className="auth-page__big-title font-weight-bold auth-page__hide-on-mobile">
          Buy & Sell trade vehicles from the UK&apos;s most trusted dealers.
        </h2>
      </Col>
      <Col className="auth-page__right-column ml-auto mr-auto" lg={5} md={9} sm={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {form => {
            return (
              <Form className="forgot-password-form">
                <h3 className="font-weight-bold text-center">Forgot your password</h3>
                <FormInputField name="email" label="Email" placeholder="Email" />
                <CommonButton
                  type="submit"
                  label="Reset password"
                  className="forgot-password-form__submit-button"
                  disabled={!form?.values?.email}
                  variant={!form?.values?.email ? DISABLED : PRIMARY}
                />
                <p className="mt-4 text-center font-weight-bold">
                  Don&apos;t have an account?
                  <Link className="color-green" to={RouterPaths.REGISTER}>
                    {' '}
                    Register Now
                  </Link>
                </p>
              </Form>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};

export default ForgotPasswordPage;
