import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { login } from './AuthActions';
import RouterPaths from '../constants/RouterPaths';
import CommonButton from '../common/components/CommonButton';
import FormInputField, {
  FormInputFieldType,
} from '../common/components/formFields/FormInputField';
import validationSchema from './Login.schema';
import { CommonButtonVariants } from '../constants/CommonButtonVariants';
import logo from '../assets/img/logo-new.png';
import { FormCheckboxField } from '../common/components/formFields';

const { DISABLED, PRIMARY } = CommonButtonVariants;

const initialValues = {
  email: '',
  password: '',
};

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));

  useEffect(() => {
    if (isAuthenticated) {
      history.push(RouterPaths.HOMEPAGE);
    }
  }, [isAuthenticated, history]);

  const handleSubmit = ({ email, password }, { setSubmitting }) => {
    dispatch(login(email, password));
    setSubmitting(false);
  };

  return (
    <Row className="auth-page m-0 p-0">
      <Col className="auth-page__left-column" md={3} sm={12}>
        <Link to={RouterPaths.HOMEPAGE} className="page-logo-link">
          <img src={logo} alt="logo" />
        </Link>
        <h2 className="auth-page__big-title font-weight-bold auth-page__hide-on-mobile">
          Buy & Sell trade vehicles from the UK&apos;s most trusted dealers.
        </h2>
      </Col>
      <Col className="auth-page__right-column" md={9} sm={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {() => (
            <Form className="login-form">
              <h3 className="font-weight-bold float-left">Login to your account</h3>
              <p className="mb-5">
                Enter your details to log into your account and browse our exclusive car
                auctions.
              </p>
              <FormInputField name="email" label="Email" placeholder="Email" />
              <FormInputField
                type={FormInputFieldType.PASSWORD}
                name="password"
                label="Password"
                placeholder="Password"
              />
              <Row className="text-center align-items-center login-form__options">
                <FormCheckboxField
                  className="pl-2"
                  label="Remember for 30 days"
                  placeholder="Email"
                />
                <Link
                  className="login-form__forgot-password-link mt-2 color-green ml-auto"
                  to={RouterPaths.FORGOT_PASSWORD}
                >
                  Forgot your password?
                </Link>
              </Row>
              <CommonButton
                type="submit"
                label="Login"
                className="login-form__submit-button mt-5"
                disabled={isLoading}
                variant={isLoading ? DISABLED : PRIMARY}
              />
              <p className="mt-4 text-center font-weight-bold">
                Don&apos;t have an account?
                <Link className="color-green" to={RouterPaths.REGISTER}>
                  {' '}
                  Register Now
                </Link>
              </p>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default withRouter(Login);
