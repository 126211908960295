import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Col, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import {
  clearStripeValidationMessage,
  getStripeClientSecret,
  register,
} from '../RegisterActions';
import FormInputField from '../../common/components/formFields/FormInputField';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { DealerRole } from '../../constants/DealerRole';
import { StripeValidationErrorType } from '../../constants/StripeValidationError';
import Footer from '../Footer';
import { getCardElementOptions } from '../../constants/CardElementOptions';

import creditCardIcon from '../../assets/img/icons/credit-card.svg';
import keyIcon from '../../assets/img/icons/key.svg';
import calendarIcon from '../../assets/img/icons/calendar.svg';
import { scrollTop } from '../../common/helpers/generalHelper';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddPaymentDetailsFormInner = ({ values, nextStep, prevStep }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  useEffect(() => {
    scrollTop();
  }, []);

  const [isOn, setSwitch] = useState(true);

  const isFranchisedDealer = values?.organizationRole === DealerRole.FRANCHISED_DEALER;
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));

  const stripeValidationMessage = useSelector(state =>
    state.register.get('stripeValidationMessage'),
  );

  const [errors, setErrors] = useState({
    cardholderError: '',
    cvcCodeError: '',
    cardNumberError: '',
    expiryError: '',
  });

  const hasAnyError = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in errors) {
      if (errors[key]) {
        return true;
      }
    }

    return isOn && !values?.cardholder;
  };

  const handleInputChange = (name, error) => {
    setErrors({ ...errors, [`${name}Error`]: error ? error.message : '' });
  };

  const isSubmittingDisabled = isLoading || (isOn && hasAnyError());

  const validateForm = () =>
    new Promise((resolve, reject) => {
      const { cardholder } = values;
      const updatedErrors = {
        ...errors,
        cardholderError: cardholder ? '' : 'Field is required',
      };

      setErrors(updatedErrors);
      const { cardholderError, cvcCodeError, cardNumberError, expiryError } = updatedErrors;
      if (cardholderError || cvcCodeError || cardNumberError || expiryError) {
        reject();
      }
      resolve();
    });

  const handleSubmit = async e => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    try {
      if (isFranchisedDealer && isOn) {
        await validateForm();
      }
      dispatch(
        getStripeClientSecret(values, stripe, elements, stripeCustomerId =>
          dispatch(register(values, () => nextStep(), stripeCustomerId)),
        ),
      );
    } catch {
      console.error(StripeValidationErrorType.VALIDATION_ERROR);
    }
  };

  return (
    <Col className="register-form-container d-flex flex-column justify-content-center align-items-center">
      <Row md={12} className="register-form-container__title w-100">
        <Col md={12}>
          <h2 className="font-weight-bold">Add payment details</h2>
          <p className="mt-1">
            <span className="font-weight-bold">No subscription</span> payments will be taken
            for the first 3 months while you enjoy your Free Trial. Payments for successful
            transactions will be debited on a pay as you go basis, please see fee table below
          </p>
        </Col>
      </Row>
      <Row md={12} className="register-form-container__content justify-content-center w-100">
        <div className="register-form__payment-details">
          <Row>
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="cardholder"
                  id="cardholder"
                  placeholder="Cardholder's name"
                  label="Card Holder"
                  disabled={!isOn}
                  required={isOn}
                  capitalize
                />
                {errors.cardholderError && <CommonErrorLabel value={errors.cardholderError} />}
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <Label
                  className={`form-input-field-label ${
                    !isOn ? 'form-input-field-label__disabled' : 'label-required'
                  }`}
                >
                  Credit Card Number
                </Label>
                <div
                  className={`form-control card-element-wrapper mb-2 position-relative ${
                    !isOn ? 'form-control--disabled' : ''
                  }`}
                >
                  <CardNumberElement
                    elements={elements}
                    options={getCardElementOptions(!isOn)}
                    onChange={({ error }) => handleInputChange('cardNumber', error)}
                    id="credit-card-container"
                  />
                  <img
                    src={creditCardIcon}
                    className="input-icon input-right-icon"
                    alt="card number"
                  />
                </div>
                {stripeValidationMessage ? (
                  <CommonErrorLabel value={stripeValidationMessage} />
                ) : (
                  ''
                )}
                <CommonErrorLabel value={errors?.cardNumberError || ''} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="p-3">
                <Label
                  className={`form-input-field-label ${
                    !isOn ? 'form-input-field-label__disabled' : 'label-required'
                  }`}
                >
                  Expiration Month and Year
                </Label>
                <div
                  className={`form-control card-element-wrapper mb-2 position-relative ${
                    !isOn ? 'form-control--disabled' : ''
                  }`}
                >
                  <CardExpiryElement
                    elements={elements}
                    options={getCardElementOptions(!isOn)}
                    onChange={({ error }) => handleInputChange('expiry', error)}
                    id="credit-card-container"
                  />
                  <img
                    src={calendarIcon}
                    className="input-icon input-right-icon"
                    alt="card exp date"
                  />
                </div>
                <CommonErrorLabel value={errors?.expiryError || ''} />
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <Label
                  className={`form-input-field-label ${
                    !isOn ? 'form-input-field-label__disabled' : 'label-required'
                  }`}
                >
                  CVC Code
                </Label>
                <div
                  className={`form-control card-element-wrapper mb-2 position-relative ${
                    !isOn ? 'form-control--disabled' : ''
                  }`}
                >
                  <CardCvcElement
                    elements={elements}
                    options={getCardElementOptions(!isOn)}
                    onChange={({ error }) => handleInputChange('cvcCode', error)}
                    id="credit-card-container"
                  />
                  <img
                    src={keyIcon}
                    className="input-icon input-right-icon"
                    alt="card cvc number"
                  />
                </div>
                <CommonErrorLabel value={errors?.cvcCodeError || ''} />
              </div>
            </Col>
          </Row>
          <Row className="payment-details__table">
            <Col md="6">
              <div className="p-3">
                <p>
                  <strong>Sellers Fees:</strong>
                </p>
                <table className="price-table">
                  <tbody>
                    <tr>
                      <td>
                        <p className="price__cell-text">£0 - £5000</p>
                      </td>
                      <td>
                        <p className="price__cell-text">
                          <strong>£35.00 + VAT</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="price__cell-text">£5,001 - £15,000</p>
                      </td>
                      <td>
                        <p className="price__cell-text">
                          <strong>£50.00 + VAT</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="price__cell-text">£15,001 +</p>
                      </td>
                      <td>
                        <p className="price__cell-text">
                          <strong>£75.00 + VAT</strong>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <p>
                  <strong>Buyers Fees:</strong>
                </p>
                <table className="price-table">
                  <tbody>
                    <tr>
                      <td>
                        <p className="price__cell-text">£0 - £5000</p>
                      </td>
                      <td>
                        <p className="price__cell-text">
                          <strong>£35.00 + VAT</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="price__cell-text">£5,001 - £15,000</p>
                      </td>
                      <td>
                        <p className="price__cell-text">
                          <strong>£50.00 + VAT</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="price__cell-text">£15,001 +</p>
                      </td>
                      <td>
                        <p className="price__cell-text">
                          <strong>£75.00 + VAT</strong>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
      <Footer
        prev={prevStep}
        next={handleSubmit}
        skip={() => setSwitch(!isOn)}
        skipButton={isFranchisedDealer}
        nextLabel="Register Account"
        nextButtonVariant={CommonButtonVariants.SUCCESS}
        nextButtonClassName="register-account-button"
        nextButtonDisabled={isSubmittingDisabled}
      />
    </Col>
  );
};

const AddPaymentDetailsForm = ({ values, nextStep, prevStep }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(clearStripeValidationMessage());
  }, [dispatch]);
  return (
    <Elements stripe={stripePromise}>
      <AddPaymentDetailsFormInner nextStep={nextStep} prevStep={prevStep} values={values} />
    </Elements>
  );
};

AddPaymentDetailsForm.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default withRouter(AddPaymentDetailsForm);
