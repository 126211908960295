import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import {
  fetchMemberNotification,
  updateMemberNotification,
} from '../myprofile/MyProfileActions';
import CommonMyOrganizationContainer from '../../common/components/CommonMyOrganizationContainer';

import { NotificationConfig } from './NotificationConfig';
import {
  getSearchAlertNotifications,
  getSystemNotifications,
  mapToUpdatePayload,
} from './Utils';
import SettingsHeader from '../SettingsHeader';
import { AccountLayoutContentWrapper } from '../../common/components/AccountLayoutWrapper';
// eslint-disable-next-line import/no-named-as-default
import CommonToggleSwitch from '../../common/components/CommonToggleSwitch';

const NotificationsPage = () => {
  const dispatch = useDispatch();
  const [systemNotifications, setSystemNotification] = useState(
    new NotificationConfig(false, false),
  );
  const [searchAlertNotification, setSearchAlertNotification] = useState(
    new NotificationConfig(false, false),
  );

  const updateNotifications = (systemNotifications, searchAlertNotification) => {
    dispatch(
      updateMemberNotification(
        mapToUpdatePayload(systemNotifications, searchAlertNotification),
      ),
    )
      .then(config => setConfig(config))
      .catch(() => {
        dispatch(fetchMemberNotification()).then(config => setConfig(config));
      });
  };

  const setConfig = config => {
    if (!config) return;
    const { emailNotifications, pushNotifications } = config;
    setSystemNotification(
      new NotificationConfig(
        getSystemNotifications(emailNotifications),
        getSystemNotifications(pushNotifications),
      ),
    );
    setSearchAlertNotification(
      new NotificationConfig(
        getSearchAlertNotifications(emailNotifications),
        getSearchAlertNotifications(pushNotifications),
      ),
    );
  };

  useEffect(() => {
    dispatch(fetchMemberNotification()).then(config => setConfig(config));
  }, [dispatch]);

  const handleOnSystemNotificationChange = config => {
    setSystemNotification(config);
    updateNotifications(config, searchAlertNotification);
  };

  const handleOnSearchAlertChange = config => {
    setSearchAlertNotification(config);
    updateNotifications(systemNotifications, config);
  };

  return (
    <CommonMyOrganizationContainer>
      <SettingsHeader />
      <AccountLayoutContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">Notifications</h5>
            <p className="text-sm mt-2">
              Please select how you would like receive system notifications.
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={4} sm={12}>
            <FormGroup check className="form-group-toggle-checkbox">
              <Label className="form-input-field-label">Push Notifications?</Label>
              <CommonToggleSwitch
                onChange={() => {
                  handleOnSystemNotificationChange(systemNotifications.pressPush());
                }}
                checked={systemNotifications.pushEnabled}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={12}>
            <FormGroup check className="form-group-toggle-checkbox">
              <Label className="form-input-field-label">Email Notifications?</Label>
              <CommonToggleSwitch
                onChange={() => {
                  handleOnSystemNotificationChange(systemNotifications.pressEmail());
                }}
                checked={systemNotifications.emailEnabled}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={4} sm={12}>
            <FormGroup check className="form-group-toggle-checkbox">
              <Label className="form-input-field-label">
                Send Alert via Push Notifications?
              </Label>
              <CommonToggleSwitch
                onChange={() => {
                  handleOnSearchAlertChange(searchAlertNotification.pressPush());
                }}
                checked={searchAlertNotification.pushEnabled}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={12}>
            <FormGroup check className="form-group-toggle-checkbox">
              <Label className="form-input-field-label">
                Send Alert via Email Notifications?
              </Label>
              <CommonToggleSwitch
                onChange={() => {
                  handleOnSearchAlertChange(searchAlertNotification.pressEmail());
                }}
                checked={searchAlertNotification.emailEnabled}
              />
            </FormGroup>
          </Col>
        </Row>
      </AccountLayoutContentWrapper>
    </CommonMyOrganizationContainer>
  );
};
export default withRouter(NotificationsPage);
