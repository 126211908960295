import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'formik';
import { Col, Row } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormInputField from '../../common/components/formFields/FormInputField';
import { clearValidationErrors, validateOrganization } from '../RegisterActions';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import { validateFormField } from '../../common/helpers/validateFormField';
import { FormFieldTypes } from '../../constants/FormFieldTypes';
import Footer from '../Footer';
import { scrollTop } from '../../common/helpers/generalHelper';

const OrganizationDetailsForm = ({ prevStep, nextStep, values }) => {
  const dispatch = useDispatch();
  const validateErrors = useSelector(state => state.register.get('validateErrors'));

  useEffect(() => {
    scrollTop();
  }, []);

  const [errors, setErrors] = useState({
    tradeNameError: '',
    companyRegisterNumberError: '',
    vatNumberError: '',
  });

  useEffect(() => {
    return () => {
      dispatch(clearValidationErrors());
    };
  }, [dispatch]);

  const valueVatNumber = useMemo(() => values.vatNumber, [values.vatNumber]);

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      const { officialCompanyName, tradeName, vatNumber } = values;

      const errors = {
        officialCompanyNameError: validateFormField(
          officialCompanyName,
          FormFieldTypes.NAME,
          false,
          50,
        ),
        tradeNameError: validateFormField(tradeName, FormFieldTypes.NAME, false, 50),
        vatNumberError: validateFormField(vatNumber, FormFieldTypes.NUMBER, true, 12, 9),
      };
      setErrors(errors);

      const { tradeNameError, companyRegisterNumberError, vatNumberError } = errors;
      if (tradeNameError || companyRegisterNumberError || vatNumberError) {
        reject();
      }
      resolve();
    });
  };

  const moveToNextStep = () => {
    validateForm()
      .then(() => dispatch(validateOrganization(values, () => nextStep())))
      .catch(() => {});
  };

  const { officialCompanyNameError, tradeNameError, vatNumberError } = errors;

  return (
    <Col
      md="12"
      className="register-form-container d-flex flex-column justify-content-center align-items-cente"
    >
      <Row md={12} className="register-form-container__title w-100">
        <Col md={12}>
          <h2 className="font-weight-bold">Add company details</h2>
          <p className="mt-1">Enter you company details</p>
        </Col>
      </Row>
      <Row md={12} className="register-form-container__content justify-content-center w-100">
        <Form autoComplete="off" className="register-form__company-details">
          <Row className="justify-content-between">
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="officialCompanyName"
                  label="Company Name"
                  invalid={!!officialCompanyNameError}
                  capitalize
                />
                <CommonErrorLabel value={officialCompanyNameError} />
              </div>
            </Col>
            <Col md="6">
              <div className="p-3">
                <FormInputField
                  name="vatNumber"
                  label="VAT Number"
                  invalid={!!vatNumberError || validateErrors?.addOrganizationAndRoleForm}
                  required
                />
                <CommonErrorLabel
                  value={vatNumberError || validateErrors?.addOrganizationAndRoleForm}
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md="12 d-flex justify-content-center ">
              <div className="p-3 w-100">
                <FormInputField
                  name="tradeName"
                  label="Trade Name"
                  invalid={!!tradeNameError}
                  capitalize
                />
                <CommonErrorLabel value={tradeNameError} />
              </div>
            </Col>
          </Row>
        </Form>
      </Row>
      <Footer prev={prevStep} next={moveToNextStep} nextButtonDisabled={!valueVatNumber} />
    </Col>
  );
};

OrganizationDetailsForm.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default OrganizationDetailsForm;
