import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

const AuctionSellingPersonInformation = ({ item }) => {
  const { organizationContact } = item?.seller;

  return (
    <>
      <Row
        className="main-description-container__section-title mt-4"
        id="vehicle-sellers-information"
      >
        <Col md={12} className="p-0 pb-2 text-lg">
          Seller&apos;s Information
        </Col>
      </Row>
      <Row className="mt-3 summary__selling-person-list text-base">
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Company Name</p>
          <p className="m-0 fw-600">{organizationContact?.tradeName || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Email</p>
          <p className="m-0 fw-600">{organizationContact?.email || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Mobile</p>
          <p className="m-0 fw-600">{organizationContact?.landlinePhoneNumber || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Address - Line 1</p>
          <p className="m-0 fw-600">{organizationContact?.location?.address || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Address - Line 2</p>
          <p className="m-0 fw-600">{organizationContact?.location?.address2 || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">City</p>
          <p className="m-0 fw-600">{organizationContact?.location?.city || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">County</p>
          <p className="m-0 fw-600">{organizationContact?.location?.county || '-'}</p>
        </Col>
        <Col lg={4} md={4} sm={4} xs={12} className="summary__list-item">
          <p className="m-0">Post Code</p>
          <p className="m-0 fw-600">
            {organizationContact?.location?.postalCode?.postcode || '-'}
          </p>
        </Col>
      </Row>
    </>
  );
};

AuctionSellingPersonInformation.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AuctionSellingPersonInformation;
