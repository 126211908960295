import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const FormCurrencyInputFieldInner = props => {
  const {
    label,
    placeholder,
    form,
    field,
    disabled,
    invalid,
    maxLength,
    className,
    inputClassName,
    required,
  } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || '';

  return (
    <Row className={className}>
      <Col>
        <Label
          className={`form-currency-input-field-label ${required ? 'label-required' : ''}`}
        >
          {label}
        </Label>
        <FormGroup>
          <CurrencyFormat
            className={inputClassName}
            thousandSeparator={true}
            allowNegative={false}
            prefix="£"
            invalid={invalid || (error && touched)}
            customInput={Input}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            disabled={form.isSubmitting || disabled}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onChange={({ target }) => {
              form.setFieldValue(field.name, target.value.replace(/[^0-9\\.]+/g, ''));
            }}
          />
          {(form.submitCount > 0 || touched) && error && (
            <label className="form-input-field__label">{error}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormCurrencyInputField = props => {
  return <Field {...props} component={FormCurrencyInputFieldInner} />;
};

FormCurrencyInputField.defaultProps = {
  placeholder: '',
  disabled: false,
  label: '',
  className: '',
  inputClassName: '',
  invalid: '',
  maxLength: null,
  required: false,
};

FormCurrencyInputField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxLength: PropTypes.number,
  required: PropTypes.bool,
};

export default FormCurrencyInputField;
