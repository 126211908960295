import React from 'react';
import PropTypes from 'prop-types';
import CommonEditIconButton from '../../../common/components/CommonEditIconButton';
import CommonDeleteIconButton from '../../../common/components/CommonDeleteIconButton';
import copyIcon from '../../../assets/img/icons/copy.svg';
import CommonIconButton from '../../../common/components/CommonIconButton';

const SearchAlertsTableRow = ({
  item,
  setSearchAlertToDelete,
  copyItemHandler,
  editItemHandler,
}) => {
  return (
    <>
      <td className="font-weight-bold">{item?.vehicleMakeName || 'All Makes'}</td>
      <td className="font-weight-bold">{item?.vehicleModelName || 'All Models'}</td>
      <td>
        {!item?.yearFrom && !item?.yearTo
          ? 'All'
          : `${item?.yearFrom || 'Any'} - ${item?.yearTo || 'Any'}`}
      </td>
      <td>{item?.mileageMaxValue ? `${item?.mileageMaxValue} miles` : 'All'}</td>
      <td>
        {!item?.distanceMaxValueInMiles
          ? 'National'
          : `${item?.distanceMaxValueInMiles} miles`}
      </td>
      <td className="common-table__action-cell">
        <CommonEditIconButton
          className="employees-table-row__action-button"
          handleClick={() => editItemHandler(item.id)}
        />
        <CommonIconButton
          icon={copyIcon}
          className="employees-table-row__action-button"
          handleClick={() => copyItemHandler(item)}
        />
        <CommonDeleteIconButton
          className="employees-table-row__action-button"
          handleClick={() => setSearchAlertToDelete(item.id)}
        />
      </td>
    </>
  );
};

SearchAlertsTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default SearchAlertsTableRow;
