export const getTitle = vehicle => {
  return (
    vehicle?.vehicle?.vehicleModel &&
    `${vehicle?.vehicle?.vehicleModel?.makeName} ${vehicle?.vehicle?.vehicleModel?.name} ${vehicle?.vehicle?.derivative}`
  );
};

export const getShortTitle = vehicle => {
  return (
    vehicle?.vehicle?.vehicleModel &&
    `${vehicle?.vehicle?.vehicleModel?.makeName} ${vehicle?.vehicle?.vehicleModel?.name}`
  );
};
