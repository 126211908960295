import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import rectangleImage from '../../assets/img/AboutUs/rectangle.svg';

const StorySection = () => {
  return (
    <section className="story section">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Row className="m-auto mb-5">
              <Col sm={12} md={6} lg={6} className="pl-0">
                <h5 className="color-light-primary text-lg font-weight-bolder">OUR STORY</h5>
                <h5 className="font-weight-bold text-3xl mb-4">
                  Suscipit senectus sit felis et nec. Sed urna dui ut integer vitae.
                </h5>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <p className="color-primary fw-400 text-base">
                  Aliquet malesuada risus pulvinar euismod orci. Dictum urna est tempor
                  vulputate. Ullamcorper eu tristique tellus platea mauris sit ultrices
                  scelerisque. Eu turpis ut sed congue mattis. Egestas nunc ac integer egestas
                  a bibendum non.
                </p>
              </Col>
            </Row>
            <Row className="story__tile-wrapper">
              <Col>
                <Row>
                  <Col sm={12} md={3}>
                    <div className="story__tile">
                      <p className="text-sm text-right story__tile-text">
                        Cursus dolor laoreet elementum dolor mollis libero senectus. Venenatis
                        nunc elit et lectus in blandit. Egestas.
                      </p>
                      <p className="story__year text-right font-weight-bold text-xl">2019</p>
                      <img
                        src={rectangleImage}
                        alt="Our Story 2019"
                        className="story__tile-rectangle"
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={3}>
                    <div className="story__tile">
                      <p className="text-sm text-right">
                        Nibh justo sit in placerat ac. Magna nulla adipiscing sit quis cursus
                        dolor donec. Netus vel venenatis pretium enim enim imperdiet placerat.
                        Eros.
                      </p>
                      <p className="story__year text-right font-weight-bold text-xl">2020</p>
                      <img
                        src={rectangleImage}
                        alt="Our Story 2019"
                        className="story__tile-rectangle"
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={3}>
                    <div className="story__tile">
                      <p className="text-sm text-right">
                        Elit pharetra lorem justo facilisis ultrices. Sagittis mollis varius
                        enim tortor tincidunt sed in cursus non.
                      </p>
                      <p className="story__year text-right font-weight-bold text-xl">2021</p>
                      <img
                        src={rectangleImage}
                        alt="Our Story 2019"
                        className="story__tile-rectangle"
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={3}>
                    <div className="story__tile">
                      <p className="text-sm text-right">
                        Aliquam amet netus in vivamus nisl. Sollicitudin diam dignissim nulla
                        dictum quis nisl urna lectus ut. Leo et adipiscing dolor felis. Tempor
                        massa.
                      </p>
                      <p className="story__year text-right font-weight-bold text-xl">2022</p>
                      <img
                        src={rectangleImage}
                        alt="Our Story 2019"
                        className="story__tile-rectangle"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={3}>
                    <p className="story__timeline-year text-right font-weight-bold text-xl">
                      2019
                    </p>
                  </Col>
                  <Col sm={12} md={3}>
                    <p className="story__timeline-year text-right font-weight-bold text-xl">
                      2020
                    </p>
                  </Col>
                  <Col sm={12} md={3}>
                    <p className="story__timeline-year text-right font-weight-bold text-xl">
                      2021
                    </p>
                  </Col>
                  <Col sm={12} md={3}>
                    <p className="story__timeline-year text-right font-weight-bold text-xl">
                      2022
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="story__timeline" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default StorySection;
