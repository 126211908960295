import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DealerRole } from '../../constants/DealerRole';
import { validateOrganizationRole } from '../RegisterActions';
import Footer from '../Footer';

import fDealerIcon from '../../assets/img/franchised-dealer-icon.png';
import fDealerDarkIcon from '../../assets/img/franchised-dealer-dark-icon.png';

import iDealerIcon from '../../assets/img/independent-dealer-icon.png';
import iDealerDarkIcon from '../../assets/img/independent-dealer-dark-icon.png';

import RouterPaths from '../../constants/RouterPaths';

const DealerTypeOption = ({ type, onSelect, isSelected }) => {
  const getDealerIcon = () => {
    if (type === DealerRole.FRANCHISED_DEALER) {
      return isSelected ? fDealerDarkIcon : fDealerIcon;
    }

    return isSelected ? iDealerDarkIcon : iDealerIcon;
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`dealer-role__option ${isSelected ? 'option-selected' : ''}`}
      onClick={onSelect}
    >
      <img src={getDealerIcon()} alt={type} />
      <span className="font-weight-bold">
        {type === DealerRole.FRANCHISED_DEALER ? 'Franchised Dealer' : 'Independent Dealer'}
      </span>
    </div>
  );
};

const RoleForm = ({ values, nextStep, setFieldValue }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const valueOrganisationRole = useMemo(() => values.organizationRole, [
    values.organizationRole,
  ]);

  const onSelect = value => {
    setFieldValue('organizationRole', value);
    dispatch(validateOrganizationRole(values.organizationRole, () => nextStep()));
  };

  return (
    <Col
      md="12"
      className="register-form-container d-flex flex-column justify-content-center align-items-center"
    >
      <Row md={12} className="register-form-container__title w-100">
        <Col md="12">
          <h2 className="font-weight-bold">Select your dealer type</h2>
          <p className="mt-1">
            To get started tell us what type of account you are going to register, Franchised
            or Independent Dealer.
          </p>
        </Col>
      </Row>
      <Row md={12} className="register-form-container__content justify-content-center w-100">
        <div className="register-form__role-details">
          <Row md={12} className="justify-content-around">
            <Col md="6" sm="12">
              <DealerTypeOption
                type={DealerRole.FRANCHISED_DEALER}
                isSelected={values?.organizationRole === DealerRole.FRANCHISED_DEALER}
                onSelect={() => {
                  onSelect(DealerRole.FRANCHISED_DEALER);
                }}
              />
            </Col>
            <Col md="6" sm="12">
              <DealerTypeOption
                type={DealerRole.INDEPENDENT_DEALER}
                isSelected={values?.organizationRole === DealerRole.INDEPENDENT_DEALER}
                onSelect={() => {
                  onSelect(DealerRole.INDEPENDENT_DEALER);
                }}
              />
            </Col>
          </Row>
        </div>
      </Row>
      <Footer
        prev={() => {
          history.push(RouterPaths.LOGIN);
        }}
        prevLabel="Back to Login"
        nextButtonDisabled={!valueOrganisationRole}
      />
    </Col>
  );
};

RoleForm.defaultProps = {
  nextStep: null,
  setFieldValue: () => {},
};

RoleForm.propTypes = {
  nextStep: PropTypes.func,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func,
};

export default RoleForm;
