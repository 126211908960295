import React from 'react';
import { Col, Row } from 'reactstrap';
import leaderImage from '../../assets/img/AboutUs/leader.png';
import linkedinImage from '../../assets/img/icons/linkedin.svg';

const TeamLeadershipSection = () => {
  return (
    <section className="team-leadership section">
      {' '}
      <Row>
        <Col md={12}>
          <Row className="m-auto mb-5">
            <Col sm={12} className="pl-0">
              <h5 className="color-light-primary text-lg font-weight-bolder">
                TEAM LEADERSHIP
              </h5>
              <h5 className="font-weight-bold text-3xl mb-4">
                Tristique eget quis tortor turpis velit. At lectus dictum vel lectus habitant.
                Suspendisse fringilla ut.
              </h5>
            </Col>
          </Row>
          <Row className="team-leadership__row">
            <Col sm={12} md={3}>
              <div className="team-leadership__tile">
                <img src={leaderImage} alt="leader" className="team-leadership__tile-image" />
                <div className="team-leadership__tile-description">
                  <p className="font-weight-bold mb-1">John Doe</p>
                  <p>Founder, CEO</p>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="">
                    <img src={linkedinImage} alt="Linkedin" />
                  </a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={3}>
              <div className="team-leadership__tile">
                <img src={leaderImage} alt="leader" className="team-leadership__tile-image" />
                <div className="team-leadership__tile-description">
                  <p className="font-weight-bold mb-1">John Doe</p>
                  <p>Founder, CEO</p>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="">
                    <img src={linkedinImage} alt="Linkedin" />
                  </a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={3}>
              <div className="team-leadership__tile">
                <img src={leaderImage} alt="leader" className="team-leadership__tile-image" />
                <div className="team-leadership__tile-description">
                  <p className="font-weight-bold mb-1">John Doe</p>
                  <p>Founder, CEO</p>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="">
                    <img src={linkedinImage} alt="Linkedin" />
                  </a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={3}>
              <div className="team-leadership__tile">
                <img src={leaderImage} alt="leader" className="team-leadership__tile-image" />
                <div className="team-leadership__tile-description">
                  <p className="font-weight-bold mb-1">John Doe</p>
                  <p>Founder, CEO</p>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="">
                    <img src={linkedinImage} alt="Linkedin" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default TeamLeadershipSection;
