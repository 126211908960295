import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { setQueryString, useQueryString } from '../../common/helpers/queryString';
import { removeEmployee, searchEmployees } from '../SettingsActions';
import CommonMyOrganizationContainer from '../../common/components/CommonMyOrganizationContainer';
import CommonButton from '../../common/components/CommonButton';
import EmployeesTable from './table/EmployeesTable';
import RouterPaths from '../../constants/RouterPaths';
import { AccountLayoutContentWrapper } from '../../common/components/AccountLayoutWrapper';
import SettingsHeader from '../SettingsHeader';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import plusIcon from '../../assets/img/icons/plus.svg';

const EmployeesPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const items = useSelector(state => state.myOrganization.get('items'));
  const totalItems = useSelector(state => state.myOrganization.get('totalItems'));
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const [hasAccess, setHasAccess] = useState(false);

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    if (!isOwner) {
      setHasAccess(false);
      return history.push(RouterPaths.DASHBOARD);
    }
    return setHasAccess(true);
  }, [isOwner, history]);

  useEffect(() => {
    dispatch(searchEmployees(searchParams));
  }, [dispatch, searchParams]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  const removeEmployeeHandler = id => {
    dispatch(removeEmployee(id)).then(() => {
      dispatch(searchEmployees(searchParams));
    });
  };

  return (
    <>
      {hasAccess && (
        <CommonMyOrganizationContainer>
          <SettingsHeader>
            <Row>
              <Col className="d-flex justify-content-end align-items-center">
                <CommonButton
                  className="text-sm"
                  label="ADD NEW EMPLOYEE"
                  variant={CommonButtonVariants.PRIMARY}
                  icon={plusIcon}
                  iconAlt="Add New Employee"
                  iconLeft={true}
                  handleClick={() => history.push(RouterPaths.CREATE_EMPLOYEE)}
                />
              </Col>
            </Row>
          </SettingsHeader>
          <AccountLayoutContentWrapper>
            <Row>
              <Col>
                <h5 className="font-weight-bold m-0">Employees</h5>
                <p className="text-sm mt-2">
                  Below you can find the list of employees using the iTrade30 platform in your
                  company.
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <EmployeesTable
                data={{ items, total: totalItems }}
                offset={searchParams.offset}
                changeOffset={changeOffset}
                removeEmployeeHandler={removeEmployeeHandler}
              />
            </Row>
          </AccountLayoutContentWrapper>
        </CommonMyOrganizationContainer>
      )}
    </>
  );
};

export default withRouter(EmployeesPage);
