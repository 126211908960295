import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { reverse } from 'named-urls';
import RouterPaths from '../../../constants/RouterPaths';
import { EmployeeStatusTypes } from '../../../constants/EmployeeStatusTypes';
import CommonDeleteIconButton from '../../../common/components/CommonDeleteIconButton';
import CommonEditIconButton from '../../../common/components/CommonEditIconButton';

const EmployeesTableRow = ({ item, history, handleDeleteEmployee }) => {
  const getItemStatusBoxStyle = () => {
    switch (item?.status) {
      case EmployeeStatusTypes.ACTIVE:
        return 'box-success';
      case EmployeeStatusTypes.PENDING:
        return 'box-warning';
      case EmployeeStatusTypes.REJECTED:
        return 'box-danger';
      default:
        return 'box-primary';
    }
  };

  const getItemStatusBoxLabel = () => {
    switch (item?.status) {
      case EmployeeStatusTypes.ACTIVE:
        return 'ACCEPTED';
      case EmployeeStatusTypes.PENDING:
        return 'IN VERIFICATION';
      case EmployeeStatusTypes.REJECTED:
        return 'REJECTED';
      default:
        return '-';
    }
  };

  return (
    <>
      <td>{item?.firstName}</td>
      <td>{item?.lastName}</td>
      <td>-</td>
      <td>
        <div
          className={`status-box ${getItemStatusBoxStyle()} employee-common-table__status-box`}
        >
          {getItemStatusBoxLabel()}
        </div>
      </td>
      <td>{item?.email}</td>
      <td className="common-table__action-cell">
        <CommonEditIconButton
          className="employees-table-row__action-button"
          handleClick={() =>
            history.push(reverse(RouterPaths.EMPLOYEE_DETAILS, { id: item?.id }))
          }
        />
        <CommonDeleteIconButton
          className="employees-table-row__action-button"
          handleClick={() => {
            if (handleDeleteEmployee !== undefined) {
              handleDeleteEmployee(item);
            }
          }}
        />
      </td>
    </>
  );
};

EmployeesTableRow.defaultProps = {
  handleDeleteEmployee: null,
};

EmployeesTableRow.propTypes = {
  item: PropTypes.object.isRequired,
  handleDeleteEmployee: PropTypes.func,
};

export default withRouter(EmployeesTableRow);
