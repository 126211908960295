import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import HeroSection from './sections/HeroSection';
import ContactUsSection from './sections/ContactUsSection';
import BuySection from './sections/BuySection';
import SellSection from './sections/SellSection';
import LiveOnlineAuctionSection from './sections/LiveOnlineAuctionSection';
import AuctionSection from './sections/AuctionSection';
import RecentlySoldSection from './sections/RecentlySoldSection';
import MobileSection from './sections/MobileSection';
import RouterPaths from '../constants/RouterPaths';
import { getHotAuction, getRecentlySoldVehicles } from './HomePageActions';

const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));
  const isSeller = useSelector(state => state.auth.get('isSeller'));
  const isBuyer = useSelector(state => state.auth.get('isBuyer'));
  const recentlySold = useSelector(state => state.homePage.get('recentlySold'));
  const hotAuction = useSelector(state => state.homePage.get('hotAuction'));

  useEffect(() => {
    if (isAuthenticated) {
      return history.push(RouterPaths.SEARCH);
    }

    return () => {};
  }, [isAuthenticated, dispatch, isSeller, isBuyer, history]);

  useEffect(() => {
    const { hash } = window.location;

    dispatch(getRecentlySoldVehicles());
    dispatch(getHotAuction());

    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div style={{ maxWidth: 1300, width: '100%' }}>
        <HeroSection />
        <BuySection />
        <SellSection />
        <LiveOnlineAuctionSection />
        {hotAuction && <AuctionSection auctionData={hotAuction} />}
        {recentlySold?.length && <RecentlySoldSection items={recentlySold} />}
        <MobileSection />
        <ContactUsSection />
      </div>
    </div>
  );
};

export default HomePage;
