import RouterPaths from './RouterPaths';

export const InformationForUsersList = {
  contactUs: {
    label: 'Contact us',
    path: RouterPaths.CONTACT_US,
  },
  faq: {
    label: 'FAQ',
    path: RouterPaths.FAQ,
  },
  privacyPolicy: {
    label: 'Privacy Policy',
    path: RouterPaths.PRIVACY_POLICY,
  },
  termsAndConditions: {
    label: 'Terms and conditions',
    path: RouterPaths.TERMS_AND_CONDITIONS,
  },
};
