import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    description: Yup.string()
      .required('Field is required')
      .min(1, 'Description must be between 1 and 10000 characters')
      .max(10000, 'Description must be between 1 and 10000 characters'),
  });
};
