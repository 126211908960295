import React from 'react';
import ContactUsForm from '../../common/components/Forms/ContactUsForm';

const ContactUsSection = () => {
  return (
    <section className="contact-us-section section">
      <ContactUsForm />
    </section>
  );
};

export default ContactUsSection;
