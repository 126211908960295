import { fromJS } from 'immutable';

const initialData = fromJS({
  items: [],
  totalItems: 0,
  filter: '',
  hasMore: true,
  timeStamp: null,
});

export const SEARCH_MY_BIDS_SUCCESS = 'SEARCH_MY_BIDS_SUCCESS';
export const MY_BIDS_CLEAR_SUCCESS = 'MY_BIDS_CLEAR_SUCCESS';
export const SET_BIDS_FILTER = 'SET_BIDS_FILTER';
export const MY_BIDS_REFRESH_SUCCESS = 'MY_BIDS_REFRESH_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case SEARCH_MY_BIDS_SUCCESS:
      return state.merge({
        ...(payload.totalItems !== null ? { totalItems: payload.totalItems } : {}),
        items: payload.offset
          ? state.get('items').merge(fromJS(payload.items))
          : fromJS(payload.items),
        timeStamp: payload.timeStamp,
        hasMore: payload.hasMore,
      });
    case MY_BIDS_REFRESH_SUCCESS: {
      const index = state
        .get('items')
        .toJS()
        .findIndex(item => item.id === payload.item.id);
      if (index !== -1) {
        const itemData = payload.item;
        itemData.timeStamp = new Date();
        itemData.organizationId = itemData.organizationId
          ? itemData.organizationId
          : itemData?.seller?.organizationContact?.id;
        return state.merge({
          items: state.get('items').update(index, () => itemData),
        });
      }
      return state;
    }
    case MY_BIDS_CLEAR_SUCCESS:
      return state.merge(
        fromJS({
          items: [],
          totalItems: 0,
          hasMore: true,
        }),
      );
    case SET_BIDS_FILTER:
      return state.merge({
        filter: payload,
      });
    default:
      return state;
  }
};
