import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import history from 'browserHistory';
import { fetchMyProfileDetails } from '../settings/myprofile/MyProfileActions';
import NotFound from '../notfound/NotFound';
import { getSiteByLink } from './CustomSiteActions';
import ContactUs from './sites/ContactUs';

const CONTACT_URL = 'contact-us';

const CustomSite = () => {
  const [content, setContent] = useState('');
  const [showNotFound, setShowNotFound] = useState(false);
  const dispatch = useDispatch();
  const link = history.location.pathname.substring(1);
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));

  useEffect(() => {
    if (link === CONTACT_URL && isAuthenticated) {
      dispatch(fetchMyProfileDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, link]);

  const notFound = () => {
    setShowNotFound(true);
  };

  useEffect(() => {
    setShowNotFound(false);
    setContent('');
    dispatch(getSiteByLink(link, setContent, notFound));
  }, [dispatch, link]);

  const render = () => {
    if (link === CONTACT_URL) {
      return <ContactUs />;
    }

    return content ? (
      <>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </>
    ) : (
      ''
    );
  };

  return (
    <div className="d-flex justify-content-center">
      <div style={{ maxWidth: 1300, width: '100%' }}>
        {showNotFound && <NotFound />}
        {render()}
      </div>
    </div>
  );
};
export default withRouter(CustomSite);
