import React from 'react';
import { Input, Label } from 'reactstrap';

export const CommonToggleSwitch = props => {
  return (
    <>
      <Label check className="common-toggle-switch">
        <Input type="checkbox" {...props} />
        <span className="common-toggle-switch__slider" />
      </Label>
    </>
  );
};

export default CommonToggleSwitch;
