import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import React from 'react';
import errorIcon from '../../assets/img/icons/error.svg';
import infoIcon from '../../assets/img/icons/info.svg';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import CommonButton from './CommonButton';
import { CommonSweetAlertVariants } from '../../constants/CommonSweetAlertVariants';

const CommonSweetAlert = ({
  show,
  componentProps,
  onConfirm,
  confirmButtonText,
  onCancel,
  cancelButtonText,
  variant,
  children,
  icon,
}) => {
  const renderButtons = () => {
    let buttonVariant;

    switch (variant) {
      case CommonSweetAlertVariants.ERROR:
        buttonVariant = CommonButtonVariants.DELETE;
        break;
      case CommonSweetAlertVariants.SUCCESS:
        buttonVariant = CommonButtonVariants.SUCCESS;
        break;
      default:
        buttonVariant = CommonButtonVariants.PRIMARY;
    }

    return (
      <div className="actions-wrapper">
        <CommonButton
          variant={CommonButtonVariants.OUTLINED}
          label={`${cancelButtonText || 'Cancel'}`}
          handleClick={onCancel}
          className="w-100"
        />
        <CommonButton
          variant={buttonVariant}
          label={`${confirmButtonText || 'Confirm'}`}
          handleClick={onConfirm}
          className="w-100"
        />
      </div>
    );
  };

  const getIcon = () => {
    if (icon) {
      return icon;
    }

    switch (variant) {
      case CommonSweetAlertVariants.ERROR:
        return errorIcon;
      case CommonSweetAlertVariants.INFO:
        return infoIcon;
      default:
        return null;
    }
  };

  return (
    <SweetAlert
      custom
      style={{ maxWidth: '800px', width: '100%', padding: '40px 80px' }}
      customIcon={getIcon()}
      show={show}
      showCancel={true}
      onConfirm={onConfirm}
      onCancel={onCancel}
      customClass={`${getIcon() ? 'sweet-alert__with-icon' : ''}`}
      customButtons={renderButtons()}
      cancelBtnText={cancelButtonText || 'Cancel'}
      cancelBtnCssClass="common-button outline-primary"
      confirmBtnText={confirmButtonText || 'Confirm'}
      confirmBtnCssClass="common-button button-success"
      title=""
      {...componentProps}
    >
      <div className="common-sweet-alert__content-wrapper">{children}</div>
    </SweetAlert>
  );
};

CommonSweetAlert.defaultProps = {
  componentProps: {},
  onConfirm: () => {},
  onCancel: () => {},
  show: false,
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
  icon: null,
};

CommonSweetAlert.propTypes = {
  componentProps: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  show: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  icon: PropTypes.object,
};

export default CommonSweetAlert;
