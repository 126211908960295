import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

const SettingsHeader = ({ children }) => {
  return (
    <Row>
      <Col md={3} sm={12}>
        <h3 className="font-weight-bold">Settings</h3>
      </Col>
      <Col md={9} sm={9}>
        {children}
      </Col>
    </Row>
  );
};

SettingsHeader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.object,
};

export default SettingsHeader;
