import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import RoleForm from './RoleForm';
import AddProfileForm from './AddProfileForm';
import AddPaymentDetailsForm from './AddPaymentDetailsForm';
import RegisterSuccess from '../RegisterSuccess';
import RouterPaths from '../../constants/RouterPaths';
import logo from '../../assets/img/logo-new.png';

import CompanyDetailsForm from './CompanyDetailsForm';
import CompanyAddressForm from './CompanyAddressForm';
import Progress from './progress';

const RegisterForm = ({ values, setFieldValue }) => {
  const [step, setStep] = useState(1);

  const prevStep = () => {
    setStep(prevState => prevState - 1);
  };

  const nextStep = () => {
    setStep(prevState => prevState + 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <RoleForm nextStep={nextStep} values={values} setFieldValue={setFieldValue} />;
      case 2:
        return (
          <CompanyDetailsForm
            prevStep={prevStep}
            setFieldValue={setFieldValue}
            values={values}
            nextStep={nextStep}
          />
        );
      case 3:
        return (
          <CompanyAddressForm
            prevStep={prevStep}
            setFieldValue={setFieldValue}
            values={values}
            nextStep={nextStep}
          />
        );
      case 4:
        return <AddProfileForm prevStep={prevStep} nextStep={nextStep} values={values} />;
      case 5:
        return (
          <AddPaymentDetailsForm prevStep={prevStep} nextStep={nextStep} values={values} />
        );
      case 6:
        return <RegisterSuccess />;
      default:
        return null;
    }
  };

  return (
    <>
      <Row className="register-page m-0 p-0">
        <Col className="register-page__left-column" md={3} sm={12}>
          <Link to={RouterPaths.HOMEPAGE} className="page-logo-link">
            <img src={logo} alt="logo" />
          </Link>
          <h2 className="register-page__big-title font-weight-bold register-page__hide-on-mobile">
            You are just a few clicks away from creating your account.
          </h2>
          <Progress step={step} />
        </Col>
        <Col className="register-page__right-column" md={9} sm={12}>
          {renderStep()}
        </Col>
      </Row>
    </>
  );
};

RegisterForm.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default RegisterForm;
