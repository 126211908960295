import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { useSelector } from 'react-redux';
import noImageImg from '../assets/img/search/noimage.svg';
import { capitalizeString } from '../common/helpers/capitalizeString';
import OrganizationRoleBox from '../myvehicles/OrganizationRoleBox';
import { pencesToPounds } from '../common/helpers/pencesToPounds';
import AuctionTypeLabel from '../myvehicles/AuctionTypeLabel';
import { getPriceColor } from '../common/helpers/auctionColorHelper';
import { auctionListType } from '../constants/AuctionListType';
import CurrentBidLabel from '../myvehicles/CurrentBidLabel';
import { checkIsPropertySpecified } from '../common/helpers/checkIsPropertySpecified';
import carCalendarBlue from '../assets/img/vehicles/car-calendar-blue.svg';
import carRegistrationBlue from '../assets/img/vehicles/car-registration-blue.svg';
import carMileageBlue from '../assets/img/vehicles/car-mileage-blue.svg';
import carTransmissionBlue from '../assets/img/vehicles/car-transmission-blue.svg';
import carEngineBlue from '../assets/img/vehicles/car-engine-blue.svg';
import carFuelBlue from '../assets/img/vehicles/car-fuel-blue.svg';
import carColorBlue from '../assets/img/vehicles/car-color-blue.svg';
import carLocationBlue from '../assets/img/vehicles/car-location-blue.svg';
import { AuctionStatusType, AuctionStatusTypeName } from '../constants/AuctionStatusType';
import AuctionMainDescriptionContainer, {
  SlotItems,
} from '../common/components/Auction/AuctionMainDescriptionContainer';
import { getBidItemMessage } from '../common/helpers/auctionItemHelper';
import Derivative from '../common/components/Auction/Derivative';
import { prepareVehicleTitle } from '../common/helpers/prepareVehicleTitle';
import { AuctionStatus } from '../constants/AuctionStatus';
import { AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE } from '../constants/AuctionBid';

const MyBidsItem = ({ item, onClickItem, getRef }) => {
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  const id = useSelector(state => state.auth.get('id'));
  const [bidBarColor, setBidBarColor] = useState('');
  const [bidBarMessage, setBidBarMessage] = useState('');
  const [bidBarTextColor, setBidBarTextColor] = useState('');
  const [windowFocus, setWindowFocus] = useState(false);

  const windowOnFocus = () => {
    setWindowFocus(true);
  };

  const windowOnBlur = () => {
    setWindowFocus(false);
  };

  let highestBid = null;
  if (item && item.bids?.length) {
    highestBid = item.bids.filter(bid => bid.isHighest)?.[0];
  }

  useEffect(() => {
    window.addEventListener('focus', windowOnFocus);
    window.addEventListener('blur', windowOnBlur);
    return () => {
      window.removeEventListener('focus', windowOnFocus);
      window.removeEventListener('blur', windowOnBlur);
    };
  }, []);

  useEffect(() => {
    renderMyBidItemMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  let image = noImageImg;
  if (item?.vehicle?.vehicleMedia?.photos?.length) {
    image = item?.vehicle?.vehicleMedia?.photos?.[0]?.fileUrl;
  }

  const title = prepareVehicleTitle(item?.vehicle);
  const isHighestBid = highestBid?.bidderOrganizationId === organizationId;

  const organizationHighestBids = item?.bids
    ?.filter(item => item.bidderOrganizationId === organizationId)
    .map(item => item.amountInPence);

  const organizationHighestBid =
    organizationHighestBids.length > 0 &&
    organizationHighestBids.reduce((a, b) => {
      return Math.max(a, b);
    });

  const myBidsAmount = item?.bids
    ?.filter(item => item.bidderId === id)
    .map(item => item.amountInPence);
  const myHighestBid =
    myBidsAmount.length > 0 &&
    myBidsAmount?.reduce((a, b) => {
      return Math.max(a, b);
    });

  const organizationRole = item?.organizationRole || item?.seller?.organizationContact?.role;

  const renderMyBidItemMessage = () => {
    const messageData = getBidItemMessage(isHighestBid, organizationHighestBid, item);

    const isLostBidder =
      (item?.type === AuctionStatusType.TRYING_TO_DEAL ||
        item?.status === AuctionStatus.SOLD) &&
      item?.status !== AuctionStatus.PUBLISHED &&
      item?.seller?.id !== id &&
      item?.bids.length > 0 &&
      !item?.bids?.filter(it => it.isHighest).length;

    if (messageData) {
      setBidBarColor(messageData.statusColor);
      setBidBarMessage(messageData.message);
      setBidBarTextColor(isLostBidder ? '#475467' : '#fff');
      return {
        message: messageData.message,
        statusColor: messageData.statusColor,
      };
    }
    return null;
  };

  // eslint-disable-next-line no-unused-vars
  const renderAuctionType = (isDesktop = true) => {
    return (
      <>
        <p
          className={`auction-item__status-type auction-item__status-type--bid ${
            isDesktop ? 'search-min-price--desktop' : 'search-min-price--mobile'
          }`}
        >
          <span className="search-aucion-type__name">
            <AuctionTypeLabel hasBid={!!item?.highestBidInPence} type={item.type} />
          </span>
        </p>
        <p
          className={`auction-item__status-type auction-item__status-type--bid ${
            isDesktop ? 'search-min-price--desktop' : 'search-min-price--mobile'
          }`}
        >
          <span className="search-aucion-type__name">
            <CurrentBidLabel hasBid={!!item?.highestBidInPence} type={item.type} />
          </span>

          <span className="search-min-price" style={getPriceColor(item.shouldPriceBeGreen)}>
            <CurrencyFormat
              value={
                pencesToPounds(item?.highestBidInPence) ||
                pencesToPounds(item?.minimumPriceInPence) ||
                pencesToPounds(AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE)
              }
              thousandSeparator={true}
              allowNegative={false}
              prefix="£"
              displayType="text"
            />
          </span>
        </p>
      </>
    );
  };

  const isAuctionLabel = useMemo(() => {
    return !!(item?.type || AuctionStatusTypeName[item?.type]);
  }, [item.type]);

  return (
    <>
      <Row
        className="search-box-of-car my-bids-box-of-car search-box-of-car--mobile"
        key={item.id}
        onClick={onClickItem}
      >
        <Col
          sm={12}
          md={3}
          className="search-box-of-img search-box-of-img--mobile flex-column"
        >
          <div className="search-box-of-img-wrapper" ref={el => getRef(el)}>
            <img
              className="search-img-in-box"
              src={image}
              alt="car"
              onError={e => {
                e.target.src = noImageImg;
              }}
            />
          </div>
          <div className="search-title-wrapper--mobile">
            <p className="search-title--mobile">{title}</p>
          </div>
        </Col>
        <Col sm={12} md={9} className="h-100 d-flex flex-column">
          <Row
            md={12}
            className="auction-basic-data-row h-100 d-flex flex-column position-relative"
          >
            <Col md={12} className="d-flex flex-column">
              <Row>
                <Col className="d-flex flex-row">
                  <div
                    className={`auction-basic-data__type-label text-uppercase ${
                      isAuctionLabel ? '' : 'hide'
                    }`}
                  >
                    <AuctionTypeLabel hasBid={!!item?.highestBidInPence} type={item.type} />
                  </div>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col>
                  <p className="search-title m-0 font-weight-bold">
                    {checkIsPropertySpecified(title, true)}
                  </p>
                </Col>
              </Row>
              <Row md={12} className="search-content-left auction-basic-data-wrapper">
                <Col className="flex-grow-1">
                  <Row className="auction-basic-data__container">
                    <Col md={3} className="basic-data-col">
                      {' '}
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.year ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carCalendarBlue} alt="Vehicle Calendar" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(item?.vehicle?.year, false, '', 'N/S')}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.mileage ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carMileageBlue} alt="Cat Mileage Blue" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(item?.vehicle?.mileage, false, '', 'N/S')}{' '}
                          miles
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.derivative ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carEngineBlue} alt="Vehicle Engine" />
                        </div>
                        <p className="m-0 ml-2">
                          <Derivative derivative={item?.vehicle?.derivative} limit={9} />
                        </p>
                      </div>
                    </Col>

                    <Col md={3} className="basic-data-col">
                      {' '}
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.colour ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carColorBlue} alt="Vehicle Color" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(item?.vehicle?.colour, false, '', 'N/S')}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      {' '}
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.registrationPlate ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carRegistrationBlue} alt="Vehicle Registration" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(
                            item?.vehicle?.registrationPlate,
                            false,
                            '',
                            'N/S',
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.transmission ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carTransmissionBlue} alt="Vehicle Transmission" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(
                            capitalizeString(item?.vehicle?.transmission),
                            false,
                            '',
                            'N/S',
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.fuel ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carFuelBlue} alt="Vehicle Fuel" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(
                            capitalizeString(item?.vehicle?.fuel),
                            false,
                            '',
                            'N/S',
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="basic-data-col">
                      {' '}
                      <div
                        className={`d-flex flex-row align-items-center ${
                          !item?.vehicle?.city ? 'd-none' : ''
                        }`}
                      >
                        <div className="auction-item__icon">
                          <img src={carLocationBlue} alt="Vehicle Location" />
                        </div>
                        <p className="m-0 ml-2">
                          {checkIsPropertySpecified(item?.location?.city, false, '', 'N/S')}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <AuctionMainDescriptionContainer
                item={item}
                listType={auctionListType.MY_BIDS}
                organizationId={organizationId}
                windowFocus={windowFocus}
                pattern={[
                  SlotItems.TIME_LEFT,
                  SlotItems.CURRENT_BID,
                  <div>
                    <p className="font-weight-bold m-0 bid-label">
                      <CurrencyFormat
                        value={myHighestBid ? myHighestBid / 100 : 0}
                        thousandSeparator={true}
                        allowNegative={false}
                        prefix="£"
                        displayType="text"
                      />
                    </p>
                    <p className="m-0 main-description-container__item-title text-xs">
                      Highest Bid
                    </p>
                  </div>,
                  SlotItems.AUCTION_STATUS_BOX,
                ]}
              />
            </Col>
          </Row>
        </Col>
        <OrganizationRoleBox role={organizationRole} />
      </Row>
      {bidBarMessage && bidBarColor && (
        <Row
          md={12}
          className="auction-bar-message"
          style={{ backgroundColor: bidBarColor, color: bidBarTextColor }}
        >
          <p className="m-0 d-block font-weight-bold">{bidBarMessage}</p>
        </Row>
      )}
    </>
  );
};
MyBidsItem.defaultProps = {
  onClickItem: () => {},
  getRef: () => {},
};

MyBidsItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClickItem: PropTypes.func,
  getRef: PropTypes.func,
};

export default withRouter(MyBidsItem);
