import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { Field } from 'formik';
// eslint-disable-next-line import/no-named-as-default
import CommonToggleSwitch from '../CommonToggleSwitch';

const FormGroupToggleFieldInner = props => {
  const { form, field, checkboxes, checkDependencies, isRoleGroupCheckbox } = props;
  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || [];
  return (
    <>
      {checkboxes.map(checkbox => (
        <FormGroup
          check
          disabled={checkbox.disabled || form.isSubmitting}
          key={checkbox.label}
          className="form-group-toggle-checkbox"
        >
          <Label className="form-input-field-label">{checkbox.label}</Label>
          <CommonToggleSwitch
            disabled={checkbox.disabled || form.isSubmitting}
            value={value}
            checked={form.values[field.name].includes(checkbox.value)}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onChange={({ target }) => {
              const values = form.values[field.name];
              if (target.checked) {
                values.push(checkbox.value);
              } else {
                const idx = values.indexOf(checkbox.value);
                if (idx !== -1) {
                  values.splice(idx, 1);
                }
              }
              if (isRoleGroupCheckbox) {
                checkDependencies(values);
              }
              form.setFieldValue(field.name, [...values]);
            }}
          />
        </FormGroup>
      ))}

      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: 'red ' }}>{error}</label>
      )}
    </>
  );
};

const FormGroupToggleField = props => {
  return <Field {...props} component={FormGroupToggleFieldInner} />;
};

FormGroupToggleField.defaultProps = {
  disabled: false,
  checkDependencies: () => {},
  isRoleGroupCheckbox: false,
};

FormGroupToggleField.defaultProps = {
  checkboxes: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  checkDependencies: PropTypes.func,
  isRoleGroupCheckbox: PropTypes.bool,
};

export default FormGroupToggleField;
