import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

const AuctionBuyerInformation = ({ item }) => {
  const { firstName, lastName, email, phoneNumber, organizationContact } = item?.buyer || {};

  const renderBuyerInformation = (value, name, hideEmpty) => {
    if (hideEmpty && !value) {
      return '';
    }

    return (
      <Col lg={3} md={3} sm={4} xs={12} className="summary__list-item text-base">
        <p className="m-0">{name}</p>
        <p className="m-0 fw-600">{value || '-'}</p>
      </Col>
    );
  };

  return (
    <>
      <Row
        className="main-description-container__section-title mt-5"
        id="vehicle-buyers-information"
      >
        <Col md={12} className="p-0 pb-2 text-lg">
          Buyer&apos;s Information
        </Col>
      </Row>
      <Row className="mt-3 summary__buyer-list">
        {renderBuyerInformation(organizationContact?.tradeName, 'Company Name')}
        {renderBuyerInformation(
          !firstName && !lastName ? '' : `${firstName} ${lastName}`,
          'Contact Name',
        )}
        {renderBuyerInformation(email, 'Email')}
        {renderBuyerInformation(phoneNumber, 'Mobile')}
        {renderBuyerInformation(organizationContact?.location?.address, 'Address - Line 1')}
        {renderBuyerInformation(organizationContact?.location?.address2, 'Address - Line 2')}
        {renderBuyerInformation(organizationContact?.location?.city, 'City')}
        {renderBuyerInformation(organizationContact?.landlinePhoneNumber, 'Landline')}
        {renderBuyerInformation(organizationContact?.location?.county, 'County')}
        {renderBuyerInformation(
          organizationContact?.location?.postalCode?.postcode,
          'Post Code',
        )}
      </Row>
    </>
  );
};

AuctionBuyerInformation.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AuctionBuyerInformation;
