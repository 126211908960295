import moment from 'moment';
import { AuctionBarColors, AuctionStatus } from '../../constants/AuctionStatus';
import { preparePriceFormat } from './preparePriceFormat';
import { AUCTION_BID_MAX_BIDS_LIMIT } from '../../constants/AuctionBid';
import { AuctionItemMessage } from '../../constants/AuctionItemMessage';
import { BidStatusTypeName } from '../../constants/BidStatusType';

export const calculateDiffTime = endsOn => {
  const now = moment();
  let minutesDiff = moment(endsOn).diff(now, 'minutes');
  let secondsDiff = moment(endsOn).diff(now, 'seconds') - minutesDiff * 60;

  if (minutesDiff <= 0 && secondsDiff <= 0) {
    return null;
  }

  if (minutesDiff < 10) {
    minutesDiff = `0${minutesDiff}`;
  }
  if (secondsDiff < 10) {
    secondsDiff = `0${secondsDiff}`;
  }

  return `${minutesDiff}:${secondsDiff}`;
};

export const getBidItemMessage = (
  isHighestBid,
  organizationHighestBid,
  item,
  isTheHighestBidder,
) => {
  let message = '';
  let statusColor = '';

  switch (item?.status) {
    case AuctionStatus.PUBLISHED:
      if (isHighestBid) {
        if (item.proxyBidInPence) {
          message = `Your bid of ${preparePriceFormat(
            item.proxyBidInPence,
            true,
          )} is the highest. Your maximum bid is ${preparePriceFormat(
            organizationHighestBid,
            true,
          )}`;
        } else {
          message = `Your bid of ${preparePriceFormat(
            organizationHighestBid,
            true,
          )} is the highest`;
        }
        statusColor = AuctionBarColors.GREEN;
      } else if (item?.bids?.length === AUCTION_BID_MAX_BIDS_LIMIT) {
        message = `Your bid of ${preparePriceFormat(
          organizationHighestBid,
          true,
        )} is not the highest`;
        statusColor = AuctionBarColors.RED;
      } else if (item?.bids?.length > 0 && item?.bids?.length < AUCTION_BID_MAX_BIDS_LIMIT) {
        message = `Your bid of ${preparePriceFormat(
          organizationHighestBid,
          true,
        )} is not the highest - try again`;
        statusColor = AuctionBarColors.RED;
      }
      break;
    case AuctionStatus.TO_ACCEPT:
      if (isHighestBid) {
        message = AuctionItemMessage.TO_ACCEPT_BUYER;
        statusColor = AuctionBarColors.YELLOW;
      } else {
        message = `Your bid of ${preparePriceFormat(
          organizationHighestBid,
          true,
        )} was not the highest.`;
        statusColor = AuctionBarColors.GRAY;
      }
      break;
    case AuctionStatus.WAITING:
      if (isHighestBid) {
        message = isTheHighestBidder
          ? 'Waiting for you to accept'
          : AuctionItemMessage.WAITING;
        statusColor = AuctionBarColors.BLUE;
      } else {
        message = `Your bid of ${preparePriceFormat(
          organizationHighestBid,
          true,
        )} was not the highest.`;
        statusColor = AuctionBarColors.RED;
      }
      break;
    case AuctionStatus.REJECTED:
      if (isHighestBid) {
        message = BidStatusTypeName.REJECTED;
        statusColor = AuctionBarColors.RED;
      } else {
        message = `Your bid of ${preparePriceFormat(
          organizationHighestBid,
          true,
        )} was not the highest.`;
        statusColor = AuctionBarColors.GRAY;
      }
      break;
    case AuctionStatus.SOLD:
      if (isHighestBid) {
        message = AuctionItemMessage.WON;
        statusColor = AuctionBarColors.GREEN;
      } else {
        message = `Your bid of ${preparePriceFormat(
          organizationHighestBid,
          true,
        )} was not the highest. The winning bid was ${preparePriceFormat(
          item?.proxyBidInPence ?? item?.wonBidPriceInPence,
          true,
        )}`;
        statusColor = AuctionBarColors.GRAY;
      }
      break;
    default:
      return null;
  }

  return {
    message,
    statusColor,
  };
};
