import axios from 'axios';
import jwt from 'jsonwebtoken';
import { logout } from '../auth/AuthActions';
import { AUTH_TOKEN } from '../auth/AuthConstants';
import { store } from '../index';
import { isDateBefore } from '../common/utils/date/Date.utils';

let authAuthInterceptor = null;

const BACKEND_URL =
    process.env.REACT_APP_BACKEND_URL || 'https://dealerbackend.itradestaging.link';

export const apiClient = axios.create({
  baseURL: `${BACKEND_URL}`,
  timeout: 5000,
});

apiClient.interceptors.request.use(req => {
  const authToken = jwt.decode(localStorage.getItem(AUTH_TOKEN));
  const tokenExpInSeconds = authToken?.exp;
  if (!authToken || isDateBefore(tokenExpInSeconds * 1000, new Date())) {
    store.dispatch(logout());
  }
  return req;
});

apiClient.interceptors.response.use(res => {
  if (res && res.data && res.data.errorCode) {
    throw res.data.errorCode;
  }
  return res;
});

const addAuthInterceptor = logoutAction => {
  authAuthInterceptor = apiClient.interceptors.response.use(
    res => res,
    error => {
      if (error && error.response && error.response.status === 401) {
        logoutAction();
      }
      throw error;
    },
  );
};
const removeAuthInterceptor = () => {
  if (authAuthInterceptor) {
    apiClient.interceptors.response.eject(authAuthInterceptor);
  }
};

export const setAuthorizationHeader = (token, logoutAction) => {
  if (token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    addAuthInterceptor(logoutAction);
  } else {
    removeAuthInterceptor();
    delete apiClient.defaults.headers.common.Authorization;
  }
};
