import React from 'react';
import FooterNav from './FooterNav';

class Footer extends React.Component {
  render() {
    return (
      <footer className="styled-footer">
        <FooterNav />
      </footer>
    );
  }
}

export default Footer;
