import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FormInputField from '../../common/components/formFields/FormInputField';
import { validateFormField } from '../../common/helpers/validateFormField';
import { FormFieldTypes } from '../../constants/FormFieldTypes';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';
import {
  getDraft,
  getVehicleMakeList,
  getVehicleModelList,
  saveBasicCarDataDraft,
  validateBasicCarData,
} from '../AddVehicleActions';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import { dataToSelect } from '../../common/helpers/dataToSelect';
import calendarIcon from '../../assets/img/icons/calendar.svg';
import FormDatePickerField from '../../common/components/formFields/FormDatePickerField';
import { parseStringToInt } from '../../common/helpers/parseStringToInt';
import { reverseDateToString } from '../../common/utils/date/Date.utils';
import { BasicCarDataFields } from '../../constants/BasicCarDataFields';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import { AddVehicleStepsMapping } from '../../constants/AddVehicle';

const AddBasicCarDataForm = ({ values, nextForm, form }) => {
  const dispatch = useDispatch();
  const [makeOptions, setMakeOptions] = useState();
  const [modelOptions, setModelOptions] = useState();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const basicData = useSelector(state => state.addVehicle.get('basicData'));
  const id = useSelector(state => state.addVehicle.get('id'));
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const [errors, setErrors] = useState({
    registrationPlateError: '',
    millageError: '',
    makeIdError: '',
    modelIdError: '',
    yearError: '',
    derivativeError: '',
    carTypeError: '',
    transmissionError: '',
    engineSizeError: '',
    fuelError: '',
    colourError: '',
    doorsError: '',
    dateOfFirstRegistrationError: '',
    previousKeepersInTotalError: '',
    vinError: '',
  });

  const validateForm = () =>
    new Promise((resolve, reject) => {
      const {
        registrationPlate,
        mileage,
        makeSelect,
        modelSelect,
        year,
        derivative,
        carType,
        transmission,
        engineSize,
        fuel,
        colour,
        doors,
        dateOfFirstRegistration,
        previousKeepersInTotal,
        vin,
      } = values?.basicData;

      const errors = {
        registrationPlateError: validateFormField(registrationPlate, null, true, 10),
        millageError: validateFormField(mileage, FormFieldTypes.NUMBER, true, 9),
        makeIdError: validateFormField(makeSelect.value, FormFieldTypes.STRING, true),
        modelIdError: validateFormField(modelSelect.value, FormFieldTypes.STRING, true),
        yearError: validateFormField(year, FormFieldTypes.PROD_YEAR, true, 4),
        derivativeError: validateFormField(derivative, FormFieldTypes.DERIVATIVE, true, 150),
        carTypeError: validateFormField(carType, FormFieldTypes.STRING, true, 20),
        transmissionError: validateFormField(transmission, FormFieldTypes.STRING, true, 40),
        engineSizeError: validateFormField(engineSize, FormFieldTypes.ENGINE_SIZE, true, 9),
        fuelError: validateFormField(fuel, FormFieldTypes.STRING, true, 20),
        colourError: validateFormField(colour, FormFieldTypes.STRING, true, 40),
        doorsError: validateFormField(doors, FormFieldTypes.DOORS, true, 2),
        dateOfFirstRegistrationError: validateFormField(
          dateOfFirstRegistration,
          FormFieldTypes.DATE_OF_REG,
          true,
        ),
        previousKeepersInTotalError: validateFormField(
          previousKeepersInTotal,
          FormFieldTypes.PREV_KEEPERS,
          true,
          2,
        ),
        vinError: validateFormField(vin, FormFieldTypes.VIN, false, 17),
      };
      setErrors(errors);
      const {
        registrationPlateError,
        millageError,
        makeIdError,
        modelIdError,
        yearError,
        derivativeError,
        carTypeError,
        transmissionError,
        engineSizeError,
        fuelError,
        colourError,
        doorsError,
        dateOfFirstRegistrationError,
        previousKeepersInTotalError,
        vinError,
      } = errors;
      if (
        registrationPlateError ||
        millageError ||
        makeIdError ||
        modelIdError ||
        yearError ||
        derivativeError ||
        derivativeError ||
        carTypeError ||
        transmissionError ||
        engineSizeError ||
        fuelError ||
        colourError ||
        doorsError ||
        dateOfFirstRegistrationError ||
        previousKeepersInTotalError ||
        vinError
      ) {
        reject();
      }
      resolve();
    });

  const moveToNextStep = () => {
    const {
      registrationPlate,
      mileage,
      year,
      mot,
      derivative,
      carType,
      transmission,
      engineSize,
      fuel,
      colour,
      doors,
      dateOfFirstRegistration,
      previousKeepersInTotal,
      vehicleCapCapId,
      vin,
    } = values?.basicData;

    const data = {
      ...values,
      basicData: {
        registrationPlate,
        mileage: parseStringToInt(mileage),
        year: parseStringToInt(year),
        mot: mot || null,
        derivative,
        carType,
        transmission,
        engineSize: parseStringToInt(engineSize),
        fuel,
        colour,
        doors: parseStringToInt(doors),
        dateOfFirstRegistration: reverseDateToString(dateOfFirstRegistration),
        previousKeepersInTotal: parseStringToInt(previousKeepersInTotal),
        vehicleCapCapId,
        vin: vin || null,
      },
    };

    validateForm()
      .then(() =>
        dispatch(
          validateBasicCarData(
            {
              values: data.basicData,
              modelId: values?.basicData?.modelSelect?.value,
              makeId: values?.basicData?.makeSelect?.value,
            },
            () => nextForm(),
          ),
        ),
      )
      .catch(() => {});
  };

  const {
    registrationPlateError,
    millageError,
    makeIdError,
    modelIdError,
    yearError,
    derivativeError,
    carTypeError,
    transmissionError,
    engineSizeError,
    fuelError,
    colourError,
    doorsError,
    dateOfFirstRegistrationError,
    previousKeepersInTotalError,
    vinError,
  } = errors;

  useEffect(() => {
    dispatch(
      getVehicleMakeList(makeList => {
        setMakeOptions(dataToSelect(makeList?.data, ['id', 'name']));
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const isFieldValueEmpty = Object.keys(values?.basicData).some(item => {
      if (
        item === BasicCarDataFields.VIN ||
        item === BasicCarDataFields.VEHICLE_CAP_ID ||
        item === BasicCarDataFields.MOT ||
        typeof values?.basicData[item] === 'number'
      )
        return false;
      return !values?.basicData[item];
    });

    if (isFieldValueEmpty) {
      return setIsNextDisabled(true);
    }
    return setIsNextDisabled(false);
  }, [values]);

  useEffect(() => {
    if (basicData?.makeId) {
      dispatch(
        getVehicleModelList(basicData?.makeId, modelList => {
          setModelOptions(dataToSelect(modelList?.data, ['id', 'name']));
        }),
      );
    }
  }, [dispatch, basicData]);

  const handleMakeSelectChange = e => {
    form.setFieldValue('basicData.modelSelect', { value: '', label: '' });
    dispatch(
      getVehicleModelList(e.value, modelList => {
        setModelOptions(dataToSelect(modelList?.data, ['id', 'name']));
      }),
    );
  };

  const handleSaveDraft = () => {
    dispatch(
      saveBasicCarDataDraft(
        id,
        {
          ...values.basicData,
          modelId: values?.basicData?.modelSelect?.value,
          makeId: values?.basicData?.makeSelect?.value,
        },
        id => dispatch(getDraft(id)),
      ),
    );
  };

  return (
    <>
      <AddVehicleHeader
        nextButtonHandler={moveToNextStep}
        backButtonHandler={() => {
          nextForm(AddVehicleStepsMapping.VEHICLE_REGISTRATION);
        }}
        nextButtonDisabled={!!isLoading || isNextDisabled}
        saveDraftButtonHandler={handleSaveDraft}
      />
      <AddVehicleContentWrapper>
        <Form className="text-left">
          <Row className="mb-4">
            <Col>
              <h5 className="font-weight-bold m-0">Vehicle Details</h5>
              <p className="text-sm mt-2">Add detailed information about the vehicle.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="mb-4">
                <Col md="4" sm="12">
                  <FormInputField
                    name="basicData.registrationPlate"
                    className="basic-data__input"
                    label="Vehicle registration plate"
                    invalid={!!registrationPlateError}
                    maxLength={10}
                    disabled
                    upperCase
                    required
                  />
                  <CommonErrorLabel value={registrationPlateError} />
                </Col>
                <Col md="4" sm="12">
                  <FormSelectField
                    name="basicData.makeSelect"
                    className="basic-data__input"
                    label="Make"
                    options={makeOptions}
                    onChangeHandler={handleMakeSelectChange}
                    invalid={!!makeIdError}
                    required
                  />
                  <CommonErrorLabel value={makeIdError} />
                </Col>
                <Col md="4" sm="12">
                  <FormSelectField
                    name="basicData.modelSelect"
                    className="basic-data__input"
                    label="Model"
                    options={modelOptions}
                    invalid={!!modelIdError}
                    required
                  />
                  <CommonErrorLabel value={modelIdError} />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="12" sm="12">
                  <FormInputField
                    name="basicData.derivative"
                    className="basic-data__input"
                    label="Derivative"
                    invalid={!!derivativeError}
                    maxLength={150}
                    lengthCounter={true}
                    upperCase
                    required
                  />
                  <CommonErrorLabel value={derivativeError} />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.mileage"
                    className="basic-data__input"
                    label="Mileage"
                    invalid={!!millageError}
                    maxLength={9}
                    required
                  />
                  <CommonErrorLabel value={millageError} />
                </Col>
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.year"
                    className="basic-data__input"
                    label="Year"
                    invalid={!!yearError}
                    maxLength={4}
                    required
                  />
                  <CommonErrorLabel value={yearError} />
                </Col>
                <Col md="3" sm="12">
                  <FormDatePickerField
                    name="basicData.dateOfFirstRegistration"
                    label="Date of registration"
                    icon={calendarIcon}
                    iconClassName="calendar-icon"
                    placeholder="DD/MM/YYYY"
                    invalid={!!dateOfFirstRegistrationError}
                    readOnly
                    required
                  />
                  <CommonErrorLabel value={dateOfFirstRegistrationError} />
                </Col>
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.colour"
                    label="Colour"
                    invalid={!!colourError}
                    maxLength={40}
                    required
                    capitalize
                  />
                  <CommonErrorLabel value={colourError} />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.doors"
                    label="Doors"
                    invalid={!!doorsError}
                    maxLength={2}
                    required
                  />
                  <CommonErrorLabel value={doorsError} />
                </Col>
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.carType"
                    label="Body type"
                    invalid={!!carTypeError}
                    maxLength={20}
                    required
                    capitalize
                  />
                  <CommonErrorLabel value={carTypeError} />
                </Col>
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.engineSize"
                    className="basic-data__input"
                    label="Engine size"
                    invalid={!!engineSizeError}
                    maxLength={9}
                    required
                  />
                  <CommonErrorLabel value={engineSizeError} />
                </Col>
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.fuel"
                    label="Fuel"
                    invalid={!!fuelError}
                    maxLength={20}
                    required
                    capitalize
                  />
                  <CommonErrorLabel value={fuelError} />
                </Col>
              </Row>
              <Row>
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.transmission"
                    label="Transmission"
                    invalid={!!transmissionError}
                    maxLength={40}
                    required
                    capitalize
                  />
                  <CommonErrorLabel value={transmissionError} />
                </Col>{' '}
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.previousKeepersInTotal"
                    className="basic-data__input"
                    label="Number of prev. owners"
                    invalid={!!previousKeepersInTotalError}
                    maxLength={2}
                    required
                  />
                  <CommonErrorLabel value={previousKeepersInTotalError} />
                </Col>
                <Col md="3" sm="12">
                  <FormDatePickerField
                    name="basicData.mot"
                    label="MOT"
                    icon={calendarIcon}
                    placeholder="DD/MM/YYYY"
                    iconClassName="calendar-icon"
                    readOnly
                    position="top"
                  />
                </Col>
                <Col md="3" sm="12">
                  <FormInputField
                    name="basicData.vin"
                    className="basic-data__input"
                    label="VIN/Chassis No."
                    invalid={!!vinError}
                    maxLength={17}
                    upperCase
                  />
                  <CommonErrorLabel value={vinError} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </AddVehicleContentWrapper>
    </>
  );
};

AddBasicCarDataForm.propTypes = {
  values: PropTypes.object.isRequired,
  nextForm: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default AddBasicCarDataForm;
