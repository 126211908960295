import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CommonMyOrganizationContainer from '../../../common/components/CommonMyOrganizationContainer';
import SearchAlertForm from '../forms/SearchAlertForm';
import { getSearchAlert, updateSearchAlert, validateSearchAlert } from '../SearchAlertActions';
import { useIsMounted } from '../utils/isMounted';
import validationSchema from '../forms/CreateSearchAlertForm.schema';
import CommonSweetAlert from '../../../common/components/CommonSweetAlert';
import { CommonSweetAlertVariants } from '../../../constants/CommonSweetAlertVariants';

const EditSearchAlertsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const searchAlert = useSelector(state => state.searchAlerts.get('editSearchAlert'));
  const initialValues = {
    make: searchAlert?.get('make').toJS(),
    model: searchAlert?.get('model').toJS(),
    allRangeOfYears: searchAlert?.get('allRangeOfYears'),
    yearMinValue: searchAlert?.get('yearMinValue')?.toJS(),
    yearMaxValue: searchAlert?.get('yearMaxValue')?.toJS(),
    mileageMaxValue: searchAlert?.get('mileageMaxValue')?.toJS(),
    distanceMaxValueInMiles: searchAlert?.get('distanceMaxValueInMiles')?.toJS(),
  };

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [values, setValues] = useState(null);
  const [formPayload, setFormPayload] = useState(null);

  useEffect(() => {
    dispatch(getSearchAlert(id));
  }, [dispatch, id]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    await dispatch(validateSearchAlert(id, values, setErrors))
      .then(res => {
        if (res.code === 'NO_ACTION') {
          update(values, { setSubmitting, setErrors });
        } else {
          setValues(values);
          setFormPayload({ setSubmitting, setErrors });
          setTitle(res.title);
          setMessage(res.message);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setSubmitting(false);
        }
      });
  };

  const update = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    await dispatch(updateSearchAlert(id, values, setErrors)).finally(() => {
      if (isMounted.current) {
        setSubmitting(false);
      }
    });
  };

  return (
    <CommonMyOrganizationContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {form => <SearchAlertForm values={form?.values} form={form} />}
      </Formik>
      <CommonSweetAlert
        variant={CommonSweetAlertVariants.ERROR}
        show={title !== ''}
        onConfirm={() => {
          update(values, formPayload);
        }}
        onCancel={() => {
          setTitle('');
          setMessage('');
          setValues(null);
          setFormPayload(null);
        }}
      >
        <p className="common-sweet-alert__title">{message}</p>
      </CommonSweetAlert>
    </CommonMyOrganizationContainer>
  );
};

export default EditSearchAlertsPage;
