import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { dateToString } from '../../../common/utils/date/Date.utils';
import { preparePriceFormat } from '../../../common/helpers/preparePriceFormat';
import { downloadInvoice } from '../../SettingsActions';

const InvoiceTableRow = ({ item }) => {
  const dispatch = useDispatch();

  return (
    <>
      <td>{dateToString(item?.createdOn)}</td>
      <td>{`No. ${item.invoiceNumber}`}</td>
      <td>
        <span className="font-weight-bold mr-1">
          {preparePriceFormat(item.totalAmountInPence, true)}
        </span>
      </td>
      <td>
        <button
          type="button"
          className="invoice-table-row__action-button"
          onClick={() =>
            dispatch(downloadInvoice({ id: item?.id, invoiceNumber: item?.invoiceNumber }))
          }
        >
          {null}
        </button>
      </td>
    </>
  );
};

InvoiceTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default withRouter(InvoiceTableRow);
