import React, { Fragment, useState } from 'react';
import { Field, FieldArray, Form, getIn } from 'formik';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormInputField from '../../../common/components/formFields/FormInputField';
import PostcodeSelectField from '../../../register/forms/postcode/PostcodeSelectField';
import CommonButton from '../../../common/components/CommonButton';
import { saveOrganizationDetails } from '../../SettingsActions';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import { DealerLocationStatusTypes } from '../../../constants/DealerLocationStatusTypes';
import { AccountLayoutContentWrapper } from '../../../common/components/AccountLayoutWrapper';
import SettingsHeader from '../../SettingsHeader';
import plusIcon from '../../../assets/img/icons/plus-blue.svg';
import CommonDeleteIconButton from '../../../common/components/CommonDeleteIconButton';
import { CommonSweetAlertVariants } from '../../../constants/CommonSweetAlertVariants';
import CommonSweetAlert from '../../../common/components/CommonSweetAlert';
import { scrollToElement } from '../../../common/helpers/generalHelper';
import CommonInput from '../../../common/components/CommonInput';

const InformationsForm = ({ values, setSubmitting, isValid, setFieldValue, handleSubmit }) => {
  const dispatch = useDispatch();
  const organizationDetails = useSelector(state =>
    state.myOrganization.get('organizationDetails'),
  );
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const prevAdditionalLocation = organizationDetails?.additionalLocation;
  const [locationIdsToDelete, setLocationIdsToDelete] = useState([]);
  const [idDelLocation, setIdDelLocation] = useState(null);
  const handleDeleteAdditionalLocation = (location, arrayHelpers, index) => {
    if (location && values) {
      setLocationIdsToDelete(prevState => [...prevState, location?.id]);
      arrayHelpers.remove(index);
    }
  };

  const handleSaveOrganizationDetails = async () => {
    handleSubmit();
    if (isValid) {
      setSubmitting(true);
      await dispatch(
        saveOrganizationDetails(values, prevAdditionalLocation, locationIdsToDelete),
      );
      setSubmitting(false);
    }
  };

  const isAdditionalLocationDisabled = location => {
    return !!(!isOwner || location?.id);
  };

  return (
    <>
      <SettingsHeader>
        <Row>
          <Col className="d-flex justify-content-end align-items-center actions-wrapper">
            <CommonButton
              className="text-sm"
              label="ADD ADDITIONAL LOCATION"
              variant={CommonButtonVariants.OUTLINED}
              icon={plusIcon}
              iconAlt="Add New Location"
              iconLeft={true}
              disabled={!isOwner}
              handleClick={() => {
                const toUpdate = [
                  ...(values.additionalLocation || []),
                  {
                    postalCode: '',
                    address: '',
                    city: '',
                    county: '',
                  },
                ];
                setFieldValue('additionalLocation', toUpdate);

                setTimeout(() => {
                  scrollToElement(`additional-location-${toUpdate.length - 1}`);
                }, 800);
              }}
            />
            <CommonButton
              className="text-sm"
              label="SAVE"
              disabled={isLoading || !isOwner}
              variant={CommonButtonVariants.PRIMARY}
              handleClick={handleSaveOrganizationDetails}
            />
          </Col>
        </Row>
      </SettingsHeader>
      <AccountLayoutContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">Company Details</h5>
            <p className="text-sm mt-2">
              Please complete the information about your company and its locations.
            </p>
          </Col>
        </Row>
        <Form autoComplete="off" className="informations-form">
          <Row className="mt-4">
            <Col md={6}>
              <Label className="form-input-field-label form-input-field-label__disabled">
                Company Name
              </Label>
              <FormGroup>
                <CommonInput
                  onChange={() => {}}
                  disabled
                  value={organizationDetails?.tradeName}
                />
              </FormGroup>
            </Col>
            <Col md={6} />
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <FormInputField
                name="companyRegistrationNumber"
                label="Company registration number"
                disabled
              />
            </Col>
            <Col md={6}>
              <FormInputField name="vatNumber" label="VAT Number" disabled />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col
              md={6}
              className={`${values?.landlinePhoneNumberIsPending &&
                'informations-form__pending-field'}`}
            >
              <FormInputField
                name="landlinePhoneNumber"
                label="Phone number"
                disabled={!isOwner}
              />
            </Col>
            <Col
              md={6}
              className={`d-flex flex-column justify-content-center ${
                values?.landlinePhoneNumberIsPending ? 'mt-2' : ''
              } `}
            >
              <FormInputField name="website" label="Website address" disabled={!isOwner} />
            </Col>
          </Row>
          <div className="line-break" />
          <Row
            className={`${values?.mainLocationIsPending &&
              'informations-form__pending-field'} mt-3`}
          >
            <Col md={12}>
              <h5 className="font-weight-bold">Company Address</h5>
            </Col>
            <Col md={6} className="mt-3">
              <PostcodeSelectField
                name="postalCodeId"
                label="Postal code"
                loadRightAway={false}
                disabled={!isOwner}
              />
            </Col>
            <Col md={6} className="mt-3">
              <FormInputField name="city" label="City" disabled={!isOwner} />
            </Col>
            <Col md={6} className="mt-3">
              <FormInputField name="address" label="Address" disabled={!isOwner} />
            </Col>
            <Col md={6} className="mt-3">
              <FormInputField name="address2" label="Address (line 2)" disabled={!isOwner} />
            </Col>
            <Col md={6} className="mt-3">
              <FormInputField name="county" label="County" disabled={!isOwner} />
            </Col>
            {values?.mainLocation && (
              <button type="button">
                <i className="far fa-trash-alt informations-form__trash-icon" />
              </button>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <FieldArray
                name="additionalLocation"
                render={arrayHelpers => (
                  <Row className="d-flex flex-column justify-content-center">
                    <div>
                      {values?.additionalLocation.map((location, index) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <Fragment key={index}>
                            <div className="line-break as-row mt-4" />
                            <Col
                              className="d-flex flex-row justify-content-between align-items-center mb-4"
                              id={`additional-location-${index}`}
                            >
                              <h5 className="d-inline-block font-weight-bold m-0">
                                Additional Company Address {index + 1}
                              </h5>
                              {isOwner && (
                                <div className="d-inline-block">
                                  <CommonDeleteIconButton
                                    handleClick={() => setIdDelLocation(index)}
                                  />
                                </div>
                              )}
                            </Col>
                            <Col
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              className={`mb-3 ${location.status &&
                                location.status === DealerLocationStatusTypes.PENDING &&
                                'informations-form__pending-field'}`}
                            >
                              <Row className="informations-form-row">
                                <Col md={6}>
                                  <Field name={`additionalLocation.${index}.address`}>
                                    {({ form }) => {
                                      const error = getIn(
                                        form.errors,
                                        `additionalLocation.${index}.address`,
                                      );
                                      const touch = getIn(
                                        form.touched,
                                        `additionalLocation.${index}.address`,
                                      );
                                      return (
                                        <div>
                                          <FormInputField
                                            name={`additionalLocation.${index}.address`}
                                            invalid={!!touch && !!error}
                                            label="Address"
                                            disabled={isAdditionalLocationDisabled(location)}
                                          />
                                          {touch && error ? (
                                            <label className="error-label">{error}</label>
                                          ) : null}
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Col>
                                <Col md={6}>
                                  <Field name={`additionalLocation.${index}.address2`}>
                                    {({ form }) => {
                                      const error = getIn(
                                        form.errors,
                                        `additionalLocation.${index}.address2`,
                                      );
                                      const touch = getIn(
                                        form.touched,
                                        `additionalLocation.${index}.address2`,
                                      );
                                      return (
                                        <div>
                                          <FormInputField
                                            name={`additionalLocation.${index}.address2`}
                                            invalid={!!touch && !!error}
                                            label="Address (line 2)"
                                            disabled={isAdditionalLocationDisabled(location)}
                                          />
                                          {touch && error ? (
                                            <label className="error-label">{error}</label>
                                          ) : null}
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Col>
                              </Row>
                              <Row className="informations-form-row">
                                <Col md={4}>
                                  <Field name={`additionalLocation.${index}.postalCode`}>
                                    {({ form }) => {
                                      const error = getIn(
                                        form.errors,
                                        `additionalLocation.${index}.postalCode`,
                                      );
                                      const touch = getIn(
                                        form.touched,
                                        `additionalLocation.${index}.postalCode`,
                                      );
                                      return (
                                        <div>
                                          <PostcodeSelectField
                                            name={`additionalLocation.${index}.postalCode`}
                                            loadRightAway={false}
                                            label="Postal Code"
                                            disabled={isAdditionalLocationDisabled(location)}
                                            invalid={!!touch && !!error}
                                          />
                                          {touch && error ? (
                                            <label className="error-label">{error}</label>
                                          ) : null}
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Col>
                                <Col md={4}>
                                  <Field name={`additionalLocation.${index}.city`}>
                                    {({ form }) => {
                                      const error = getIn(
                                        form.errors,
                                        `additionalLocation.${index}.city`,
                                      );
                                      const touch = getIn(
                                        form.touched,
                                        `additionalLocation.${index}.city`,
                                      );
                                      return (
                                        <div>
                                          <FormInputField
                                            name={`additionalLocation.${index}.city`}
                                            invalid={!!touch && !!error}
                                            label="City"
                                            disabled={isAdditionalLocationDisabled(location)}
                                          />
                                          {touch && error ? (
                                            <label className="error-label">{error}</label>
                                          ) : null}
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Col>
                                <Col md={4}>
                                  <Field name={`additionalLocation.${index}.county`}>
                                    {({ form }) => {
                                      const error = getIn(
                                        form.errors,
                                        `additionalLocation.${index}.county`,
                                      );
                                      const touch = getIn(
                                        form.touched,
                                        `additionalLocation.${index}.county`,
                                      );
                                      return (
                                        <div>
                                          <FormInputField
                                            name={`additionalLocation.${index}.county`}
                                            invalid={!!touch && !!error}
                                            label="County"
                                            disabled={isAdditionalLocationDisabled(location)}
                                          />
                                          {touch && error ? (
                                            <label className="error-label">{error}</label>
                                          ) : null}
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </Col>
                              </Row>
                            </Col>
                            <CommonSweetAlert
                              show={index === idDelLocation}
                              variant={CommonSweetAlertVariants.ERROR}
                              onConfirm={() => {
                                handleDeleteAdditionalLocation(location, arrayHelpers, index);
                                setIdDelLocation(null);
                              }}
                              onCancel={() => setIdDelLocation(null)}
                              confirmButtonText="REMOVE"
                            >
                              <p className="common-sweet-alert__title">
                                Are you sure you want to remove this location?
                              </p>
                            </CommonSweetAlert>
                          </Fragment>
                        );
                      })}
                    </div>
                  </Row>
                )}
              />
            </Col>
          </Row>
        </Form>
      </AccountLayoutContentWrapper>
    </>
  );
};

InformationsForm.propTypes = {
  values: PropTypes.object.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default InformationsForm;
