import React from 'react';
import HeroSection from './sections/HeroSection';
import WhoWeAreSection from './sections/WhoWeAreSection';
import StorySection from './sections/StorySection';
import BusinessModelSection from './sections/BusinessModelSection';
import TeamLeadershipSection from './sections/TeamLeadershipSection';
import ContactUsSection from './sections/ContactUsSection';

const AboutUs = () => {
  return (
    <div className="d-flex justify-content-center">
      <div style={{ maxWidth: 1300, width: '100%' }}>
        <HeroSection />
        <WhoWeAreSection />
        <StorySection />
        <BusinessModelSection />
        <TeamLeadershipSection />
        <ContactUsSection />
      </div>
    </div>
  );
};

export default AboutUs;
