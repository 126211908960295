import React from 'react';
import { Col, Container, Row, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import uuid from 'react-uuid';

export const AccountLayoutTitleVariants = {
  PRIMARY: 'primary',
  ERROR: 'error',
  WARNING: 'warning',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
};

export const AccountLayoutWrapper = ({
  children,
  navOptions,
  navTitle,
  navContent,
  contentClassName,
}) => {
  return (
    <Container
      fluid
      className="account-layout container-fluid d-flex flex-row p-0 flex-grow-1"
    >
      <div className="account-layout__nav-column">
        <div className="account-layout__nav-column-content">
          {navTitle && (
            <Row className="m-0">
              <Col md={12} className="p-0 m-0 d-flex">
                {navTitle}
              </Col>
            </Row>
          )}
          {navOptions && (
            <Row
              className={`d-flex flex-column m-0 w-100 flex-grow-1 ${!navTitle ? 'mt-2' : ''}`}
            >
              <Col md={12} className="h-100 w-100 p-0">
                <Nav className="account-layout__nav d-flex flex-column flex-grow-1">
                  {navOptions.map(item => (
                    <div key={uuid()}>{item}</div>
                  ))}
                </Nav>
              </Col>
            </Row>
          )}
          {navContent && <>{navContent}</>}
        </div>
      </div>
      <div className={`account-layout__content-column ${contentClassName || ''}`}>
        {children}
      </div>
    </Container>
  );
};

export const AccountLayoutNavItem = ({ children, link, onClick, hide, className }) => {
  return (
    <NavItem className={`${hide ? 'hide' : ''}`}>
      <NavLink
        className={`account-layout__nav-item ${className || ''}`}
        to={link}
        onClick={onClick}
      >
        {children}
      </NavLink>
    </NavItem>
  );
};

export const AccountLayoutTitle = ({ children, variant }) => {
  return (
    <div
      className={`nav-column__title-box d-flex justify-content-center align-items-center status-box box-${variant ||
        AccountLayoutTitleVariants.PRIMARY}`}
    >
      {children}
    </div>
  );
};

export const AccountLayoutContentWrapper = ({ children }) => {
  return <div className="account-layout__content-wrapper">{children}</div>;
};
