export const BidStatus = {
  WON: 'WON',
  PLACED: 'PLACED',
  TO_ACCEPT: 'TO_ACCEPT',
  WAITING: 'WAITING',
  LOST: 'LOST',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
};

export const BidStatusName = {
  WON: 'Won',
  PLACED: 'Placed bids',
  TO_ACCEPT: 'To accept',
  WAITING: 'Waiting',
  LOST: 'Lost',
  REJECTED: 'Declined Offer',
  CANCELLED: 'Cancelled',
  OFFER_DECLINED: 'Declined Offer',
};
