import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Field } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import {
  DATE_FORMAT,
  dateToString,
  REVERSE_DASH_DATE_FORMAT,
} from '../../utils/date/Date.utils';

const FormDatePickerFieldInner = props => {
  const {
    label,
    placeholder,
    form,
    field,
    onChangeHandler,
    disabled,
    icon,
    iconClassName,
    readOnly,
    invalid,
    suffix,
    isPastDateDisabled,
    position,
    required,
  } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || get(form.values, field.name, '') || '';
  const calendarRef = useRef();

  const setClassName = () => {
    if (error) return 'rdt-invalid is-invalid';
    if (disabled) return 'form-field--disabled';
    return '';
  };

  const yesterday = moment().subtract(1, 'day');
  const disablePastDate = current => {
    return current.isAfter(yesterday);
  };

  return (
    <Row>
      <Col>
        <Label className={`form-date-picker-field-label ${required ? 'label-required' : ''}`}>
          {label}
        </Label>
        <FormGroup
          className={`form-date-picker-field-wrapper ${
            position === 'top' ? 'react-date-picker-top' : ''
          }`}
        >
          <Datetime
            closeOnSelect={true}
            timeFormat={false}
            dateFormat={DATE_FORMAT}
            inputProps={{
              placeholder,
              disabled: form.isSubmitting || disabled,
              readOnly,
              className: invalid || error ? 'is-invalid form-control' : 'form-control',
            }}
            value={dateToString(value)}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onChange={option => {
              form.setFieldValue(field.name, moment(option).format(REVERSE_DASH_DATE_FORMAT));
              onChangeHandler(option);
            }}
            className={setClassName()}
            isValidDate={isPastDateDisabled ? disablePastDate : null}
            ref={calendarRef}
          />
          {suffix && value ? (
            <div
              className={`form-date-picker-field__suffix ${
                (icon && iconClassName) || invalid || error
                  ? 'form-date-picker-field__suffix-with-icon'
                  : ''
              } `}
            >
              {suffix}
            </div>
          ) : null}
          {icon && iconClassName && !(invalid || error) && (
            <button
              className={`${iconClassName}`}
              type="button"
              onClick={() => {
                if (calendarRef?.current) {
                  calendarRef.current.openCalendar();
                }
              }}
            >
              <img src={icon} alt="Input icon" />
            </button>
          )}
          {(form.submitCount > 0 || touched) && error && (
            <label className="form-input-field__label">{error}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormDatePickerField = props => {
  return <Field {...props} component={FormDatePickerFieldInner} />;
};
FormDatePickerField.defaultProps = {
  placeholder: '',
  options: [],
  onChangeHandler: () => {},
  disabled: false,
  label: '',
  icon: '',
  iconClassName: '',
  readOnly: false,
  invalid: '',
  suffix: '',
  isPastDateDisabled: false,
  position: '',
  required: false,
};

FormDatePickerField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  suffix: PropTypes.string,
  isPastDateDisabled: PropTypes.bool,
  position: PropTypes.string,
  required: PropTypes.bool,
};

export default FormDatePickerField;
