import { get } from 'lodash';
import {
  hideLoader,
  showLoader,
} from '../../common/components/NavbarLoader/NavbarLoaderActions';
import { apiClient } from '../../api/ApiClient';
import { handleError, showAlert } from '../../common/components/alert/AlertActions';
import AlertVariant from '../../common/components/alert/AlertVariant';
import history from '../../browserHistory';
import RouterPaths from '../../constants/RouterPaths';
import { prepareSearchData, SORT_ASC } from '../../common/helpers/prepareSearchData';
import {
  CLEAR_COPIED_SEARCH_ALERT_STATE,
  CLEAR_EDIT_SEARCH_ALERT_STATE,
  COPY_SEARCH_ALERT,
  FETCH_SEARCH_ALERTS_OPTION_SUCCESS,
  FETCH_SEARCH_ALERTS_SUCCESS,
  GET_SEARCH_ALERT_SUCCESS,
} from './SearchAlertReducer';
import { mapErrors } from './utils/validationErrorMapper';
import { mapToNullIfAllSelected } from './utils/defaultValue';

const SEARCH_ALERT_API_ENDPOINT = '/organization-member-search-alert';
const SEARCH_ALERT_VALIDATE_API_ENDPOINT = '/organization-member-search-alert/validate';
const SEARCH_ALERT_OPTIONS_API_ENDPOINT = '/search-alert-options';

const preparePayload = (values, id) => {
  return {
    id,
    makeId: mapToNullIfAllSelected(values.make?.value),
    modelId: mapToNullIfAllSelected(values.model?.value),
    distanceMaxValueInMiles: mapToNullIfAllSelected(values.distanceMaxValueInMiles?.value),
    mileageMaxValue: mapToNullIfAllSelected(values.mileageMaxValue?.value),
    yearMinValue: mapToNullIfAllSelected(values.yearMinValue?.value),
    yearMaxValue: mapToNullIfAllSelected(values.yearMaxValue?.value),
  };
};

export const createSearchAlert = (values, setErrors) => dispatch => {
  dispatch(showLoader());
  const data = preparePayload(values);
  return apiClient
    .post(SEARCH_ALERT_API_ENDPOINT, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Search alert created successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.SEARCH_ALERTS);
      }
      return res;
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        setErrors(mapErrors(err?.response?.data?.data));
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const validateSearchAlert = (id, values, setErrors) => dispatch => {
  dispatch(showLoader());
  const data = preparePayload(values, id);
  return apiClient
    .post(SEARCH_ALERT_VALIDATE_API_ENDPOINT, data)
    .then(res => {
      return get(res, 'data.data', null);
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        setErrors(mapErrors(err?.response?.data?.data));
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const updateSearchAlert = (id, values, setErrors) => dispatch => {
  dispatch(showLoader());
  const data = preparePayload(values, id);
  return apiClient
    .put(SEARCH_ALERT_API_ENDPOINT, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Search alert updated successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.SEARCH_ALERTS);
      }
      return res;
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        setErrors(mapErrors(err?.response?.data?.data));
      } else {
        dispatch(handleError(err));
      }
    })
    .finally(() => dispatch(hideLoader()));
};

export const getSearchAlertOptions = () => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(SEARCH_ALERT_OPTIONS_API_ENDPOINT)
    .then(res => {
      dispatch({
        type: FETCH_SEARCH_ALERTS_OPTION_SUCCESS,
        payload: {
          data: get(res, 'data.data', []),
        },
      });
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const getSearchAlert = id => dispatch => {
  dispatch(showLoader());
  return apiClient
    .get(`${SEARCH_ALERT_API_ENDPOINT}/${id}`)
    .then(res => {
      dispatch({
        type: GET_SEARCH_ALERT_SUCCESS,
        payload: {
          data: get(res, 'data.data', {}),
        },
      });
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const searchSearchAlerts = values => dispatch => {
  dispatch(showLoader());
  return apiClient
    .post(
      `${SEARCH_ALERT_API_ENDPOINT}/read-all`,
      prepareSearchData(values, { createdOn: SORT_ASC }),
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_SEARCH_ALERTS_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const removeSearchAlert = id => dispatch => {
  dispatch(showLoader());
  return apiClient
    .delete(`${SEARCH_ALERT_API_ENDPOINT}/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Search alert removed successfully', AlertVariant.SUCCESS));
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => dispatch(hideLoader()));
};

export const clearEditSearchAlertState = () => dispatch => {
  dispatch({
    type: CLEAR_EDIT_SEARCH_ALERT_STATE,
  });
};

export const copyCopiedSearchAlertState = data => dispatch => {
  dispatch({
    type: COPY_SEARCH_ALERT,
    payload: {
      data,
    },
  });
};

export const clearCopiedSearchAlertState = () => dispatch => {
  dispatch({
    type: CLEAR_COPIED_SEARCH_ALERT_STATE,
  });
};
